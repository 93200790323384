import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { useStore } from "../../../Hooks";


const MapPage: React.FC = observer((props: any) => {
  const panelStore = useStore("panelStore");
  const dataStore = useStore("dataStore");

//   useEffect(() => {
//     let isMap = window.location.pathname.slice(0, 100) === "/map";

//     // if (panelStore.activePerson && isMap)
//     //   panelStore.renderPerson(panelStore.activePerson, undefined);
//   }, []);

  return ( null
 
      // <SummaryBody />
 
  );
});

export default MapPage;
