import React from "react";
import { T_dataComponent } from "../../../types";
import { SubItem, T_subItemProps } from "../../Components/SubItem";

type T_props = {
  amount: string;
  title: string;
  type: T_dataComponent;
} & T_subItemProps;

export const FederalAppropriationsItem: React.FC<T_props> = ({
  title,
  amount,
  size,
  type = "PAGE",
}) => {
  const className = type === "POSTCARD" ? "-postcard" : "";

  return (
    <SubItem size={size}>
      <div className="w-100">
        <h1 className={`gm-explore-numeric${className} text-left pb-1`}>
          {amount}
        </h1>
        <p className={`gm-explore-text${className} text-left`}>{title}</p>
      </div>
    </SubItem>
  );
};
