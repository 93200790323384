import React from "react";
import { observer } from "mobx-react";
import { APTAMember } from "APTAPackage";
import ListElem from ".";
import {useStore} from '../../../../../Hooks'

// @ts-ignore
interface APTAListElemProps {
  APTAMember: APTAMember;
  onClick?: any
}

const APTAListElem: React.FC<APTAListElemProps> = ({ APTAMember, onClick }) => {

  const panelStore = useStore('panelStore');
  return (
    <ListElem key={APTAMember.mbt_code as string} onClick={(e: any) => {
      e.stopPropagation()
      panelStore.renderMemberPanel(APTAMember);
    } }>
      <div className="gm-person-list-elem-content">
        <div className="gm-person-list-elem-details">
          <strong> {APTAMember.orgName}</strong>
          <span> {APTAMember.mbt_code}</span>
          <span> {APTAMember.identityInfo.stateFull}</span>
        </div>
      </div>
    </ListElem>
  );
};

export default observer(APTAListElem);
