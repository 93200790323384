import React from "react";
import Tab, { TabProps } from "./Tab";
import { SEARCH_PANEL_TYPES } from "../../../../../../Stores/SearchStore/types";

const tabConfig: TabProps[] = [
  { title: "States", type: SEARCH_PANEL_TYPES.STATE_SEARCH_PANEL },
  { title: "Legislators", type: SEARCH_PANEL_TYPES.LEG_SEARCH_PANEL },
  { title: "APTA Members", type: SEARCH_PANEL_TYPES.APTA_SEARCH_PANEL },
  { title: "Committees", type: SEARCH_PANEL_TYPES.COMMITTEE_SEARCH_PANEL },
];

interface TabSelectProps {
  tabArr?: TabProps[];
}
const TabSelect: React.FC<TabSelectProps> = ({ tabArr = tabConfig }) => {
  return (
    <section className="gm-search-panel-tabs">
      {tabArr.map((tabElem) => (
        <Tab key={tabElem.title} title={tabElem.title} type={tabElem.type} />
      ))}
    </section>
  );
};

export default TabSelect;
