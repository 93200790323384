import React from "react";
import { Space } from "./Space";

type T_props = {
  bold?: string;
  rest?: string;
};

export const HomeText: React.FC<T_props> = ({ bold, rest }) => {
  return (
    <p className="gm-home-page-font-size-small">
      <span className="gm-home-page-details-bold pr-1">{bold}</span>
      <span className="gm-home-page-details-unbold">{rest}</span>
    </p>
  );
};
