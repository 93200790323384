import { API_URLS } from "../../constants/configTS";
import axios from "axios";

export const signInFootPrint = async (credentials: any) => {
  return await axios
    .post(
      // process.env.REACT_APP_ENV === "dev"
      // ? `${DEV_BATCH_PRINT_URL}/login`
      API_URLS.POST_login_password,
      credentials
    )
    .then((res) => {
      if (
        res.data.WebUserType.CurrentKey ===
        "00000000-0000-0000-0000-000000000000"
      )
        throw "User Not Found.";

      return res;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const signInOnApta = async (token: string) => {
  return await axios
    .post(
      // process.env.REACT_APP_ENV === "dev"
      // ? `${DEV_BATCH_PRINT_URL}/login2`
      API_URLS.POST_login_token,
      { token }
    )
    .then((res) => {
      if (
        res.data.WebUserType.CurrentKey ===
        "00000000-0000-0000-0000-000000000000"
      )
        throw "User Not Found.";
      return res;
    })
    .catch((e) => {
      throw new Error(e);
    });
};
