import React from "react";
import { observer } from "mobx-react";
import Panel from "./BasePanel/Panel";
import APTAMemberElem from "./ListElems/APTAMember";
import { useStore } from "../../../../Hooks";
import {Property} from './PersonPanel/Properties'

const PersonPanel: React.FC = () => {
  const panelStore = useStore("panelStore");

  if (panelStore?.activeMember === undefined) {
    return <Panel />;
  }

  return (
    <Panel>
      <APTAMemberElem APTAMember={panelStore.activeMember} />
      <section className="gm-apta-member-section">
        {/* <h1> Member Description: </h1> */}
        <p> {panelStore.activeMember.orgDescription} </p>
        <Property title='Address' data={panelStore?.activeMember?.address as string} />
        <Property title='OGT Code' data={panelStore?.activeMember?.org_ogt_code as string} />
        <Property title='Reporting Group' data={panelStore?.activeMember?.mbt_reporting_group_ext as string} />

        
      
      </section>
    </Panel>
  );
};

export default observer(PersonPanel);
