import React from "react";
interface IProperty {
  title: string;
  data: string | undefined;
}

export const Property: React.FC<IProperty> = ({ title, data }) => {
  if (data) {
    return (
      <tr>
        <td>{title}: </td>
        <td> {data} </td>
      </tr>
    );
  }
  return null;
};

export const LinkProperty: React.FC<ILinkProperty> = ({ title, href }) => {
  if (href) {
    return (
      <tr>
        <td> {title}:</td>
        <td>
          <a href={href} target="_blank">
            Link
          </a>
        </td>
      </tr>
    );
  }
  return null;
};

interface IIconProperty {
  data: string;
  image: string;
  href: string;
  onClick?: () => void;
}

export const IconProperty: React.FC<IIconProperty> = ({
  data,
  image,
  href,
  onClick,
}) => {
  if (data) {
    return (
      <a href={href} target="_blank" onClick={onClick}>
        <img alt="title" src={image} />
      </a>
    );
  }
  return <span> </span>;
};

interface ILinkProperty {
  title: string;
  href: string;
  onClick?: () => void;
}
