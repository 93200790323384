import React from "react";
import { useStore } from "../../../../../Hooks";
import { observer } from "mobx-react";

const Search = () => {
  const searchStore = useStore("searchStore");

  return (
    <section className="gm-search-panel-input">
      <input
        type="text"
        placeholder="Please search for a location or a person."
        className="input-search input-minimal"
        onChange={(e) => searchStore.setSearchString(e.target.value)}
        value={searchStore.searchString}
        autoComplete="off"
      />
    </section>
  );
};

export default observer(Search);
