import React from "react";
import ReactDOM from "react-dom/client";

import App from "./components/App2";
import { StoresProvider, stores } from "./Stores";

const rootElement = document.getElementById("root");
// ReactDOM.render(
//   <StoresProvider value={stores}>
//     <App />
//   </StoresProvider>,
//   rootElement
// );

ReactDOM.createRoot(rootElement!).render(
  <StoresProvider value={stores}>
    <App />
  </StoresProvider>
);
