import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../Hooks";

import { CartButton } from "./Components/CartButton/CartButton";

import { useHistory } from "react-router-dom";
import { CartSpinner } from "./Components/CartSpinner";

const CartButtons: React.FC = () => {
  const history = useHistory();

  const newCartStore = useStore("newCartStore");

  const printStatus = newCartStore.printStatus;
  const activePage = newCartStore?.cartPage;
  const cartLength = newCartStore?.cartSize;
  const status = newCartStore?.status;
  const zip = newCartStore?.zipUrl;

  const buttons = useMemo(() => {
    switch (printStatus) {
      case "INIT": {
        return (
          <>
            <CartButton
              label="Back"
              className={"mr-3"}
              onClick={() => {
                if (activePage === 0) history.goBack();
                else if (activePage === 5) history.goBack();
                else {
                  newCartStore.previousPage();
                }
              }}
            />

            {cartLength > 0 && activePage !== 5 && (
              <CartButton
                label={activePage === 4 ? "Print" : "Next"}
                invert
                className={"ml-3"}
                onClick={() => newCartStore.nextPage()}
              />
            )}
          </>
        );
      }
      case "LOADING": {
        return <CartSpinner color="#47318e4d" status={status} />;
      }
      case "SUCCESS": {
        const errors = status?.counts?.errorCount;
        if (errors) {
          return (
            <div className="d-flex flex-column">
              <p className="mb-1 text-center">
                {" "}
                Your job completed with {errors}{" "}
                {errors === 1 ? "error" : "errors"}.
              </p>

              <CartButton
                label="Download Zip"
                onClick={() => window.open(zip, "_blank")}
              />
            </div>
          );
        }

        return (
          <div className="d-flex flex-column">
            {" "}
            <p className="mb-1 text-center"> Your job completed.</p> <br />
            <CartButton
              label="Download Zip"
              onClick={() => window.open(zip, "_blank")}
            />
          </div>
        );
      }
      case "ZIP-ERROR": {
        return (
          <div className="d-flex flex-column">
            <p className="mb-1 text-center">
              {" "}
              There was an error generating your zip. Please download the files
              individually, or try again later.
            </p>{" "}
            <br />
          </div>
        );
      }
      case "ERROR": {
        return (
          <div className="d-flex flex-column">
            <p className="mb-1 text-center">
              {" "}
              There was an error please try again later.
            </p>{" "}
            <br />
          </div>
        );
      }
    }
  }, [printStatus, activePage, cartLength, status, zip]);

  return (
    <>
      <div className="row pt-3 ">
        <div
          className="col"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {buttons}
        </div>
      </div>
      <div className="pb-5 pt-5"></div>
    </>
  );
};

export default observer(CartButtons);
