import React, { useRef, useMemo, useLayoutEffect } from "react";
import { Chart, registerables } from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import DoughLegend from "./DoughnutLegend";
import "./Doughnut.css";

export type T_doughnut = {
  id: string;
  title?: string;
  units?: string;
  items: T_doughnutItem[];
  noValue?: boolean;
};

type T_doughnutItem = {
  label: string;
  percentage: string;
  color: string;
  value: number;
};

type T_props = T_doughnut;

export const DoughnutRefactor: React.FC<T_props> = (props) => {
  let { id, title, units, items, noValue } = props;

  const canvasRef = useRef<any | null>(null);
  const graphRef = useRef<Chart | null>(null);
  Chart.register(...registerables);
  // Chart.register(...registerables, ChartDataLabels);

  const parsedData = useMemo(() => {
    const data: number[] = [];
    const colors: string[] = [];
    const labels: string[] = [];
    const percentages: string[] = [];

    let newItems = [...items];

    newItems.sort((a, b) => {
      if (a.value > b.value) return -1;
      else if (a.value < b.value) return 1;
      return 0;
    });

    newItems.forEach((item) => {
      data.push(item.value);
      colors.push(item.color);
      labels.push(item.label);
      percentages.push(item.percentage);
    });

    return {
      data,
      colors,
      labels,
      percentages,
      sortedItems: newItems,
    };
  }, [items]);

  const { data, colors, labels, percentages, sortedItems } = parsedData;

  useLayoutEffect(() => {
    if (graphRef.current !== null) {
      graphRef.current.destroy();
    }

    let myChartRef;
    if (canvasRef.current) {
      myChartRef = canvasRef.current.getContext("2d");
    }

    //@ts-ignore
    graphRef.current = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: colors,
            datalabels: { display: false },
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1.75,
        cutout: "70%",
        plugins: {
          legend: { display: false },
        },
        //@ts-ignore
        tooltips: {
          enabled: true,
          mode: "single",
          callbacks: {
            label: function (tooltipItems: any, data: any) {
              units = units ? units : "";

              let index = tooltipItems.index;

              if (noValue) {
                return " " + percentages[index] + " " + data.labels[index];
              }

              let value = data.datasets[0].data[index];
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(",");
              return units !== "$"
                ? " " +
                    percentages[index] +
                    " " +
                    data.labels[index] +
                    " : " +
                    value +
                    " " +
                    units
                : " " +
                    percentages[index] +
                    " " +
                    data.labels[index] +
                    " : " +
                    "$ " +
                    value;
            },
          },
        },
      },
    });
    return () => {
      if (graphRef.current !== null) {
        graphRef.current.destroy();
      }
    }
  }, [id]);

  return (
    <>
      <h2 className="doughnut-title"> {title}</h2>
      <div className="row">
        <div className="col col-6 col-lg-7  ">
          <canvas ref={canvasRef} id={id} />
        </div>
        <div
          className="col col-6 col-lg-5 p-0"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 1,
            flexGrow: 0,
          }}
        >
          <DoughLegend
            labels={labels}
            colors={colors}
            labelPercents={percentages}
          />
        </div>
      </div>
    </>
  );
};
