import React from "react";
import { observer } from "mobx-react";
import Panel from "./BasePanel/Panel";
import { AddAllFed } from "./ListElems/AddButtons";
import StateListElem from "./ListElems/State";
import { useStore } from "../../../../Hooks";
import { stateAbbrevToFull } from "APTAPackage";
import { stateFullToAbbrev } from "../../../../constants";

const StateSelectPanel = () => {
  const dataStore = useStore("dataStore");
  const searchStore = useStore("searchStore");

  let stateAbbrevs =
    dataStore.data !== undefined ? Object.keys(dataStore?.data.map) : [];

  const stateFull = stateAbbrevs.map((abbrev) => stateAbbrevToFull[abbrev]);
  const stateFullSort = stateFull
    .sort()
    .filter((state) =>
      state.toLocaleLowerCase().includes(searchStore.searchString.toLowerCase())
    );
  const stateAbbrevSort = stateFullSort.map((full) => stateFullToAbbrev[full]);

  const flatFed = dataStore.getFlatFed;

  return (
    // <Panel>
    <ul className="gm-panel-list">
      <AddAllFed legs={flatFed} />
      {stateAbbrevSort.length > 0 ? (
        stateAbbrevSort.map((state, index) => (
          <StateListElem key={index} stateAbbrev={state} />
        ))
      ) : (
        <p> No Results Found</p>
      )}
    </ul>
    // </Panel>
  );
};

export default observer(StateSelectPanel);
