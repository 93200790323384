"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.runQuery2 = exports.runQuery = exports.genQuery = void 0;
const constants_1 = require("../constants");
const arcgis_rest_feature_service_1 = require("@esri/arcgis-rest-feature-service");
function genQuery(requestData, urlObj) {
    const { type } = requestData;
    let query = "";
    let url = "";
    switch (type) {
        case constants_1.LegislatorTypes.STATE_SUM: {
            const { stateAbbrev } = requestData;
            query = `Abbrev = '${stateAbbrev}'`;
            url = urlObj.STATE;
            break;
        }
        case constants_1.LegislatorTypes.SENATE: {
            const { stateAbbrev } = requestData;
            query = `Abbrev = '${stateAbbrev}'`;
            url = urlObj.STATE;
            break;
        }
        case constants_1.LegislatorTypes.HOUSE: {
            const { district, geoid } = requestData;
            query = `CD_ID = '${geoid}'`;
            url = urlObj.CD;
            break;
        }
        case constants_1.LegislatorTypes.SLDL: {
            const { builtID } = requestData;
            query = `SLDL_ID = '${builtID}'`;
            url = urlObj.SLDL;
            break;
        }
        case constants_1.LegislatorTypes.SLDU: {
            const { builtID } = requestData;
            query = `SLDU_ID = '${builtID}'`;
            url = urlObj.SLDU;
            break;
        }
    }
    return [url, query];
}
exports.genQuery = genQuery;
function runQuery(url, query, token, orderByFields = "", centroid = false) {
    return new Promise((resolve, reject) => {
        arcgis_rest_feature_service_1.queryFeatures({
            url,
            where: query,
            //@ts-ignore
            authentication: token,
            returnGeometry: true,
            returnCentroid: centroid,
            orderByFields: orderByFields,
            geometryType: "esriGeometryPolygon",
            outSR: "4326",
        })
            .then((res) => {
            resolve(res?.features);
        })
            .catch((error) => {
            reject(error);
        });
    });
}
exports.runQuery = runQuery;
function GEN_initTransitStats() {
    return {
        CALC_SUM_Total_Active_Fleet_auto: null,
        CALC_SUM_Beyond_Useful_Life_auto: null,
        CALC_SUM_Total_Active_Fleet_bus: null,
        CALC_SUM_Beyond_Useful_Life_bus: null,
        CALC_SUM_Total_Active_Fleet_ferry: null,
        CALC_SUM_Beyond_Useful_Life_ferry: null,
        CALC_SUM_Total_Active_Fleet_rail: null,
        CALC_SUM_Beyond_Useful_Life_rail: null,
    };
}
function runQuery2(url, query, token) {
    return new Promise((resolve, reject) => {
        arcgis_rest_feature_service_1.queryFeatures({
            url,
            where: query,
            //@ts-ignore
            authentication: token,
            returnGeometry: true,
            outStatistics: [
                {
                    statisticType: "sum",
                    onStatisticField: "SUM_Total_Active_Fleet_auto",
                    outStatisticFieldName: "CALC_SUM_Total_Active_Fleet_auto",
                },
                {
                    statisticType: "sum",
                    onStatisticField: "SUM_Beyond_Useful_Life_auto",
                    outStatisticFieldName: "CALC_SUM_Beyond_Useful_Life_auto",
                },
                {
                    statisticType: "sum",
                    onStatisticField: "SUM_Total_Active_Fleet_bus",
                    outStatisticFieldName: "CALC_SUM_Total_Active_Fleet_bus",
                },
                {
                    statisticType: "sum",
                    onStatisticField: "SUM_Beyond_Useful_Life_bus",
                    outStatisticFieldName: "CALC_SUM_Beyond_Useful_Life_bus",
                },
                {
                    statisticType: "sum",
                    onStatisticField: "SUM_Total_Active_Fleet_ferry",
                    outStatisticFieldName: "CALC_SUM_Total_Active_Fleet_ferry",
                },
                {
                    statisticType: "sum",
                    onStatisticField: "SUM_Beyond_Useful_Life_ferry",
                    outStatisticFieldName: "CALC_SUM_Beyond_Useful_Life_ferry",
                },
                {
                    statisticType: "sum",
                    onStatisticField: "SUM_Total_Active_Fleet_rail",
                    outStatisticFieldName: "CALC_SUM_Total_Active_Fleet_rail",
                },
                {
                    statisticType: "sum",
                    onStatisticField: "SUM_Beyond_Useful_Life_rail",
                    outStatisticFieldName: "CALC_SUM_Beyond_Useful_Life_rail",
                },
            ],
            geometryType: "esriGeometryPolygon",
            outSR: "4326",
        })
            .then((res) => {
            const stats = GEN_initTransitStats();
            const keys = Object.keys(stats);
            const unparsed = res?.features?.[0]?.attributes;
            keys.forEach((key) => {
                //@ts-expect-error: Index Signature...
                if (unparsed?.[key] != null)
                    stats[key] = unparsed[key];
            });
            resolve(stats);
        })
            .catch((error) => {
            const stats = GEN_initTransitStats();
            return resolve(stats);
            // reject(error);
        });
    });
}
exports.runQuery2 = runQuery2;
