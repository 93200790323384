import React, { useRef, useEffect, useState } from "react";
import { LegislatorTypes, FedLeg, StateLeg, layerIdObj } from "APTAPackage";
import Legend from "./Legend";

import { T_layerObj } from "APTAPackage/dist/v2/Layers/Types";

import { SectionTitle } from "../../../Explore/Components/SectionTitle";
import { webmapId } from "../../../../constants/configTS";
import {
  T_esriObj,
  T_useEsriModulesRes,
} from "../../../../Hooks/useEsriModules";
import { ArcGISIdentityManager } from "@esri/arcgis-rest-request";

interface MapProps {
  esriToken: ArcGISIdentityManager | null;
  esriObj: T_esriObj | null;
  geometry: any;
  layers: T_layerObj;
  legislatorType: LegislatorTypes;
  member: FedLeg | StateLeg;
}

const Map: React.FC<MapProps> = ({
  esriObj,
  esriToken,
  geometry,
  layers,
  member,
}) => {
  const mapRef = useRef<any>(null);

  const [dataURL, setDataURL] = useState("");
  const [legendDone, setLegendDone] = useState(false);
  const [theLegend, setTheLegend] = useState<any>(undefined);

  useEffect(() => {
    if (esriToken != null && esriObj != null) {
      const {
        watchUtils,
        WebMap,
        MapView,
        Legend,
        Graphic,
        Polygon,
        IdentityManager,
      } = esriObj;

      IdentityManager.registerToken({
        expires: esriToken.tokenExpires,
        server: "https://www.arcgis.com/sharing/rest",
        ssl: true,
        token: esriToken.token,
      });


      let thePoly = new Polygon(geometry);

      const symbol = {
        type: "simple-fill",
        color: [51, 51, 204, 0.2],
        style: "solid",
        outline: {
          color: "white",
          width: 1,
        },
      };
      const theGraphic = new Graphic(thePoly, symbol);

      let isAK = false;

      if (
        (member.identityInfo.type === LegislatorTypes.STATE_SUM ||
          member.identityInfo.type === LegislatorTypes.SENATE ||
          LegislatorTypes.HOUSE) &&
        member.identityInfo.stateAbbrev === "AK"
      ) {
        isAK = true;
      }

      // Init the map.
      const theWebMap = new WebMap({
        portalItem: {
          id: webmapId,
        },
        // extent: thePoly.extent,
        zoom: isAK ? 3 : undefined,
      });

      theWebMap.basemap = "arcgis-light-gray";

      theWebMap.allLayers.map((layer) => (layer.visible = false));

      const theView = new MapView({
        map: theWebMap,
        container: mapRef.current,
        extent: theGraphic.geometry.extent,
        zoom: 4,
      });

      const isAlaskaSenator =
        member.identityInfo.stateFull.toLowerCase() === "alaska" &&
        member.identityInfo.type.toLowerCase() === "senate";

      if (isAlaskaSenator) {
        theView.padding.bottom = 130;
      }

      // Wait for map to load
      theWebMap.when((map: any) => {
        // Check if all layers loaded
        const promArr: Promise<any>[] = [];

        map.allLayers.forEach((layer: any) => {
          if (layer?.loaded !== undefined) {
            promArr.push(
              new Promise((resolve) => {
                watchUtils.whenTrueOnce(layer, "loaded", () => {
                  resolve(undefined);
                });
              })
            );
          }
        });
        Promise.all(promArr).then(() => {
          theView
            .when((view: any) => {
              const legend = new Legend({
                view: theView,
              });

              let thePoly = new Polygon(geometry);
              const symbol = {
                type: "simple-fill",
                color: [51, 51, 204, 0.2],
                style: "solid",
                outline: {
                  color: "white",
                  width: 1,
                },
              };

              const theGraphic = new Graphic(thePoly, symbol);
              view.graphics.add(theGraphic);
              let goToArgs: any = {
                target: theGraphic.geometry.extent,
              };
              if (
                (member.identityInfo.type === LegislatorTypes.STATE_SUM ||
                  member.identityInfo.type === LegislatorTypes.SENATE ||
                  LegislatorTypes.HOUSE) &&
                member.identityInfo.stateAbbrev === "AK"
              ) {
                goToArgs = {
                  target: theGraphic.geometry.extent,
                  zoom: 3,
                };
              }

              view.goTo(goToArgs).then(() => {
                map.allLayers.items.map((layer: any, index: number) => {
                  const theLayerID = layer?.portalItem?.id;
                  const theLayerName = layerIdObj?.[theLayerID]?.alias;

                  if (theLayerName !== undefined) {
                    //@ts-ignore
                    let theVisibility = layers?.[theLayerName]?.visible;
                    map.allLayers.items[index].visible = theVisibility;
                    layer.visible = theVisibility;
                    // map.allLayers.items[index].visible = false
                    // layer.visible = false;
                  }
                });

                let promArr: Promise<any>[] = [];
                promArr.push(
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve("s");
                    }, 4000);
                  })
                );
                view.allLayerViews.forEach((elem: any) => {
                  let theProm = new Promise((resolve) => {
                    watchUtils.whenFalse(elem, "updating", () => {
                      resolve(undefined);
                    });
                  });
                  promArr.push(theProm);
                });
                Promise.all(promArr)
                  .then(() => {
                    legend.renderable.then((elem: any) => {
                      setTheLegend(legend);
                    });

                    view
                      .takeScreenshot({ ignorePadding: true })
                      .then((res: any) => {
                        if (res?.dataUrl !== undefined) {
                          setDataURL(res?.dataUrl);
                        }
                      });
                  })
                  .catch((error) => {
                    view.takeScreenshot().then((res: any) => {
                      if (res?.dataUrl !== undefined) {
                        console.error("in set data in error", error);
                        setDataURL(res?.dataUrl);
                      }
                    });
                  })
                  .catch((error: any) => {
                    console.error("in screenshot error", error);
                    return;
                  });
              });
            })
            .catch((err: any) => {
              console.error(err);
              return;
            });
        });
      });
    }
  }, [esriObj, esriToken]);

  return (
    <>
      <div
        ref={mapRef}
        id="gm-print-view-div"
        className={`${dataURL ? "gm-invis" : null}`}
      />

      {legendDone && dataURL ? (
        member.identityInfo.stateFull.toLowerCase() === "alaska" &&
        member.identityInfo.type.toLowerCase() === "senate" ? (
          <img id="DONE" className="w-100 pb-3" src={dataURL} />
        ) : (
          <img id="DONE" className="w-100 pb-3" src={dataURL} />
        )
      ) : null}
      <SectionTitle title="Legend" type="FULL_PAGE">
        <Legend
          esriCode={esriObj}
          legend={theLegend}
          setLegendDone={setLegendDone}
        />
      </SectionTitle>
    </>
  );
};

export default Map;
