import React from "react";
import { observer } from "mobx-react";
import { Committee } from "APTAPackage";
import ListElem from ".";

// @ts-ignore
interface APTAListElemProps {
  theComm: Committee;
  onClick?: any;
}

const CommitteeElem: React.FC<APTAListElemProps> = ({ theComm, onClick }) => {
  return (
    <ListElem
      key={theComm.id}
      onClick={(e: any) => {
        e.stopPropagation();
        if (onClick !== undefined) onClick();
      }}
    >
      <strong> {theComm.committeeName}</strong>
    </ListElem>
  );
};

export default observer(CommitteeElem);
