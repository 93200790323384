"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const names = {
    "L000570": "Ben Ray Luján",
    "C001047": "Shelley Moore Capito",
    "S000168": "Maria Elvira Salazar",
    "G000596": "Marjorie Taylor Greene",
    "D000096": "Danny K. Davis",
    "L000562": "Stephen F. Lynch",
    "K000382": "Ann McLane Kuster",
    "L000273": "Teresa Leger Fernandez",
    "M001185": "Sean Patrick Maloney",
    "S001205": "Mary Gay Scanlon",
    "B001248": "Michael C. Burgess",
    "J000126": "Eddie Bernice Johnson",
    "N000147": "Eleanor Holmes Norton",
    "R000600": "Aumua Amata Radewagen",
    "G000586": "Jesús 'Chuy' García",
    "B001251": "G. K. Butterfield",
    "P000604": "Donald Payne Jr."
};
exports.default = names;
