import { computed, observable, action, makeObservable } from "mobx";
import { SEARCH_PANEL_TYPES, APTA_SEARCH_TYPES, SEARCH_TYPES } from "./types";
import { debounce } from "lodash";
import { Committee } from "APTAPackage";
import { TrieValue } from "../../Types/Tries/TrieValue";
import { genAPTALayer, initAPTALayer } from "../initActions";
import AuthStore from "../newAuthStore/AuthStore";
import { T_committeeMember } from "../../components/Pages/Map/Panels/CommitteePanel";
import { T_committee } from "../../Types/Legislators";
import { compareCommittee } from "../../services/Committees";

class PanelStore {
  private APTAMembers: any | undefined = undefined;
  private dataStoreRef: any = undefined;

  authStoreRef: AuthStore | undefined = undefined;

  @observable searchStatus: SEARCH_TYPES = SEARCH_TYPES.INIT;
  @observable searchString: string = "";
  @observable searchType: SEARCH_PANEL_TYPES =
    SEARCH_PANEL_TYPES.STATE_SEARCH_PANEL;

  @observable searchResults: any[] = [];

  @observable APTAMemberSearchStatus: APTA_SEARCH_TYPES =
    APTA_SEARCH_TYPES.APTA_INIT;

  @observable senCommsOpen = true;
  @observable houseCommsOpen = true;
  @observable jointCommsOpen = true;
  @observable filteredSenComms: Committee[] = [];
  @observable filteredHouseComms: Committee[] = [];
  @observable filteredJointComms: Committee[] = [];

  // Set the search string and debounce a search.
  @action setSearchString(newSearchString: string) {
    this.searchString = newSearchString;
    this.searchStatus = SEARCH_TYPES.LOADING;
    this.deSearch();
  }

  @action setSearchType(newSearchType: SEARCH_PANEL_TYPES) {
    this.searchType = newSearchType;
    this.searchStatus = SEARCH_TYPES.LOADING;
    this.deSearch();
  }

  // Debounced Search
  @action deSearch = debounce(() => this.search(), 1000);

  @action search = async () => {
    if (
      !this.searchString &&
      this.searchType !== SEARCH_PANEL_TYPES.COMMITTEE_SEARCH_PANEL
    ) {
      this.searchStatus = SEARCH_TYPES.INIT;

      return;
    }
    switch (this.searchType) {
      case SEARCH_PANEL_TYPES.LEG_SEARCH_PANEL: {
        this.legSearch();
        break;
      }
      case SEARCH_PANEL_TYPES.STATE_SEARCH_PANEL: {
        this.locSearch();
        break;
      }
      case SEARCH_PANEL_TYPES.COMMITTEE_SEARCH_PANEL: {
        this.commSearch();
        break;
      }
      case SEARCH_PANEL_TYPES.APTA_SEARCH_PANEL: {
        await this.APTASearch();

        break;
      }
    }
  };

  @action legSearch() {
    const lowerSearch = this.searchString.toLowerCase();
    let personList = this.dataStoreRef.Tries?.peopleTrie.find(lowerSearch) as [
      string,
      TrieValue
    ][];
    personList = personList.slice(0, 200);
    this.searchResults = personList;
    this.searchStatus = SEARCH_TYPES.SUCCESS;
  }

  @action locSearch() {
    const locationSearch = this.searchString.toLowerCase();
    let locList = this.dataStoreRef.Tries?.locTrie.find(locationSearch) as [
      string,
      TrieValue
    ][];
    locList = locList.slice(0, 200);
    this.searchResults = locList;
    this.searchStatus = SEARCH_TYPES.SUCCESS;
  }

  // APTA Member Search
  @action APTASearch = async () => {
    try {
      let APTAMembers: any[] = await this.APTAQuery();

      this.searchResults = APTAMembers;
      this.searchStatus = SEARCH_TYPES.SUCCESS;
    } catch (error) {
      this.searchStatus = SEARCH_TYPES.ERROR;
    }
  };

  // APTA Member Search
  APTAQuery = (): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      // Check if the Layer is loaded.
      if (this.APTAMembers) {
        const lowerSearch = this.searchString.toLowerCase();

        let query = this.APTAMembers.createQuery();
        query.where = `cst_name_cp like '%${lowerSearch}%'`;
        query.outFields = ["*"];
        query.num = 100;
        query.returnGeometry = true;

        this.APTAMembers.queryFeatures(query)
          .then((results: any) => {
            resolve(results.features);
          })
          .catch((error: any) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  };

  commSearch() {
    const lowerSearch = this.searchString.toLowerCase();


    if (lowerSearch !== "") {
      this.filteredHouseComms = this.dataStoreRef.houseComms.filter(
        (com: Committee) => {
          let comName = com?.committeeName?.toLowerCase();
          if (comName.includes(lowerSearch)) return com;
        }
      ).sort(compareCommittee);

      this.filteredSenComms = this.dataStoreRef.senComms.filter(
        (com: Committee) => {
          let comName = com?.committeeName?.toLowerCase();
          if (comName.includes(lowerSearch)) return com;
        }
      ).sort(compareCommittee);

      this.filteredJointComms = this.dataStoreRef.jointComms.filter(
        (com: Committee) => {
          let comName = com?.committeeName?.toLowerCase();
          if (comName.includes(lowerSearch)) return com;
        }
      ).sort(compareCommittee);

      if (this.filteredHouseComms.length === 0) this.updateHouseCommsOpen(true);
      if (this.filteredSenComms.length === 0) this.updateSenCommsOpen(true);
      if (this.filteredJointComms.length === 0) this.updateJointCommsOpen(true);
    } else {
      this.getComms();
      if (this.filteredHouseComms.length === 0) this.updateHouseCommsOpen(true);
      if (this.filteredSenComms.length === 0) this.updateSenCommsOpen(true);
      if (this.filteredJointComms.length === 0) this.updateJointCommsOpen(true);
    }
    this.searchStatus = SEARCH_TYPES.SUCCESS;
  }

  @action updateSenCommsOpen = (open: boolean) => {
    this.senCommsOpen = open;
  };

  @action updateHouseCommsOpen = (open: boolean) => {
    this.houseCommsOpen = open;
  };

  @action updateJointCommsOpen = (open: boolean) => {
    this.jointCommsOpen = open;
  };

  @action getComms = () => {
    this.filteredHouseComms = this.dataStoreRef?.houseComms;
    this.filteredSenComms = this.dataStoreRef?.senComms;
    this.filteredJointComms = this.dataStoreRef?.jointComms
  };

  theTimeout = () => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(null), 1000);
    });
  };

  @action initComms = async () => {
    let isLoaded = this.dataStoreRef.loaded;
    while (isLoaded === false) {
      await this.theTimeout();
      isLoaded = this.dataStoreRef.loaded;
    }
    this.getComms();
  };

  constructor(dataStore: any, authStore: AuthStore) {
    makeObservable(this);
    this.dataStoreRef = dataStore;
    this.authStoreRef = authStore;
    this.initComms();

    authStore.GET_esri().then((session) => {
      genAPTALayer(session)
        .then((theLayer) => {
          this.APTAMembers = theLayer;
        })
        .catch((error) => {
          this.APTAMembers = undefined;
        });
    });
  }
}

export default PanelStore;
