import React from "react";

interface SpinnerColors {
  color?: string;
}

const LoadingSpinner: React.FC<SpinnerColors> = ({ color = "white" }) => {
  return (
    <>
      <div className="spinner">
        <div className="rect1" style={{ backgroundColor: color }}></div>
        <div className="rect2" style={{ backgroundColor: color }}></div>
        <div className="rect3" style={{ backgroundColor: color }}></div>
        <span className="spinner-text" style={{ color: color }}>
          Loading. . .
        </span>
      </div>
    </>
  );
};

export default LoadingSpinner;
