import React, { Children } from "react";

import { T_dataComponent } from "../../types";

export type T_subItemProps = {
  src?: string;
  alt?: string;
  size?: string;
  classMod?: string;
  type?: T_dataComponent;
};

type T_props = T_subItemProps;

export const SubItem: React.FC<T_props> = ({
  src,
  alt,
  size,
  children,
  type = 'PAGE',
}) => {
  const className = type === "POSTCARD" ? "-postcard" : "";

  return (
    <div className={`d-flex flex-grow-1 align-items-center m-0 pb-3`}>
      {src ? (
        <img
          src={src}
          alt={`${alt}-img`}
          className={`gm-subitem-img${className}`}
        />
      ) : (
        <div id="EMPTY_IMAGE_HEIGHT_SET" className={`gm-subitem${className}`} />
      )}

      {children}
    </div>
  );
};
