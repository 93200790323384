import React from "react";
import { SEARCH_PANEL_TYPES } from "../../../../../../Stores/SearchStore/types";
import { useStore } from "../../../../../../Hooks";
import { observer } from "mobx-react";

export interface TabProps {
  title: string;
  type: SEARCH_PANEL_TYPES;
}

const Tab: React.FC<TabProps> = ({ title, type }) => {
  const searchStore = useStore("searchStore");
  return (
    <div
      key={title}
      className={`gm-search-panel-tab ${
        searchStore.searchType === type ? "gm-search-panel-tab-active" : null
      }`}
      onClick={() => searchStore.setSearchType(type)}
    >
      {title}
    </div>
  );
};

export default observer(Tab);
