"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegislatorMap = void 0;
const constants_1 = require("../constants");
class LegislatorMap {
    constructor(map) {
        this.map = map;
    }
    getPerson(hashID) {
        if (hashID === undefined)
            return undefined;
        const unparsedHash = hashID.split("-");
        const theType = unparsedHash[0];
        const stateAbbrev = unparsedHash[1];
        const district = unparsedHash[2];
        if (theType === constants_1.LegislatorTypes.STATE_SUM) {
            let theLeaf = this.map?.[stateAbbrev]?.[theType];
            return theLeaf?.members?.[0];
        }
        else if (theType === constants_1.LegislatorTypes.SENATE) {
            let theLeaf = this.map?.[stateAbbrev]?.[theType];
            let index = theLeaf?.hash?.[hashID];
            return theLeaf?.members?.[index];
        }
        else {
            let theLeaf = this.map?.[stateAbbrev]?.[theType]?.[district];
            let index = theLeaf?.hash?.[hashID];
            return theLeaf?.members?.[index];
        }
    }
    getPerson_no_external(hashID) {
        if (hashID === undefined)
            return undefined;
        const unparsedHash = hashID.split("-");
        const theType = unparsedHash[0];
        const stateAbbrev = unparsedHash[1];
        const district = unparsedHash[2];
        if (theType === constants_1.LegislatorTypes.STATE_SUM) {
            let theLeaf = this.map?.[stateAbbrev]?.[theType];
            return theLeaf;
        }
        else if (theType === constants_1.LegislatorTypes.SENATE) {
            let theLeaf = this.map?.[stateAbbrev]?.[theType];
            return theLeaf;
        }
        else {
            let theLeaf = this.map?.[stateAbbrev]?.[theType]?.[district];
            return theLeaf;
        }
    }
    getPerson_API(state, body, APIId) {
        const theData = this.map?.[state]?.[body];
        if (theData === undefined)
            return undefined;
        else if (body === constants_1.LegislatorTypes.SENATE) {
            let theSenators = theData?.members;
            if (!theSenators)
                return undefined;
            let theSen = theSenators.find((senator) => senator.API_ID === APIId);
            return theSen?.identityInfo?.hashID;
        }
        else {
            let houseObj = theData;
            let theHouseKeys = Object.keys(houseObj);
            for (let key of theHouseKeys) {
                let theLeaf = houseObj?.[key];
                let theMembers = theLeaf?.members;
                if (theMembers && APIId === theMembers?.[0]?.API_ID) {
                    return theLeaf.members[0]?.identityInfo?.hashID;
                }
            }
        }
        return undefined;
    }
    getFlatHelper(type, theState) {
        if (type === constants_1.LegislatorTypes.STATE_SUM) {
            return;
        }
        else if (type === constants_1.LegislatorTypes.HOUSE) {
            const theBody = theState?.[type];
            const bodyKeys = Object.keys(theBody);
            let bodyArr = [];
            bodyKeys.forEach((key) => {
                //@ts-ignore
                bodyArr = [...bodyArr, ...theBody?.[key]?.members];
            });
            bodyArr = bodyArr.sort((a, b) => {
                const aDist = Number(a.identityInfo.district);
                const bDist = Number(b.identityInfo.district);
                if (aDist < bDist)
                    return -1;
                if (aDist > bDist)
                    return 1;
                return 0;
            });
            return bodyArr;
        }
        else if (type !== constants_1.LegislatorTypes.SENATE) {
            const theBody = theState?.[type];
            const bodyKeys = Object.keys(theBody);
            let bodyArr = [];
            bodyKeys.forEach((key) => {
                //@ts-ignore
                bodyArr = [...bodyArr, ...theBody?.[key]?.members];
            });
            return bodyArr;
        }
        return theState?.SENATE?.members === undefined
            ? []
            : theState?.SENATE?.members;
    }
    getFlat(state) {
        const theState = this.map?.[state];
        const flatSenate = this.getFlatHelper(constants_1.LegislatorTypes.SENATE, theState);
        const flatHouse = this.getFlatHelper(constants_1.LegislatorTypes.HOUSE, theState);
        const flatSLDL = this.getFlatHelper(constants_1.LegislatorTypes.SLDL, theState);
        const flatSLDU = this.getFlatHelper(constants_1.LegislatorTypes.SLDU, theState);
        const flatAll = [...flatSenate, ...flatHouse, ...flatSLDL, ...flatSLDU];
        return {
            flatAll,
            flatSenate,
            flatHouse,
            flatSLDL,
            flatSLDU,
        };
    }
}
exports.LegislatorMap = LegislatorMap;
