import React from "react";
import { observer } from "mobx-react";

import MemberSummary from "../Explore/Sections/MemberSummary/MemberSummary";
import FederalAppropriations from "../Explore/Sections/FederalAppropriations";
import MajorGrants from "../Explore/Sections/MajorGrants";

import RegionalFunding from "../Explore/Sections/RegionalFunding";

import UsefulLife from "../Explore/Sections/UsefulLife";
import TransportationFootprint from "../Explore/Sections/TransportationFootprint";
import MajorPublicTransit from "../Explore/Sections/MajorPublicTransit";
import DataSummary from "../Explore/Sections/DataSummary/DataSummary2";
import MemberList from "../Explore/Sections/MemberList";

import { T_sectionObj } from "APTAPackage/dist/v2/Sections/Types";
import { T_layerObj } from "APTAPackage/dist/v2/Layers/Types";
import Map from "./Sections/Map/Map";
import Header from "./Sections/Header";
import { usePreparePrint } from "../../Hooks/usePreparePrint";
import DataSectionViewer from "../Explore/DataSectionViewer";
import "./standardprint.scss";
import { GrantDetails } from "../Explore/Sections/GrantDetails/GrantDetails";

const Explore: React.FC = (props: any) => {
  const theURL = new URL(window.location.href);
  const hashId = theURL.searchParams.get("hashId");
  const isPreview = theURL.searchParams.get("preview") === "true";

  const { data, error, esriObj, esriToken, ident } = usePreparePrint();

  // const esriCode = useEsriModules();
  if (error != null) {
    console.error("in error", error);
    return <div id="ERROR"> ERROR </div>;
  }
  // if (esriCode == null || data == null) return null;
  if (data == null || esriToken == null || ident != true) return null;

  const memberData = data?.memberData;
  const printData = data?.printData;

  const sections = data?.printData?.sections as T_sectionObj;
  const layers = data?.printData?.layers as T_layerObj;
  const type = data?.memberData?.theMember?.identityInfo?.type;
  const geometry = data?.memberData?.AGOLData?.coreData?.geometry;

  // if this is true. that means puppeteer/crawler is viewing it.
  // if puppet is viewing set the stirng as pb-1 if not, pb-5

  const isHeadlessClient = navigator?.webdriver ? "pb-1" : "pb-5 mb-5";


  const printJSON = {
    rows: [
      {
        classes: "pb-1 my-4",
        columns: [
          {
            columnSize: 6,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: sections.MemberSummary.visible ? (
                      <MemberSummary
                        data={memberData.AGOLData}
                        type="FULL_PAGE"
                      />
                    ) : null,

                    columnSize: 12,
                    classes:
                      "pb-3 under-map-column-height d-flex flex-column justify-content-center",
                  },
                ],
              },
            ],
          },
          {
            columnSize: 6,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: sections?.FederalAppropriations?.visible ? (
                      <FederalAppropriations
                        type="FULL_PAGE"
                        data={memberData?.AGOLData}
                        dataLoading={false}
                      />
                    ) : null,
                    columnSize: 12,
                    classes:
                      "pb-3 under-map-column-height d-flex flex-column justify-content-center",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: isHeadlessClient,
        columns: [
          {
            columnSize: 6,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: sections?.RegionalFunding.visible ? (
                      <RegionalFunding
                        type="FULL_PAGE"
                        data={memberData?.AGOLData?.coreData}
                        dataLoading={data.featureDataLoading}
                      />
                    ) : null,
                    columnSize: 12,
                    classes:
                      "col-sm d-flex flex-column full-page-print-column-height my-auto",
                  },
                ],
              },
            ],
          },
          {
            columnSize: 6,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: sections?.TransportationFootprint.visible ? (
                      <TransportationFootprint
                        data={memberData?.AGOLData?.transitStatsData}
                        type="FULL_PAGE"
                      />
                    ) : null,
                    columnSize: 12,
                    classes:
                      "col-sm d-flex flex-column my-auto full-page-print-column-height",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        classes: isHeadlessClient,
        columns: [
          {
            columnSize: 6,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "pb-3",
                innerColumns: [
                  {
                    component: sections?.UsefulLifeOfPublicTransit.visible ? (
                      <UsefulLife
                        type="FULL_PAGE"
                        data={memberData?.AGOLData?.transitStatsData}
                        dataLoading={data.featureDataLoading}
                      />
                    ) : null,
                    columnSize: 12,
                    classes: "d-flex flex-column full-page-print-column-height",
                  },
                ],
              },
            ],
          },
          {
            columnSize: 6,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "pb-3",
                innerColumns: [
                  {
                    component: sections?.MajorPublicTransitSystems.visible ? (
                      <MajorPublicTransit
                        type="FULL_PAGE"
                        data={memberData?.AGOLData?.transitSystemData}
                        dataLoading={data.featureDataLoading}
                      />
                    ) : null,

                    columnSize: 12,
                    classes: "d-flex flex-column full-page-print-column-height",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-1",
        columns: [
          {
            columnSize: 12,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "pb-3",
                innerColumns: [
                  {
                    component: sections?.MajorGrantAwards?.visible ? (
                      <MajorGrants
                        type="FULL_PAGE"
                        data={memberData?.AGOLData?.grantsData}
                        dataLoading={data.featureDataLoading}
                        coreData={memberData?.AGOLData?.coreData}
                        member={memberData?.theMember}
                      />
                    ) : null,

                    columnSize: 12,
                    classes:
                      "col-sm col-md d-flex d-flex flex-column full-page-print-column-height",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-1 d-print-block",
        columns: [
          {
            columnSize: 12,
            classes: "",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: sections?.GrantDetails.visible ? (
                      <>
                        <GrantDetails
                          type="FULL_PAGE"
                          coreData={memberData?.AGOLData?.coreData}
                          countyData={memberData?.AGOLData?.countyData}
                          member={memberData?.theMember}
                        />
                      </>
                    ) : null,
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-5 pt-5 pt-md-2",
        columns: [
          {
            columnSize: 12,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "pb-3",
                innerColumns: [
                  {
                    component: sections?.GrantDetails.visible ? (
                      <>
                        <DataSummary
                          type="FULL_PAGE"
                          coreData={memberData?.AGOLData?.coreData}
                          countyData={memberData?.AGOLData?.countyData}
                          dataLoading={data.featureDataLoading}
                          dataView="both"
                        />
                      </>
                    ) : null,
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        classes: "pb-1 d-print-block",
        columns: [
          {
            columnSize: 12,
            classes: "",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: sections?.APTAMembers.visible ? (
                      <MemberList
                        type="FULL_PAGE"
                        title="APTA Members"
                        members={memberData?.AGOLData?.aptaMembers}
                        dataLoading={data.featureDataLoading}
                        isTop={true}
                      />
                    ) : null,
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-1",
        columns: [
          {
            columnSize: 12,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: sections?.BusManufacturers.visible ? (
                      <MemberList
                        type="FULL_PAGE"
                        title="Bus Manufacturers"
                        members={memberData?.AGOLData?.busMembers}
                        dataLoading={data.featureDataLoading}
                      />
                    ) : null,
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-1 ",
        columns: [
          {
            columnSize: 12,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: sections?.RailManufacturers.visible ? (
                      <MemberList
                        type="FULL_PAGE"
                        title="Rail Manufacturers"
                        members={memberData?.AGOLData?.railMembers}
                        dataLoading={data.featureDataLoading}
                      />
                    ) : null,
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-1 ",
        columns: [
          {
            columnSize: 12,
            classes: "d-print-block",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: sections?.DBEMembers.visible ? (
                      <MemberList
                        type="FULL_PAGE"
                        title="DBE Members"
                        members={memberData?.AGOLData?.DBEMembers}
                        dataLoading={data.featureDataLoading}
                        isTop={true}
                      />
                    ) : null,
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <>
      {isPreview && (
        <div className="container d-print-none">
          <div className="row mt-3">
            <div className="col text-center">
              <h1>Print Preview</h1>
            </div>
          </div>
        </div>
      )}
      <div
        className="container mt-3 text-sm-left text-center mt-3 gm-print"
        style={
          isPreview
            ? {
                boxShadow: "0px 0px 8px black",
                borderRadius: "15px",
                padding: "30px",
              }
            : {}
        }
      >
        <div className="row">
          <div className="col-md-12 col-sm-12 d-flex flex-column pb-1">
            <Header legislator={memberData.theMember} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 d-flex flex-column">
            <Map
              esriObj={esriObj}
              esriToken={esriToken}
              legislatorType={type}
              layers={layers}
              geometry={geometry}
              member={memberData.theMember}
            />
          </div>
        </div>
        <DataSectionViewer dataJSON={printJSON} />
      </div>
    </>
  );
};

export default observer(Explore);

// const orderedList = [
//   "MemberSummary",
//   "FederalAppropriations",
//   "TransportationFootprint",
//   "RegionalFunding",
//   "MajorGrantAwards",
//   "MajorPublicTransitSystems",
//   "UsefulLifeOfPublicTransit",
//   "DataSummary",
//   "APTAMembers",
//   "BusManufacturers",
//   "RailManufacturers",
//   "DBEMembers",
// ];

// const rows: any = [];
// let tempColumns: any = [];
// const columnsObject: any = {
//   MemberSummary: {
//     columnSize: 6,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "",
//         innerColumns: [
//           {
//             component: (
//               <MemberSummary data={memberData.AGOLData} type="FULL_PAGE" />
//             ),

//             columnSize: 12,
//             classes:
//               "pb-3 full-page-print-column-height d-flex flex-column justify-content-center",
//           },
//         ],
//       },
//     ],
//   },
//   FederalAppropriations: {
//     columnSize: 6,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "",
//         innerColumns: [
//           {
//             component: sections?.FederalAppropriations?.visible ? (
//               <FederalAppropriations
//                 type="FULL_PAGE"
//                 data={memberData?.AGOLData}
//                 dataLoading={false}
//               />
//             ) : null,
//             columnSize: 12,
//             classes:
//               "pb-3 full-page-print-column-height d-flex flex-column justify-content-center",
//           },
//         ],
//       },
//     ],
//   },
//   TransportationFootprint: {
//     columnSize: 6,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "",
//         innerColumns: [
//           {
//             component: sections?.TransportationFootprint.visible ? (
//               <TransportationFootprint
//                 data={memberData?.AGOLData?.coreData}
//                 type="FULL_PAGE"
//               />
//             ) : null,
//             columnSize: 12,
//             classes:
//               "col-sm d-flex flex-column my-auto full-page-print-column-height",
//           },
//         ],
//       },
//     ],
//   },
//   RegionalFunding: {
//     columnSize: 6,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "",
//         innerColumns: [
//           {
//             component: sections?.RegionalFunding.visible ? (
//               <RegionalFunding
//                 type="FULL_PAGE"
//                 data={memberData?.AGOLData?.coreData}
//                 dataLoading={data.featureDataLoading}
//               />
//             ) : null,
//             columnSize: 12,
//             classes:
//               "col-sm d-flex flex-column full-page-print-column-height my-auto",
//           },
//         ],
//       },
//     ],
//   },
//   MajorGrantAwards: {
//     columnSize: 12,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "pb-3",
//         innerColumns: [
//           {
//             component: sections?.MajorGrantAwards?.visible ? (
//               <MajorGrants
//                 type="FULL_PAGE"
//                 data={memberData?.AGOLData?.grantsData}
//                 dataLoading={data.featureDataLoading}
//               />
//             ) : null,

//             columnSize: 12,
//             classes:
//               "col-sm col-md d-flex d-flex flex-column full-page-print-column-height",
//           },
//         ],
//       },
//     ],
//   },
//   MajorPublicTransitSystems: {
//     columnSize: 6,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "pb-3",
//         innerColumns: [
//           {
//             component: sections?.MajorPublicTransitSystems.visible ? (
//               <MajorPublicTransit
//                 type="FULL_PAGE"
//                 data={memberData?.AGOLData?.transitSystemData}
//                 dataLoading={data.featureDataLoading}
//               />
//             ) : null,

//             columnSize: 12,
//             classes: "d-flex flex-column full-page-print-column-height",
//           },
//         ],
//       },
//     ],
//   },
//   UsefulLifeOfPublicTransit: {
//     columnSize: 6,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "pb-3",
//         innerColumns: [
//           {
//             component: sections?.UsefulLifeOfPublicTransit.visible ? (
//               <UsefulLife
//                 type="FULL_PAGE"
//                 data={memberData?.AGOLData?.coreData}
//                 dataLoading={data.featureDataLoading}
//               />
//             ) : null,
//             columnSize: 12,
//             classes: "d-flex flex-column full-page-print-column-height",
//           },
//         ],
//       },
//     ],
//   },
//   DataSummary: {
//     columnSize: 12,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "pb-3",
//         innerColumns: [
//           {
//             component: sections?.DataSummary.visible ? (
//               <DataSummary
//                 type="FULL_PAGE"
//                 coreData={memberData?.AGOLData?.coreData}
//                 countyData={memberData?.AGOLData?.countyData}
//                 dataLoading={data.featureDataLoading}
//                 dataView="both"
//               />
//             ) : null,
//             columnSize: 12,
//             classes: "",
//           },
//         ],
//       },
//     ],
//   },
//   APTAMembers: {
//     columnSize: 12,
//     classes: "",
//     innerRows: [
//       {
//         classes: "",
//         innerColumns: [
//           {
//             component: sections?.APTAMembers.visible ? (
//               <MemberList
//                 type="FULL_PAGE"
//                 title="APTA Members"
//                 members={memberData?.AGOLData?.aptaMembers}
//                 dataLoading={data.featureDataLoading}
//                 isTop={true}
//               />
//             ) : null,
//             columnSize: 12,
//             classes: "",
//           },
//         ],
//       },
//     ],
//   },
//   BusManufacturers: {
//     columnSize: 12,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "",
//         innerColumns: [
//           {
//             component: sections?.BusManufacturers.visible ? (
//               <MemberList
//                 type="FULL_PAGE"
//                 title="Bus Manufacturers"
//                 members={memberData?.AGOLData?.busMembers}
//                 dataLoading={data.featureDataLoading}
//               />
//             ) : null,
//             columnSize: 12,
//             classes: "",
//           },
//         ],
//       },
//     ],
//   },
//   RailManufacturers: {
//     columnSize: 12,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "",
//         innerColumns: [
//           {
//             component: sections?.RailManufacturers.visible ? (
//               <MemberList
//                 type="FULL_PAGE"
//                 title="Rail Manufacturers"
//                 members={memberData?.AGOLData?.railMembers}
//                 dataLoading={data.featureDataLoading}
//               />
//             ) : null,
//             columnSize: 12,
//             classes: "",
//           },
//         ],
//       },
//     ],
//   },
//   DBEMembers: {
//     columnSize: 12,
//     classes: "d-print-block",
//     innerRows: [
//       {
//         classes: "",
//         innerColumns: [
//           {
//             component: sections?.DBEMembers.visible ? (
//               <MemberList
//                 type="FULL_PAGE"
//                 title="DBE Members"
//                 members={memberData?.AGOLData?.DBEMembers}
//                 dataLoading={data.featureDataLoading}
//                 isTop={true}
//               />
//             ) : null,
//             columnSize: 12,
//             classes: "",
//           },
//         ],
//       },
//     ],
//   },
// };

// let sum = 0;
// orderedList.forEach((item: string) => {
//   if (sections[item].visible) {
//     if (columnsObject[item].columnSize === 12) {
//       rows.push({ columns: [columnsObject[item]] });
//     } else {
//       tempColumns.push(columnsObject[item]);
//       sum += columnsObject[item].columnSize;
//     }
//     if (sum === 12) {
//       rows.push({ columns: tempColumns });
//       tempColumns = [];
//       sum = 0;
//     }
//   }
// });

// if (tempColumns.length > 0) {
//   rows.unshift({ columns: tempColumns });
// }
// const printJSON = { rows };
