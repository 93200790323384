import React from "react";
import { typeToClass, T_dataComponent } from "../../types";

type T_collapseProps = {
  startOpen: boolean;
  count: string;
};

type T_sectionTitle = {
  title?: string;
  subTitle?: string;
  collapseProps?: T_collapseProps;
  isTop?: boolean;
  type: T_dataComponent;
  children?: React.ReactNode;
};

type T_props = T_sectionTitle;

export const SectionTitle: React.FC<T_props> = ({
  title,
  subTitle,
  isTop = false,
  collapseProps,
  type,
  children,
}) => {
  const classMod = typeToClass(type);

  return (
    <>
      <div className="row">
        <div className="col">
          <div className={`pb-3 mb-3 gm-section-header${classMod} d-flex`}>
            {title !== undefined ? (
              <h1 className={`m-0  gm-explore-header${classMod}`}>{title}</h1>
            ) : null}

            {isTop ? (
              <h1 className={`m-0 ml-2 gm-explore-subtext${classMod}`}>
                {" "}
                {" (* Denotes Main Office)"}
              </h1>
            ) : null}
            {collapseProps?.count != null && (
              <>
                <h1 className={`ml-auto gm-explore-numeric${classMod} mr-3`}>
                  {collapseProps?.count}
                </h1>
              </>
            )}
          </div>
        </div>
      </div>
      {subTitle && (
        <div className="row">
          <div className="col">
            <h2 className={`gm-explore-subtext${classMod} mb-3`}>{subTitle}</h2>
          </div>
        </div>
      )}

      {children}
    </>
  );
};
