export enum PANEL_TYPES {
  EXPLORE = "EXPLORE",
  APTAMEMBER = "APTAMEMBER",
  COMMITTEE = "COMMITTEE",
  MEMBER_SELECT = "MEMBER_SELECT",
  SELECT_STATE = "SELECT_STATE",
  STATES = "STATES",
  FED_HOUSE = "FED_HOUSE",
  STATE_UPPER = "STATE_UPPER",
  STATE_LOWER = "STATE_LOWER",
  PERSON = "PERSON",
  PERSONLIST = "PERSONLIST",
  SEARCH = "SEARCH",
  PRINT = "PRINT",
}

export enum SEARCH_PANEL_TYPES {
  LEG_SEARCH_PANEL = "LEG_SEARCH_PANEL",
  STATE_SEARCH_PANEL = "STATE_SEARCH_PANEL",
  APTA_SEARCH_PANEL = "APTA_SEARCH_PANEL",
  COMMITTEE_SEARCH_PANEL = "COMMITTEE_SEARCH_PANEL",
}

export enum APTA_SEARCH_TYPES {
  APTA_INIT = "APTA_INIT",
  APTA_LOADING = "APTA_LOADING",
  APTA_ERROR = "APTA_ERROR",
  APTA_SUCCESS = "APTA_SUCCESS",
}

export enum FEATURE_DATA_TYPES {
  INIT = "INIT",
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export enum SEARCH_TYPES {
  INIT = "INIT",
  LOADING = "LOADING",
  ERROR = "_ERROR",
  SUCCESS = "SUCCESS",
}
