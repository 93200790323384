import React, { useRef, useEffect, useState } from "react";

interface MapProps {
  esriCode: any;
  legend: any;
  setLegendDone: any;
}

const Legend: React.FC<MapProps> = ({ esriCode, legend, setLegendDone }) => {
  const [theLayers, setTheLayers] = useState<any>([]);

  useEffect(() => {
    let layers: any[] = [];

    // Make sure all Legend Layers are ready.
    let promArr: Promise<any>[] = [];

    legend?.activeLayerInfos?.items?.forEach((layer: any) => {
      if (layer?.ready !== undefined)
        promArr.push(
          new Promise((resolve) => {
            //@ts-ignore
            esriCode.watchUtils.whenTrueOnce(layer, "ready", () => resolve());
          })
        );
    });
    // promArr.push(
    //   new Promise(function (resolve) {
    //     setTimeout(() => resolve(null), 60000);
    //   })
    // );
    // All Layers ready
    Promise.all(promArr)
      .then(() => {
        legend?.activeLayerInfos?.items?.forEach((layer: any) => {

          const theLayer = { title: layer.title, legend: {} };
          layer.legendElements.forEach((legElem: any) => {
      
            let subElems: any[] = [];
            const theLegendElem = { title: legElem.title, subElems };
            legElem.infos.forEach((info: any) => {
              const theInfoElem: any = {
                title: info.label,
                preview: info?.preview
                  ? info?.preview
                  : { innerHTML: `<div> <img src=${info.src} /> </div>` },
              };
              theLegendElem.subElems.push(theInfoElem);
            });
            theLayer.legend = theLegendElem;
          });
          layers.push(theLayer);
        });

        layers = layers.sort((a: any, b: any) => {
          if (a.legend.subElems.length < b.legend.subElems.length) return 1;
          else if (a.legend.subElems.length > b.legend.subElems.length)
            return -1;
          return 0;
        });

        setTheLayers(layers);
      })
      .catch((error) => {
        return;
      });
  }, [legend]);

  useEffect(() => {
    if (theLayers != null && theLayers.length != 0) {
      setTimeout(() => {
        setLegendDone(true);
      }, 2000);
    }
  }, [theLayers]);

  return (
    <div className="gm-map-legend-container row">
      {theLayers.map((layer: any) => {
        return (
          <div className="gm-map-legend-layer col-3 pb-3" key={layer?.title}>
            <h1 className="gm-explore-text-2 pb-2"> {layer?.title} </h1>
            {layer.legend.subElems.map((info: any, index: number) => {

              return (
                <div
                  className="gm-map-legend-elem pb-2"
                  key={
                    info?.title ? info.title : `infos-elem-${index}-${index}`
                  }
                >
                  <div
                    className="mr-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        info.preview.innerHTML == ''
                          ? info.preview.outerHTML
                          : info.preview.innerHTML,
                    }}
                  />

                  <label className="gm-explore-subtext-legend">
                    {" "}
                    {info?.title}{" "}
                  </label>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Legend;
