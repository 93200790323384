import React, { useMemo } from "react";
import { FedLeg, StateLeg } from "APTAPackage";
import { LegislatorImage } from "../Components/LegislatorImage";
import { PersonImage } from "../../../_v2/Common/Components/PersonImage";

type T_props = {
  legislator: FedLeg | StateLeg;
};

const Header: React.FC<T_props> = ({ legislator }) => {
  const renderData = useMemo(() => {
    let stateAbbrev = "";
    const stateAbbrevRaw = legislator?.identityInfo?.stateAbbrev;
    if (stateAbbrevRaw != null) stateAbbrev = stateAbbrevRaw.toLowerCase();

    let title = legislator?.title ? (legislator.title as string) : undefined;
    const party = legislator?.party ? (legislator.party as string) : undefined;

    const stateFull = legislator?.identityInfo?.stateFull;
    const phone = legislator?.contactInfo?.phone1
      ? `(${legislator?.contactInfo?.phone1})`
      : undefined;
    const name = legislator?.fullName
      ? (legislator?.fullName as string)
      : undefined;
    const legislatorImage = legislator?.image
      ? (legislator?.image as string)
      : undefined;

    const seniorJuniorRegex = /Senior|senior|Junior|junior/gm;

    // NO SENIOR OR JUNIOR
    if (title && seniorJuniorRegex.test(title as string)) {
      title = title.replace(seniorJuniorRegex, "");
    }

    const detailsArr = [stateFull, title, party, phone];

    return {
      detailsArr,
      name,
      legislatorImage,
    };
  }, [legislator]);

  const { detailsArr, name, legislatorImage } = renderData;

  return (
    <div className="row gm-standard-print-header pt-2 pb-2">
      <div className="col d-flex gm-header-person">
        <PersonImage person={legislator} />
        <div className="pl-3 my-auto">
          <h1 className="gm-explore-text"> {name} </h1>
          <div className=" d-flex">
            {detailsArr.map((detail, index) => {
              if (detail == null) return null;
              else if (index != detailsArr.length - 1) {
                return (
                  <React.Fragment key={`${detail}-${index}`}>
                    <h1 className="gm-explore-text pr-3">{detail}</h1>
                    <h1 className="gm-explore-text pr-3"> | </h1>
                  </React.Fragment>
                );
              } else {
                return (
                  <h1 className="gm-explore-text" key={`${detail}-${index}`}>
                    {detail}
                  </h1>
                );
              }
            })}
          </div>
        </div>
        <div className="ml-auto my-auto text-center gm-header-logo">
          <img
            className="gm-rounded"
            style={{ width: "4rem", height: "2rem" }}
            alt="APTA Logo"
            src="/images/apta_logo_2022.png"
          />
          <div className="text-center">
            <b>footprint.apta.com</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
