import React, { useState } from "react";
import { observer } from "mobx-react";
import Panel from "./BasePanel/Panel";
import CollapsibleHeader from "./CollapsibleHeader";
import PersonList from "./thePersonList";
import {
  AddAllState,
  AddAllSenState,
  AddAllHouseState,
  AddAllSLDUState,
  AddAllSLDLState,
} from "./ListElems/AddButtons";

import { useStore } from "../../../../Hooks";
import { LegislatorTypes } from "APTAPackage";

//import "../StatesPanelComponent.css";

const StatePanel: React.FC = () => {
  const panelStore = useStore("panelStore");

  const dataStore = useStore("dataStore");

  const [list, setList] = useState([]);
  const [value, setValue] = useState("");

  const onClickViewSection = (event: any, func: any, newState: any) => {
    event.stopPropagation();
    func(!newState);
  };

  const onChange = (e: any, list: any) => {
    let value = e.target.value;
    let arr = [];
    list = list.filter((elem: any) => {
      if (elem.fullName.toLowerCase().includes(value.toLowerCase()))
        return elem;
    });
    setValue(value);
    setList(list);
  };

  const flatData = dataStore.getFlat(panelStore.activeState);

  if (flatData === undefined)
    return (
      <Panel>
        <div>No Data Available</div>
      </Panel>
    );
  return value === "" ? (
    <Panel>
      <div style={{ height: 50 }}>
        <input
          id="app-search-input"
          type="text"
          style={{ height: 50 }}
          placeholder="Filter Government Officals"
          className="input-search input-minimal"
          onChange={(e) => onChange(e, flatData.flatAll)}
          value={value}
        />
      </div>
      <AddAllState legs={flatData.flatAll} stateKey={panelStore.activeState} />
      {/* ~~~~~~~~~~~~~~~~~ FEDERAL SENATORS  ~~~~~~~~~~~~~~~~~ */}
      {flatData.flatSenate.length ? (
        <CollapsibleHeader
          title="Federal Senators"
          controlledOpen={panelStore.senateOpen}
          controlledOpenFunc={() =>
            panelStore.setSectionOpen(LegislatorTypes.SENATE)
          }
          listQuantity={flatData.flatSenate.length.toString()}
        >
          <PersonList
            key={"Senate"}
            sortList={false}
            people={flatData.flatSenate}
            elem={<AddAllSenState legs={flatData.flatSenate} />}
          />
        </CollapsibleHeader>
      ) : null}
      {
        // ~~~~~~~~~~~~~~~~~ FEDERAL HOUSE MEMBERS  ~~~~~~~~~~~~~~~~~
        flatData.flatHouse.length ? (
          <CollapsibleHeader
            title="Congressional Districts"
            controlledOpen={panelStore.houseOpen}
            controlledOpenFunc={() =>
              panelStore.setSectionOpen(LegislatorTypes.HOUSE)
            }
            listQuantity={flatData.flatHouse.length.toString()}
          >
            <PersonList
              key={"CON DISTRICTS"}
              sortList={false}
              people={flatData.flatHouse}
              elem={<AddAllHouseState legs={flatData.flatHouse} />}
            />
          </CollapsibleHeader>
        ) : null
      }
      <React.Fragment>
        {
          // // ~~~~~~~~~~~~~~~~~ THE UPPER CHAMBER SOLO ~~~~~~~~~~~~~~~~~
          flatData?.flatSLDU?.length ? (
            <CollapsibleHeader
              title="State Upper Chamber"
              controlledOpen={panelStore.SLDUOpen}
              controlledOpenFunc={() =>
                panelStore.setSectionOpen(LegislatorTypes.SLDU)
              }
              listQuantity={flatData.flatSLDU.length.toString()}
            >
              <PersonList
                key={"UPPER-NC"}
                sortList={false}
                people={flatData.flatSLDU}
                elem={
                  <AddAllSLDUState
                    stateKey={panelStore.activeState}
                    legs={flatData.flatSLDU}
                  />
                }
              />
            </CollapsibleHeader>
          ) : null
        }
        {
          // ~~~~~~~~~~~~~~~~~ THE LOWER CHAMBER SOLO  ~~~~~~~~~~~~~~~~~
          flatData?.flatSLDL?.length ? (
            <CollapsibleHeader
              title="State Lower Chamber"
              controlledOpen={panelStore.SLDLOpen}
              controlledOpenFunc={() =>
                panelStore.setSectionOpen(LegislatorTypes.SLDL)
              }
              listQuantity={flatData.flatSLDL.length.toString()}
            >
              <PersonList
                key={"LOWER-NC"}
                sortList={false}
                people={flatData.flatSLDL}
                elem={
                  <AddAllSLDLState
                    stateKey={panelStore.activeState}
                    legs={flatData.flatSLDL}
                  />
                }
              />
            </CollapsibleHeader>
          ) : null
        }
      </React.Fragment>
    </Panel>
  ) : (
    <Panel>
      <div style={{ height: 50 }}>
        <input
          id="app-search-input"
          type="text"
          placeholder="Filter Government Officals"
          className="input-search input-minimal"
          autoComplete="off"
          onChange={(e) => onChange(e, flatData.flatAll)}
          value={value}
        />
      </div>
      {!list?.length ? <p> No Legislators Found</p> : null}
      <PersonList people={list} sortList={false} />
    </Panel>
  );
};

export default observer(StatePanel);
