import React from "react";
import { HomeHeader } from "../HomeHeader";
import { TransitBox, T_transitBoxProps } from "./TransitBox";

const items: T_transitBoxProps[] = [
  { count: "1,308", label: "RURAL", isBorder: true, color: "text-blue" },
  { count: "945", label: "URBAN", isBorder: true, color: "text-orange" },
  {
    count: "4,500+",
    label: "NONPROFIT",
    isBorder: false,
    color: "text-purple",
  },
];

export const TransitHome = () => {
  return (
    <div className="row">
      <div className="col">
        <HomeHeader title="Transit Systems" id="transit_systems" />

        <div className="row">
          {items.map((item) => {
            return (
              <React.Fragment key={`${item?.count}-${item?.label}`}>
                <TransitBox {...item} />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
