import React, { useEffect, useState } from "react";

import { T_configType } from "../../../Stores/newCartStore.ts/newCartStore";
import { useStore } from "../../../Hooks";
import { observer } from "mobx-react";

import { CartButton } from "../Components/CartButton/CartButton";
import { BsChevronUp, BsChevronDown, BsSlashCircle } from "react-icons/bs";
import { Collapse } from "react-collapse";

interface CartSelectProps {
  title: string;
  type: T_configType;
}

const titleStyle = { display: "flex", borderBottom: "solid black 1px" };

const CartSelect: React.FC<CartSelectProps> = ({ title, type }) => {
  const newCartStore = useStore("newCartStore");

  const { printType, cartPage } = newCartStore;

  const [isOpen, setIsOpen] = useState(printType === "POSTCARD" ? false : true);

  const config = newCartStore.getConfig(type);
  const keys = Object.keys(config);




  const parsedIsOpen = printType === "POSTCARD" ? false : isOpen;

  useEffect(() => {
    if (printType == "POSTCARD") {
      setIsOpen(true);
    }
    if (printType === "PAGE") {
      newCartStore.resetConfig(type);
    }
  }, [printType, cartPage]);

  return (
    <div className="mb-3">
      <div
        style={
          (printType === "POSTCARD" && type !== "fileOptions") ||
          (printType !== "POSTCARD" && type === "fileOptions")
            ? { ...titleStyle, cursor: "not-allowed", opacity: 0.5 }
            : { ...titleStyle, cursor: "pointer" }
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 style={{ fontWeight: 300 }}> {title}</h4>
        <div className="ml-auto">
          {(printType === "POSTCARD" && type !== "fileOptions") ||
          (printType !== "POSTCARD" && type === "fileOptions") ? (
            <BsSlashCircle size="1.25rem" />
          ) : isOpen ? (
            <BsChevronDown size="1.25rem" onClick={() => setIsOpen(!isOpen)} />
          ) : (
            <BsChevronUp size="1.25rem" onClick={() => setIsOpen(!isOpen)} />
          )}
        </div>
      </div>

      <Collapse
        isOpened={
          (parsedIsOpen && type !== "fileOptions") ||
          (printType === "POSTCARD" &&
            type === "fileOptions" &&
            isOpen === true)
        }
      >
        {type === "fileOptions" ? (
          <div className="row mt-3 justify-content-center">
            {keys.map((key) => {
              //@ts-expect-error
              const item = config?.[key];



          
              return (
                <div className="col-4 col-md-2 col-sm-4 mx-m-2 mx-s-0 text-center">
                  <div
                    className={`card py-3 ${
                      item.visible ? "gm-format-card-active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      newCartStore.updateConfig(type, item.key);
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="gm-cart-select-elem-container mt-3 row">
            {keys.map((key: string) => {
              const item = config[key];
              if (
                item.key === "SLDL" ||
                item.key === "SLDU" ||
                item.key === "HOUSE" ||
                item.key === "STATES"
              )
                return null;
              return (
                <div
                  key={item.key}
                  className="gm-cart-select-elem card"
                  onClick={(e) => {
                    e.stopPropagation();
                    newCartStore.updateConfig(type, item.key);
                  }}
                >
                  <section className="d-inline-flex">
                    <input
                      className="d-inline"
                      type="checkbox"
                      checked={item.visible}
                      readOnly
                    />
                    <h1 className="d-inline"> {item.name}</h1>
                  </section>
                </div>
              );
            })}
            <div className=" mb-3 text-md-right text-center w-100">
              <CartButton
                label="Select Defaults"
                className="mr-3"
                onClick={() => newCartStore.resetConfig(type)}
              />{" "}
              <CartButton
                label="Clear Selection"
                onClick={() => newCartStore.clearConfig(type)}
              />
            </div>
          </div>
        )}
      </Collapse>
    </div>
  );
};

export default observer(CartSelect);
