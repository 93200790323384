import React, { useEffect, useMemo, useState } from "react";
import { CoreData, Grants } from "APTAPackage";


import Legend from "./Legend";

import { SectionTitle } from "../../Components/SectionTitle";
import BarGraph from "../../../Postcard/SectionComponents/Graphs/BarGraph";
import { genMoneyString } from "../../../../components/Pages/Print/helpers";
import { graphColors } from "../../Config";
import { T_dataComponent } from "../../../types";
import NoData from "../../Components/NoData";
import LoadingSpinner from "../../../../components/Icons/LoadingSpinner";
import { useStore } from "../../../../Hooks";
import { useHistory } from "react-router-dom";
import { useGetGrants } from "../../../../services";
import { FedLeg } from "aptapackage";
import { StateLeg } from "aptapackage";
import { Grant } from "APTAPackage/dist/src/ESRIResponses/GrantData/Grant";
// import NoData from "../NoData";

interface IProps {
  data: Grants;
  dataLoading: boolean;
  type: T_dataComponent;
  coreData?: CoreData;
  member?: FedLeg | StateLeg
}

const callback = (value: any, index: any, values: any) => {
  return genMoneyString(value);
};

const MajorGrants: React.FC<IProps> = ({
  data,
  dataLoading,
  type,
  coreData,
  member
}) => {
  // const grantData = data?.grants;

  const history = useHistory();
  const panelStore = useStore("panelStore");
  const authStore = useStore("authStore");

  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    authStore.GET_esri().then((res) => {
      setToken(res.token);
    });
  }, []);

  const activerPersonIdentity = member?.identityInfo != null  ? member.identityInfo : panelStore?.activePerson?.identityInfo;

  const pathName = history.location.pathname.toLowerCase();

  const searchQuery = history.location.search;

  const searchObj = searchQuery
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}) as any;

  const hashId = searchObj?.hashId?.toLowerCase() as string;

  const state = (panelStore?.activeState ||
    coreData?.identityInfo?.stateAbbrev) as string;

  const getGrants = useGetGrants({
    state,
    token,
    pathName,
    hashId,
    activerPersonIdentity,
  });

  // // let theData = [];
  // let labels: string[] = [];
  // // let grants: Grant[] = [];

  // if (grantData?.length > 0) {
  //   let val = 3;
  //   if (grantData?.length < 3) val = grantData.length;
  //   for (let i = 0; i < val; i++) {
  //     // theData.push(grantData[i].Allocation as number);
  //     labels.push(`Agency ${1}`);
  //     // grants.push(grantData[i]);
  //   }
  // }

  const { theData, grants } = useMemo(() => {
    let theData = [];
    let labels: string[] = [];
    let grants: Grant[] = [];

    if (getGrants?.data?.length > 0) {
      // sort them first
      const sorted = getGrants?.data?.sort((a, b) => {
        return b.Allocation - a.Allocation;
      });
      let val = 3;
      if (sorted?.length < 3) val = sorted.length;
      for (let i = 0; i < val; i++) {
        const current = sorted?.[i];

   


        let allocation = current?.Allocation as number;
        if(allocation == null) theData.push('N/A');
        //@ts-ignore
        allocation = allocation.toFixed(0)
        theData?.push(allocation as number);
        labels?.push(`Agency ${1}`);
        // @ts-ignore
        grants?.push(current);
      }
    }

    return { theData, labels, grants };
  }, [getGrants?.isLoading]);



  

  return (
    <SectionTitle title="Major Grant Awards" type={type}>
      {dataLoading ? (
        <LoadingSpinner color="var(--apta-navbar-text-color)" />
      ) : theData.length === 0 ? (
        <NoData />
      ) : (
        // <div className="row  d-flex flex-grow-1">
        //   <div className="col d-flex flex-column justify-content-center">
        <div className={`row ${type !== "WEBSITE" ? "h-100" : ""}`}>
          <div className="col mt-1">
            <BarGraph
              data={theData}
              labels={["1", "2", "3"]}
              colors={graphColors}
              yAxesCallback={callback}
              aspectRatio={type === "POSTCARD" ? 2.1 : 1.4}
              chartElementId="MajorGrants"
              tooltip={{
                callbacks: {
                  label: function (context: any) {
                    return ` $${context.formattedValue}`;
                  },
                },
              }}
            />
          </div>
          <div className="col-sm-6 col-md-6 d-flex flex-column justify-content-center">
            <Legend grants={grants} />
          </div>
        </div>
      )}
    </SectionTitle>
  );
};

export default MajorGrants;
