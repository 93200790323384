import ViewHideIcon from "calcite-ui-icons-react/ViewHideIcon";
import ViewVisibleIcon from "calcite-ui-icons-react/ViewVisibleIcon";
import React from "react";

type T_props = {
  key: string;
  title: string;
  checked: boolean;
  onClick: any;
};

export const CheckItem: React.FC<T_props> = ({
  key,
  title,
  checked,
  onClick,
}) => {
  return (
    <div
      key={key}
      className="gm-cart-select-elem card"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <div className="d-flex align-items-center ">
        {checked ? <ViewVisibleIcon /> : <ViewHideIcon />}

        <h1 className="pl-3"> {title}</h1>
      </div>
    </div>
  );
};
