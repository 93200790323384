import React from "react";

type T_props = {
  isHR: boolean;
};

export const Space: React.FC<T_props> = ({ isHR }) => {
  return (
    <div className="row">
      <div className="col">
        <hr style={isHR ?  undefined : { borderColor: "white" }} />
      </div>
    </div>
  );
};
