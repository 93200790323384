import TrieMap from "mnemonist/trie-map";
import { stateAbbrevToFull, LegislatorTypes } from "APTAPackage";
import { FedLeg, StateLeg } from "APTAPackage";

export default class TrieMaster {
  generalTrie: TrieMap<string, string>;
  peopleTrie: TrieMap<string, string>;
  locTrie: TrieMap<string, string>;

  setNameVariations = (person: FedLeg | StateLeg) => {
    let fullName: string = person?.fullName as string;

    let lowerName = fullName.toLowerCase();

    let theTrieValue = person.identityInfo.hashID;

    //let theTrieValue = genTrieValue(person, "PERSON");

    this.generalTrie.set(lowerName, theTrieValue);
    this.peopleTrie.set(lowerName, theTrieValue);
    let splitName = lowerName.split(" ");

    splitName.forEach((subStr) => {
      this.generalTrie.set(
        `${subStr} ${person.identityInfo.builtID}`,
        theTrieValue
      );
      this.peopleTrie.set(
        `${subStr} ${person.identityInfo.builtID}`,
        theTrieValue
      );
    });
  };

  setStateVariations = (stateAbbrev: string) => {
    let lowerState = stateAbbrev.toLowerCase();
    let stateFull = stateAbbrevToFull[stateAbbrev].toLowerCase();

    //  let theTrieValue = genTrieValueState(stateAbbrev);
    let theTrieValue = `${LegislatorTypes.STATE_SUM}-${stateAbbrev}---`;

    this.generalTrie.set(lowerState, theTrieValue);
    this.locTrie.set(lowerState, theTrieValue);
    this.generalTrie.set(stateFull, theTrieValue);
    this.locTrie.set(stateFull, theTrieValue);
  };

  setHouseVariations = (person: FedLeg) => {
    //let theTrieValue = genTrieValue(person, "LOCATION");

    let theTrieValue = person.identityInfo.hashID;
    let state = person.identityInfo.stateAbbrev;

    let stateLower = state.toLowerCase();
    let stateFull = stateAbbrevToFull[state].toLowerCase();

    let variations = [
      `${stateLower} ${person.identityInfo.district}`,
      `${stateLower},${person.identityInfo.district}`,
      `${stateLower}, ${person.identityInfo.district}`,
      `${stateLower} , ${person.identityInfo.district}`,
      `${stateLower}-${person.identityInfo.district}`,
      `${stateLower}_${person.identityInfo.district}`,
      `${stateFull}-${person.identityInfo.district}`,
      `${stateFull} ${person.identityInfo.district}`,
      `${stateFull},${person.identityInfo.district}`,
      `${stateFull}, ${person.identityInfo.district}`,
      `${stateFull} , ${person.identityInfo.district}`,
      `${stateFull}_${person.identityInfo.district}`,
    ];
    variations.forEach((variation) => {
      this.generalTrie.set(variation, theTrieValue);
      this.locTrie.set(variation, theTrieValue);
    });
  };

  setChamberVariations = (person: StateLeg) => {
    let chamber = person.identityInfo.chamber as string;
    let district = person.identityInfo.district as string;
    let state = person.identityInfo.stateAbbrev as string;

    // let theTrieValue = genTrieValue(person, "LOCATION");
    let theTrieValue = person.identityInfo.hashID;
    let stateLower = state.toLowerCase();
    let stateFull = stateAbbrevToFull[state].toLowerCase();
    let chamberUp = chamber.toUpperCase();
    let variations = [
      `${stateLower} ${chamber} ${district}`,
      `${stateLower}, ${chamber}, ${district}`,
      `${stateLower}, ${chamber}, ${district}`,
      `${stateLower} , ${chamber} , ${district}`,
      `${stateLower}-${chamber}-${district}`,
      `${stateLower}_${chamber}_${district}`,
      `${stateLower} ${chamberUp} ${district}`,
      `${stateLower}, ${chamberUp}, ${district}`,
      `${stateLower}, ${chamberUp}, ${district}`,
      `${stateLower} , ${chamberUp} , ${district}`,
      `${stateLower}-${chamberUp}-${district}`,
      `${stateLower}_${chamberUp}_${district}`,
      `${stateFull} ${chamber} ${district}`,
      `${stateFull}, ${chamber}, ${district}`,
      `${stateFull}, ${chamber}, ${district}`,
      `${stateFull} , ${chamber} , ${district}`,
      `${stateFull}-${chamber}-${district}`,
      `${stateFull}_${chamber}_${district}`,
      `${stateFull} ${chamberUp} ${district}`,
      `${stateFull}, ${chamberUp}, ${district}`,
      `${stateFull}, ${chamberUp}, ${district}`,
      `${stateFull} , ${chamberUp} , ${district}`,
      `${stateFull}-${chamberUp}-${district}`,
      `${stateFull}_${chamberUp}_${district}`,
    ];
    variations.forEach((variation) => {
      this.generalTrie.set(variation, theTrieValue);
      this.locTrie.set(variation, theTrieValue);
    });
  };

  constructor() {
    this.generalTrie = new TrieMap();
    this.peopleTrie = new TrieMap();
    this.locTrie = new TrieMap();
  }
}
