import React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../../Hooks/index";
import "../../../../../assets/Panel.scss";
import HoriChevron from "../../../../Icons/HoriChevron";
import { PANEL_TYPES } from "../../../../../Stores/PanelStore/types";

import CartIcon from "../../../../Icons/CartIcon2";
import { Link } from "react-router-dom";

import { BiSearchAlt2 } from "react-icons/bi";

interface PanelProps {
  children?: React.ReactNode;
  classMod?: string;
}

const Panel: React.FC<PanelProps> = ({ children, classMod = "" }) => {
  const panelStore = useStore("panelStore");
  const cartStore = useStore("newCartStore");

  const titleArr = panelStore.crumbStrings;

  const functionArr = panelStore.crumbFunctions;

  const count = cartStore.cartSize;
  return (
    <React.Fragment>
      <div className="gm-v2-panel-header d-flex  align-items-center pl-3">
        {/* <div className="gm-v2-panel-header-content"> */}
        {panelStore.isSearchView ? (
          <div
            className="gm-side-panel-header-icon "
            key="Search"
            //  onClick={() => changeActiveView(PANEL_TYPES.SEARCH)}
          >
            <div
              onClick={() => {
                panelStore.setActiveView(PANEL_TYPES.SEARCH);
                panelStore.setCrumb(["USA"], [null]);
              }}
            >
              <BiSearchAlt2 />
            </div>
          </div>
        ) : (
          <div
            className="gm-side-panel-header-icon "
            key="Search"
            //  onClick={() => changeActiveView(PANEL_TYPES.SEARCH)}
          >
            <div
              className="gm-panel-back-wrap"
              onClick={() => {
                panelStore.setPrevView();
              }}
            >
              <HoriChevron classMod="gm-panel-back-wrap" isOpened={false} />
            </div>
          </div>
        )}

        <nav className="gm-side-panel-nav flex-grow-1">
          {titleArr.map((title: string, index: number) => {
            let elem;
            let className = "";
            if (index < titleArr.length - 1) {
              elem = <span key={`${title}-/`}>{" / "}</span>;
              className = "gm-side-panel-nav-link";
            }
            return (
              <React.Fragment key={title}>
                <span
                  key={title}
                  className={className}
                  onClick={functionArr[index]}
                >
                  {title}
                </span>
                {elem}
              </React.Fragment>
            );
          })}
        </nav>
        {/* </div> */}

        <div className="gm-v2-panel-header-buttons">
          <div className="gm-atlas-icon">
            <img
              className="gm-esri-living-atlas"
              src="/images/icons/new-atlas-icon.svg"
              onClick={() => panelStore.renderLivingAtlas()}
            />
            <div className="gm-seperator"></div>

            <Link className="gm-panel-print-icon" to="/Cart">
              <span className="gm-panel-print-"> {count ? count : ""} </span>
              <CartIcon fill="#FFFFFF" count={count ? count : 0} />
            </Link>
          </div>
        </div>
      </div>

      <div
        className={`gm-side-panel-content ${classMod} d-flex flex-column flex-grow-1`}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default observer(Panel);
