import { LegislatorTypes } from "APTAPackage";
import { SlideProps } from "../components/Pages/Home/components/Carousel/Slide";

export const DEV_BASE_URL = "http://localhost:3000";
export const DEV_BATCH_PRINT_URL = "http://localhost:4000/staging";
export const STAGING_BASE_URL = "https://apta2.geomarvel.com";
export const PROD_BASE_URL = "https://footprint.apta.com";

export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const API_URLS = {
  POST_login_password: `${API_BASE_URL}/login`,
  POST_login_token: `${API_BASE_URL}/login/token`,
  jobRoute: `${API_BASE_URL}/jobs`,
  GET_esriToken: `${API_BASE_URL}/token`,
  unauthPrint: `${API_BASE_URL}/printUnauth`,
  getZip: `https://4wzais6ul47wq5xogdq2rbvivq0fafnn.lambda-url.us-east-1.on.aws`,
  getToken: `https://apta-footprint-auth.auth.us-east-1.amazoncognito.com/oauth2/token`,
  getJobStatus: `{${API_BASE_URL}/}`,
};

export const webmapId_2023 = "d470b8640f314f279786fee84dec8639";
// 2024
export const webmapId = "4a9fe7e498a945f38917775b9ea92b15";
export const dataExplorerWebmapId = "29a45902fb7c45918e137b31ad478959";

export const geoLayers: { [key: string]: string } = {
  [LegislatorTypes.STATE_SUM]: "ae289a4643b745e59af6e9d404bcca04",
  [LegislatorTypes.SENATE]: "ae289a4643b745e59af6e9d404bcca04",
  [LegislatorTypes.HOUSE]: "2e28ad8efe3d40a5894ecf4695726abe",
  [LegislatorTypes.SLDL]: "31c75cdd57364e27a8f117df4b47c7b5",
  [LegislatorTypes.SLDU]: "c12d61288a8a426c8161057c861d5ea3",
};

export const portalURLs_2023: { [key: string]: string } = {
  STATE_SUM_CORE:
    "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/State_Core_2023/FeatureServer",
  SENATE_CORE:
    "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/State_Core_2023/FeatureServer",
  HOUSE_CORE:
    "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/CD_Core_2023/FeatureServer",
  SLDL_CORE:
    "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_Core_2023/FeatureServer",
  SLDU_CORE:
    "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_Core_2023/FeatureServer",
};

// 2024 Change - We should pull the core url from conifg.

const APTA_FS_URL =
  "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services";

export const portalURLs: { [key: string]: string } = {
  STATE_SUM_CORE: `${APTA_FS_URL}/State_Core_2024/FeatureServer`,
  SENATE_CORE: `${APTA_FS_URL}/State_Core_2024/FeatureServer`,
  HOUSE_CORE: `${APTA_FS_URL}/CD_Core_2024/FeatureServer`,
  SLDL_CORE: `${APTA_FS_URL}/SLDL_Core_2024/FeatureServer`,
  SLDU_CORE: `${APTA_FS_URL}/SLDU_Core_2024/FeatureServer`,
};

export const grantURLs: { [key: string]: string } = {
  STATE: `${portalURLs.STATE_SUM_CORE}/557/query`,
  CD: `${portalURLs.HOUSE_CORE}/560/query`,
  SLDL: `${portalURLs.SLDL_CORE}/558/query`,
  SLDU: `${portalURLs.SLDU_CORE}/559/query`,
};

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Living Atlas Map Data !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const livingAtlasLayers = [
  {
    id: "2021_USA_Population_Density",
    lable: "2021 USA Population Density",
    title: "2021 USA Population Density",
    creditLable: "Esri Living Atlas",
    image: "/images/thumbnails/2019_USA_Population_Density.png",
    layerId: "a8407298de7e48078a2bc9cdd76c79af",
    active: false,
    visible: true,
  },
  {
    id: "2021_USA_Tapestry_Segmentation",
    lable: "2021 USA Tapestry Segmentation",
    title: "2021 USA Tapestry Segmentation",
    creditLable: "Esri Living Atlas",
    image: "/images/thumbnails/2019_USA_Tapestry_Segmentation.png",
    layerId: "4bbba5f3ef5b40649ff7fc15f7a481b1",
    active: false,
    visible: true,
  },
  {
    id: "2021_USA_Retail_Opportunity",
    lable: "2021 USA Retail Opportunity",
    title: "2021 USA Retail Opportunity (in 2019 Geography)",
    creditLable: "Esri Living Atlas",
    image:
      "/images/thumbnails/2017_USA_Retail_Opportunity__28in_2019_Geography_29.png",
    layerId: "d10507680443414f9df1f2515e7c4ac1",
    active: false,
    visible: true,
  },
  {
    id: "2021_USA_Median_Age",
    lable: "2021 USA Median Age",
    title: "2021 USA Median Age",
    creditLable: "Esri Living Atlas",
    image: "/images/thumbnails/2019_USA_Median_Age.png",
    layerId: "9f55e7006a614e43b30642a0f9fc3266",
    active: false,
    visible: true,
  },
  {
    id: "2021_USA_Median_Household_Income",
    lable: "2021 USA Median Household Income",
    title: "2021 USA Median Household Income",
    creditLable: "Esri Living Atlas",
    image: "/images/thumbnails/2019_USA_Median_Household_Income.png",
    layerId: "39001018f2924cb5a7d4cd65932a9484",
    active: false,
    visible: true,
  },
  {
    id: "2021_USA_Transportation_Fuel_Spending",
    lable: "2021 USA Transportation Fuel Spending",
    title: "2021 USA Transportation Fuel Spending",
    creditLable: "Esri Living Atlas",
    image: "/images/thumbnails/2019_USA_Transportation_Fuel_Spending.png",
    layerId: "71e58ba584464b83b2401b00a62b83d8",
    active: false,
    visible: true,
  },
];

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Home Page Carousel Config !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const carouselConfig: SlideProps[] = [
  {
    index: 1,
    header: "APTA Industry Footprint",
    mainText:
      "Public transportation is a $79 billion industry that employs more than 430,000 people and supports millions of private-sector jobs.",
    img: "images/carousel/1-min.jpg",
  },
  {
    index: 2,
    header: "2700+ Total Public Transit Systems Digitally Represented",
    mainText:
      "Approximately 6,800 organizations provided public transportation in 2020, so agency work is needed to share this data",
    img: "images/carousel/2-min.jpg",
  },
  {
    index: 3,
    header: "12,000+ Miles of Public Transit",
    mainText:
      "Servicing and improving this network employed 430,000+ people in 2020",
    img: "images/carousel/3-min.jpg",
  },
  {
    index: 4,
    header: "33+ Billion Public Transit Miles Travelled in 2020",
    mainText: "Riders took 6 billion trips on public transit in 2020",
    img: "images/carousel/4-min.jpg",
  },
  {
    index: 5,
    header: "$74+ Billion in Spending by Transit Agencies",
    mainText:
      "Includes $50 billion spent on operations and $24 billion spent on capital investments",
    img: "images/carousel/10-min.jpg",
  },
  {
    index: 6,
    header: "1500+ Government Points of Contact",
    mainText:
      "Connect with decision makers through email, social media, or phone",
    img: "images/carousel/6-min.jpg",
  },
];

// export const GA_TRACKING_ID = process.env.REACT_GA_MEASUREMENT_ID;
