import React, { useEffect, useMemo } from "react";
import Panel from "./BasePanel/Panel";
import { useStore } from "../../../../Hooks";
import { dataExplorerWebmapId } from "../../../../constants/configTS";
import useEsriModules from "../../../../Hooks/useEsriModules";
import { CheckItem } from "../../../Generic";
import { GroupLayer } from "../../../Generic/GroupLayer";
import { observer } from "mobx-react-lite";
// import { changeToPreviousView } from "../actions/actions";

type T_groupLayer = T_layer & T_groupArgs;

type T_layerTypes = "group" | "other";

type T_layer = {
  id: string;
  type: T_layerTypes;
  name: string;
  active: boolean;

  portalItemId?: string;
  parent?: string;
  children: T_layer[];
};

type T_groupArgs = {
  isChild: boolean;
  children: string[];
};

type T_mapLayer = {
  portalItem: { id: string };
};

type T_layerObj = { [key: string]: T_layer | T_groupLayer };

// function processGroupLayer(layer: any) {
//   const type = layer?.type;
//   const layers = layer?.layers;

//   if (type === "group") {
//     const groupLayer: T_groupLayer = {
//       id: layer.id,
//       name: layer.title,
//       active: false,

//       isChild: false,
//       children: [],
//     };
//     const childLayers: T_groupLayer[] = [];

//     if (layers != null && layers.length) {
//       const children: string[] = [];

//       layers.forEach((layer: any) => {
//         const childLayer: T_groupLayer = {
//           id: `${layer.id}-${layer.portalItem.id}`,
//           name: layer.title,
//           portalItemId: layer.portalItem.id,
//           active: false,

//           isChild: true,
//           children: [],
//         };

//         if (childLayer.portalItemId) {
//           children.push(childLayer.id);
//           childLayers.push(childLayer);
//         }
//       });
//       groupLayer.children = children;
//     }
//     return { groupLayer, children: childLayers };
//   }

//   return null;
// }

const ExploreLayers = () => {
  const { esriObj, esriToken } = useEsriModules();

  const panelStore = useStore("panelStore");
  const layers = panelStore.exploreLayers;
  const mapLayers = panelStore?.map?.layers;

  const toggleLayer = (layer: T_layer | T_groupLayer) => {
    const mapLayers = panelStore?.map?.layers;

    if (layers != null && mapLayers != null) {
      const newLayers = { ...layers };
      const newLayer = { ...layer };

      const currentLayer = layers[layer.id];
      if (currentLayer != null && currentLayer.active === true) {
        const newLayer = { ...layer };
        newLayer.active = false;
        newLayers[layer.id] = newLayer;

        if (layer.type !== "group") {
          panelStore.removeLayer(layer);
        } else {
          const newChildren = layer.children.map((childLayer) => {
            panelStore.removeLayer(childLayer);
            const newLayer = { ...childLayer };
            newLayer.active = false;
            return newLayer;
          });
          newLayer.children = newChildren;
        }
      } else if (currentLayer != null && currentLayer.active === false) {
        const newLayer = { ...layer };
        newLayer.active = true;
        newLayers[layer.id] = newLayer;
        panelStore.addLayer(layer);

        if (layer.type === "group") {
          layer.children.map((childLayer) => {
            const newLayer = { ...childLayer };
            newLayer.active = true;
            panelStore.addLayer(childLayer);
            return newLayer;
          });
        }
        // if (isGroup(layer)) {
        //   // panelStore.addLayer(layer);
        //   layer.children.forEach((elem) => {
        //     const layer = layers[elem];

        //     panelStore.addLayer(layer);
        //     const newLayer = { ...layer };
        //     newLayers[layer.id] = newLayer;
        //   });
        // } else {
        // panelStore.addLayer(layer);
        // newLayer.active = true;
        // if (layer.portalItemId) {
        //   newLayers[layer.portalItemId] = newLayer;
        // } else {
        //   newLayers[layer.id] = newLayer;
        // }
        // // }
      }

      // mapLayers.forEach((mapLayer: T_mapLayer) => {
      //   const portalItemId = mapLayer?.portalItem?.id;

      //   if (portalItemId && portalItemId === layer.portalItemId) {
      //     didRemove = true;
      //     newLayer.active = false;
      //     newLayers[layer.portalItemId] = newLayer;
      //     panelStore.removeLayer(mapLayer);
      //   }
      // });

      // if (didRemove === false) {

      //   }
      // }

      panelStore.setExploreLayers(newLayers);
    }
  };

  // Get Layers
  useEffect(() => {
    let webmap: any = null;

    if (
      esriObj != null &&
      esriToken != null &&
      panelStore.exploreLayers == null
    ) {
      const { WebMap, GroupLayer } = esriObj;

      webmap = new WebMap({
        portalItem: {
          id: dataExplorerWebmapId,
        },
      });
      webmap
        .load()
        .then((map: any) => {
          const layers = map.layers;
          if (layers && layers?.length) {
            const layerObj: T_layerObj = {};

            layers.forEach((layer: any) => {
              const parsedLayer: T_layer = {
                id: layer.id,
                name: layer.title,

                portalItemId: layer?.portalItem?.id,

                active: false,
                type: layer.type,
                children: [],
              };

              if (layer.type === "group") {
                layer.layers.forEach((layer: any) => {
                  const childParsedLayer: T_layer = {
                    id: layer.id,
                    name: layer.title,

                    portalItemId: layer?.portalItem?.id,

                    active: false,
                    type: layer.type,
                    parent: parsedLayer.id,
                    children: [],
                  };
                  parsedLayer.children.push(childParsedLayer);
                });
              }
              layerObj[parsedLayer.id] = { ...parsedLayer };
            });

            panelStore.setExploreLayers(layerObj);
          }
        })
        .catch((error: any) => console.error(" in error", error, esriToken));
    }

    return () => {
      if (webmap) {
        webmap.destroy();
      }
    };
  }, [esriObj, esriToken]);

  // useEffect(() => {
  //   const mapLayers = panelStore?.map?.layers;
  //   if (layers && mapLayers && mapLayers?.length) {
  //     let set = false;

  //     const newLayers = { ...layers };

  //     mapLayers.forEach((mapLayer: T_mapLayer) => {
  //       const portalItemId = mapLayer?.portalItem?.id;

  //       if (newLayers[portalItemId]) {
  //         const newLayer = { ...newLayers[portalItemId] };

  //         if (newLayer.active !== true) {
  //           newLayer.active = true;
  //           newLayers[portalItemId] = newLayer;
  //           set = true;
  //         }
  //       }
  //     });

  //     if (set) {
  //       setLayers(newLayers);
  //     }
  //   }
  // }, [layers]);

  // Convert Layer Obj to Arr;
  const layerArr = useMemo(() => {
    const layerArr: T_layer[] = [];

    if (panelStore.exploreLayers != null) {
      for (let key in layers) {
        layerArr.push(layers[key]);
      }
    }
    return layerArr;
  }, [panelStore.exploreLayers]);

  return (
    <Panel>
      <div id="viewDiv" style={{ visibility: "hidden" }} />

      <p> These Layers... </p>

      {/* <button
        className="btn"
        onClick={() => {
          layerArr.forEach((layer) => {
            if (layer.active) {
              toggleLayer(layer);
            }
          });
        }}
      >
        {" "}
        Clear All
      </button> */}

      <div>
        {/* //@ts-ignore */}
        {layerArr.map((layer) => {
          // if (layer.type === "group") {
          //   return (
          //     <GroupLayer
          //       key={layer.id}
          //       title={layer.name}
          //       onClick={() => {
          //         toggleLayer({ ...layer });
          //       }}
          //       checked={layer.active}
          //     >
          //       {/* {layer.children.map((elem) => {
          //         const childLayer = layers?.[elem];

          //         if (childLayer) {
          //           return (
          //             <CheckItem
          //               key={
          //                 childLayer.portalItemId
          //                   ? childLayer.portalItemId
          //                   : childLayer?.id
          //               }
          //               onClick={() => {
          //                 toggleLayer({ ...layer });
          //               }}
          //               title={childLayer.name}
          //               checked={childLayer.active}
          //             />
          //           );
          //         } */}
          //       {/* return null; */}
          //       {/* })} */}
          //     </GroupLayer>
          //   );
          // } else {
          return (
            <CheckItem
              key={layer.id}
              onClick={() => toggleLayer({ ...layer })}
              title={layer.name}
              checked={layer.active}
            />
          );
          // }
        })}
      </div>

      {/* <div id="searchInputWrap" className="flexy-item">
        <input
          id="app-search-input"
          type="text"
          placeholder="Search and Filter Datasets"
          className="input-search input-minimal"
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
          autoComplete="off"
        />

        <div id="search-clean-btn" className="js-clear-search hide">
          <span className="icon-ui-close"></span>
        </div>
      </div>

      <div
        className="gm-content-container leader-half"
        style={{ overflowY: "scroll", overflowX: "hidden" }}
      >
        <div className="block-group block-group-2-up tablet-block-group-2-up phone-block-group-1-up">
          {filteredLayerList.map((item, key) => {
            return item.visible ? (
              <div key={key} className="block mb-3">
                <div className="card block">
                  <figure className="card-image-wrap">
                    <img className="card-image" src={item.image} />
                    <div className="card-image-caption">{item.creditLable}</div>
                  </figure>
                  <div className="card-content">
                    <h6>
                      <a href="#">{item.lable}</a>
                    </h6>
                    <button
                      style={
                        !item.active
                          ? {
                              backgroundColor: "#0079c1",
                              color: "white",
                              borderRadius: 5,
                              boxShadow: "0px 0px 0px white !imporant",
                            }
                          : { backgroundColor: "white", color: "black" }
                      }
                      onClick={() => toggleLayer(item)}
                    >
                      {!item.active ? "Add to Map" : "Remove from Map"}
                    </button>
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </div> */}
      {/* </div> */}
    </Panel>
  );
};

export default observer(ExploreLayers);
