import {
  FedLeg,
  StateLeg,
  memberTypes,
  stateAbbrevToFull,
  LegislatorTypes,
} from "APTAPackage";

export function genParsedDataState(stateAbbrev: string, zoomFlag = true) {
  return {
    stateAbbrev,
    stateFull: stateAbbrevToFull[stateAbbrev],
    type: memberTypes.GEO_STATE,
    zoomFlag,
  };
}

// Gen Senate Parsed Data - just used to avoid converting old call.
export function genParsedDataSenate(person: FedLeg, zoomFlag = true) {
  return {
    type: memberTypes.STATE,
    person,
    stateAbbrev: person?.identityInfo?.stateAbbrev,
    stateFull: stateAbbrevToFull[person?.identityInfo?.stateAbbrev],
    zoomFlag,
  };
}

// Gen CD Parsed Data - just used to avoid converting old call.
export function CDDataFactoryPerson(person: FedLeg) {
  return {
    type: memberTypes.CD,
    stateAbbrev: person.identityInfo.stateAbbrev,
    stateFull: person.identityInfo.stateFull,
    CDPP: person.identityInfo.district,
    CD_ID: person.identityInfo.geoid,
  };
}

export function genParsedStateLeg(person: StateLeg) {
  const { type, district, stateAbbrev, builtID } = person.identityInfo;
  let convertedType =
    type === LegislatorTypes.SLDU ? memberTypes.SLDU : memberTypes.SLDL;
  let atLarge = district === "00" || district == "98" ? true : false;
  let stateProperty = type === LegislatorTypes.SLDU ? "upperObj" : "lowerObj";
  let chamber = type === LegislatorTypes.SLDU ? "upper" : "lower";
  //let stateData = store.parsedGovData.fed[stateAbbrev];

  //let districtMembers = stateData[stateProperty][district].list;
  return {
    type: convertedType as any,
    stateAbbrev,
    stateFull: stateAbbrevToFull[stateAbbrev],
    district,
    chamber,
    // districtMembers,
    builtID,
  };
}
