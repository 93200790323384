import { T_committee } from "../../Types/Legislators";

export function compareCommittee( a: T_committee , b: T_committee  ) {
    if ( a.committeeName < b.committeeName ){
      return -1;
    }
    if ( a.committeeName > b.committeeName ){
      return 1;
    }
    return 0;
  }