import React, { useState, useEffect } from "react";
import { Router, Route, Switch, useLocation } from "react-router-dom";

import history from "./history";
import Home from "./Pages/Home/Home";

import Map from "./Pages/Map/MapPage";
import Print2 from "../Features/StandardPrint";
import Cart from "../Features/Cart/Cart";
import DevPage from "./Pages/Dev";
import ReactGA from "react-ga4";
//@ts-ignore

import RedirectRoute from "./Pages/Profile/RedirectRoute";

import "../assets/MainApp.scss";
import { useStore } from "../Hooks";
import Settings from "./Pages/Profile/Settings";
import Navbar from "./partial/Navbar";
import LogIn from "./Pages/Profile/LogIn";
import ClosePage from "./Pages/Profile/ClosePage";
import PrintPostCard from "../Features/Postcard/PrintPostCard";
import { useGoogleAnalytics } from "../Hooks/useGoogleAnalytics";

function Rest() {
  const dataStore = useStore("dataStore");
  const panelStore = useStore("panelStore");

  const [initLoad, setInitLoad] = useState(false);

  const loc = useLocation();

  useGoogleAnalytics();

  useEffect(() => {
    dataStore.loadData();
    let isMap = history.location.pathname.slice(0, 5) === "/map/";
    const hashId = history.location.pathname.slice(5);
    const isStateSum = history.location.pathname.slice(5, 14) === "STATE_SUM";
    let data = dataStore.getPerson(hashId);

    if (data !== undefined) {
      panelStore.setActiveHashId(hashId);
      panelStore.renderPerson(data, undefined);
    }

    // initEsri().then(() => {});
    setInitLoad(true);
  }, []);

  return (
    <Route path="/">
      <Switch>
        <Route path="/dev/:hashId?">
          <DevPage />
        </Route>
        <Route path="dev/">
          <DevPage />
        </Route>

        <Route path="/map/:hashId?">
          <Navbar />
          <Map />
        </Route>
        {/* <Route path="/city/test-city">
              <StickyHeader />
              <iframe
                style={{ width: "100vw", height: "90vh" }}
                src="https://test-local-city-apta.hub.arcgis.com/"
              ></iframe>
            </Route> */}
        <Route path="/settings">
          <Navbar />
          <Settings />
        </Route>
        <Route path="/code/">
          <RedirectRoute />
        </Route>
        <Route path="/login">
          <Navbar />
          <LogIn />
        </Route>
        <Route path="/Cart">
          <Navbar />
          <Cart />
        </Route>

        <Route path="/close">
          <ClosePage />
        </Route>

        <Route path="/">
          <Navbar />
          <Home />
        </Route>
      </Switch>
    </Route>
  );
}

function App() {


  const dataStore = useStore("dataStore");
  const panelStore = useStore("panelStore");

  return (
    <div className="gm-pos-relative">
      {/* {initLoad ? ( */}
      <Router history={history}>
        <Switch>
          <Route path="/print">
            <Route exact path="/print/postcard/:hashId?">
              <PrintPostCard />
            </Route>

            <Route exact path="/print/full/:hashId?">
              <Print2 />
            </Route>
          </Route>

          <Rest />
        </Switch>
      </Router>
      {/* ) : null} */}
    </div>
  );
}

export default App;
