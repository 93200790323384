import React from "react";
import Chart from "chart.js";
import Doughnut from "../../../graphs/Doughnut";
import { config } from "../../../../constants/config";

class RidershipComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { transitTrips, transitMiles } = config;
    return (
      <div id="rider_section">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col">
                <h2 className="headerText">Ridership:</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <hr />
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-4">
          <div className="col-md-6 text-center gm-border-right">
            <div className="row">
              <div className="col">
                <h2 className="doughnut-title">Transit Ridership</h2>{" "}
              </div>
            </div>
            <div className="row">
              <Doughnut
                id="transitTrips"
                data={transitTrips.data}
                units="Trips"
                title="Transit Ridership"
                labels={transitTrips.labels}
                labelPercents={transitTrips.labelPercents}
                colors={transitTrips.colors}
              />
            </div>
            <div className="row">
              <div className="col">
                <p
                  style={{ paddingTop: "15px" }}
                  className="gm-homepage-font-size-small"
                >
                  <strong> Bus modes </strong> carried more riders in 2020 due
                  to the pandemic.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 text-center h-100 w-100">
            <div className="row">
              <div className="col">
                <h2 className="doughnut-title">Miles Traveled</h2>
              </div>
            </div>
            <div className="row">
              <Doughnut
                id="transitMiles"
                data={transitMiles.data}
                units="Miles"
                title="Rail Modes"
                labels={transitMiles.labels}
                labelPercents={transitMiles.labelPercents}
                colors={transitMiles.colors}
              />
            </div>
            <div className="row">
              <div className="col">
                <p
                  style={{ paddingTop: "15px", paddingLeft: "10px" }}
                  className="gm-homepage-font-size-small"
                >
                  <strong> Rail modes</strong>, with dedicated right of way and
                  faster vehicles, carry transit passengers for more miles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RidershipComponent;
