"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionStateStart = exports.LayerStateStart = exports.LayerItems = exports.SectionItems = void 0;
const types_1 = require("./types");
exports.SectionItems = {
    MemberSummary: {
        key: types_1.SectionTitles.MemberSummary,
        title: "Member Summary",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    FederalAppropriations: {
        key: types_1.SectionTitles.FederalAppropriations,
        title: "Federal Appropriations",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    RegionalFunding: {
        key: types_1.SectionTitles.RegionalFunding,
        title: "Regional Funding",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    MajorGrantAwards: {
        key: types_1.SectionTitles.MajorGrantAwards,
        title: "Major Grant Awards",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    TransportationFootprint: {
        key: types_1.SectionTitles.TransportationFootprint,
        title: "Transportation Footprint",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    UsefulLifeofPublicTransit: {
        key: types_1.SectionTitles.UsefulLifeofPublicTransit,
        title: "Useful Life of Public Transit",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    MajorPublicTransitSystems: {
        key: types_1.SectionTitles.MajorPublicTransitSystems,
        title: "Major Public Transit Systems",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    DataSummary: {
        key: types_1.SectionTitles.DataSummary,
        title: "Data Summary",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    APTAMembers: {
        key: types_1.SectionTitles.APTAMembers,
        title: "APTA Members",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    BusManufacturers: {
        key: types_1.SectionTitles.BusManufacturers,
        title: "Bus Manufacturers",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    RailManufacturers: {
        key: types_1.SectionTitles.RailManufacturers,
        title: "Rail Manufacturers",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    DBEMembers: {
        key: types_1.SectionTitles.DBEMembers,
        title: "DBE Members",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
};
exports.LayerItems = {
    APTAMembersDBE: {
        key: types_1.LayerTitles.APTAMembersDBE,
        title: "APTA Members DBE",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    APTAMembers: {
        key: types_1.LayerTitles.APTAMembers,
        title: "APTA Members",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    BusManufacturers: {
        key: types_1.LayerTitles.BusManu,
        title: "Bus Manufacturers",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    RailManufacturers: {
        key: types_1.LayerTitles.RailManu,
        title: "Rail Manufacturers",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    RuralProviders: {
        key: types_1.LayerTitles.RuralProviders,
        title: "Rural Public Transit Providers",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    BusStationAreas: {
        key: types_1.LayerTitles.BusStationAreas,
        title: "Bus Station Areas",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    AmtrakStations: {
        key: types_1.LayerTitles.AmtrakStations,
        title: "Amtrak Stations",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    RailStations: {
        key: types_1.LayerTitles.RailStations,
        title: "Rail Stations",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    AmtrakRoutes: {
        key: types_1.LayerTitles.AmtrakRoutes,
        title: "Amtrak Routes",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    RailLines: {
        key: types_1.LayerTitles.RailLines,
        title: "Rail Lines",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    TransitAgency: {
        key: types_1.LayerTitles.TransitAgency,
        title: "Transit Agency",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    TransitAgencyServiceArea: {
        key: types_1.LayerTitles.TransitAgencyServiceArea,
        title: "Transit Agency Service Area",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    GrantData: {
        key: types_1.LayerTitles.GrantData,
        title: "Grant Data",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    UrbanizedArea: {
        key: types_1.LayerTitles.UrbanizedArea,
        title: "Urbanized Area",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    States: {
        key: types_1.LayerTitles.States,
        title: "States",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    House: {
        key: types_1.LayerTitles.House,
        title: "House",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    SLDL: {
        key: types_1.LayerTitles.SLDL,
        title: "SLDL",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    SLDU: {
        key: types_1.LayerTitles.SLDU,
        title: "SLDU",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
};
exports.LayerStateStart = {
    [types_1.LayerTitles.APTAMembersDBE]: true,
    [types_1.LayerTitles.APTAMembers]: true,
    [types_1.LayerTitles.RuralProviders]: true,
    [types_1.LayerTitles.BusManu]: true,
    [types_1.LayerTitles.RailManu]: true,
    [types_1.LayerTitles.BusStationAreas]: true,
    [types_1.LayerTitles.AmtrakStations]: true,
    [types_1.LayerTitles.RailStations]: true,
    [types_1.LayerTitles.AmtrakRoutes]: true,
    [types_1.LayerTitles.RailLines]: true,
    [types_1.LayerTitles.TransitAgency]: true,
    [types_1.LayerTitles.TransitAgencyServiceArea]: true,
    [types_1.LayerTitles.GrantData]: true,
    [types_1.LayerTitles.UrbanizedArea]: true,
    [types_1.LayerTitles.States]: true,
    [types_1.LayerTitles.House]: true,
    [types_1.LayerTitles.SLDU]: true,
    [types_1.LayerTitles.SLDL]: true,
};
exports.SectionStateStart = {
    [types_1.SectionTitles.MemberSummary]: true,
    [types_1.SectionTitles.FederalAppropriations]: true,
    [types_1.SectionTitles.RegionalFunding]: true,
    [types_1.SectionTitles.MajorGrantAwards]: true,
    [types_1.SectionTitles.TransportationFootprint]: true,
    [types_1.SectionTitles.UsefulLifeofPublicTransit]: true,
    [types_1.SectionTitles.MajorPublicTransitSystems]: true,
    [types_1.SectionTitles.DataSummary]: true,
    [types_1.SectionTitles.APTAMembers]: true,
    [types_1.SectionTitles.BusManufacturers]: true,
    [types_1.SectionTitles.RailManufacturers]: true,
    [types_1.SectionTitles.DBEMembers]: true
};
