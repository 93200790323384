export enum PAGE_TYPES {
  REGISTER = "REGISTER",
  LOG_IN = "LOG_IN",
}

export enum REGISTER_TYPES {
  INIT_REGISTER = "INIT_REGISTER",
  LOADING_REGISTER = "LOADING_REGISTER",
  FAILED_REGISTER = "FAILED_REGISTER",
  SUCCESS_REGISTER = "SUCCESS_REGISTER",
}

export enum CONFIRM_TYPES {
  INIT_CONFIRM = "INIT_CONFIRM",
  LOADING_CONFIRM = "LOADING_CONFIRM",
  FAILED_CONFIRM = "FAILED_CONFIRM",
  SUCCESS_CONFIRM = "SUCCESS_CONFIRM",
}

export enum LOGIN_TYPES {
  INIT_LOGIN = "INIT_LOGIN",
  LOADING_LOGIN = "LOADING_LOGIN",
  FAILED_LOGIN = "FAILED_LOGIN",
  SUCCESS_LOGIN = "SUCCESS_LOGIN",
}

export enum AUTH_TYPES {
  INIT_AUTH = "INIT_AUTH",
  LOADING_AUTH = "LOADING_AUTH",
  FAILED_AUTH = "FAILED_AUTH",
  SUCCESS_AUTH = "SUCCESS_AUTH",
  SUCCESS_AUTH_NO_ACCOUNT = "SUCCESS_AUTH_NO_ACCOUNT",
}
