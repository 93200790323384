import React from "react";
import { useStore } from "../../../../../../Hooks";
import { APTAMember } from "APTAPackage";
import APTAListElem from "../../ListElems/APTAMember";

const APTAList = () => {
  const searchStore = useStore("searchStore");

  const hashObj: any = {};



    let renderData: any = searchStore.searchResults.map((elem: any) => {

    let theMember = new APTAMember(
      { geometry: elem.geometry, ...elem?.attributes },
      true
    );
    const OBJECTID: string = theMember?.identityInfo?.OBJECTID as string;
    if (hashObj?.[OBJECTID] === undefined) {
      hashObj[OBJECTID] = true;
        return <APTAListElem key={theMember.identityInfo.OBJECTID} APTAMember={theMember} />;
    }
  });

  if (!renderData?.length) renderData = <p> No Results Found</p>;
  return <section>{renderData}</section>;
};

export default APTAList;
