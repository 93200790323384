"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Committee = void 0;
const constants_1 = require("../../../constants");
class Committee {
    constructor(comm, theLegMap) {
        this.id = comm.id;
        this.congress = comm.congress;
        this.chamber =
            comm.chamber === "Senate"
                ? constants_1.LegislatorTypes.SENATE
                : constants_1.LegislatorTypes.HOUSE;
        this.committeeName = comm.name;
        this.committeeURL = comm.url;
        this.chairHashId = theLegMap.getPerson_API(comm.chair_state, this.chamber, comm.chair_id);
        this.currentMembers = comm.current_members.map((member) => {
            return theLegMap.getPerson_API(member.state, this.chamber, member.id);
        });
        if (comm.subcommittees) {
            this.subcommittees = comm.subcommittees.map((subComm) => new Committee(subComm, theLegMap));
        }
    }
}
exports.Committee = Committee;
