"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BusMember_1 = require("./BusMember");
class BusMembers {
    constructor(data) {
        const theMembers = [];
        const hashObj = {};
        let sectionArr;
        let sections = {};
        data.forEach((member) => {
            const theMember = member?.attributes;
            let parsedMember = new BusMember_1.BusMember(theMember, false);
            if (!hashObj[parsedMember.orgName]) {
                hashObj[parsedMember.orgName] = true;
                if (!sections[parsedMember.sectionTitle]) {
                    sections[parsedMember.sectionTitle] = [parsedMember];
                }
                else {
                    sections[parsedMember.sectionTitle].push(parsedMember);
                }
                theMembers.push(parsedMember);
            }
        });
        this.count = theMembers.length;
        const sectionKeys = Object.keys(sections);
        sectionKeys.forEach((key) => {
            sections[key] = sections[key].sort((a, b) => {
                if (a.orgName < b.orgName)
                    return -1;
                else if (a.orgName > b.orgName)
                    return 1;
                return 0;
            });
            sectionArr = sectionKeys.map((key) => {
                return { title: key, data: sections[key] };
            });
            sectionArr.sort((a, b) => {
                if (a.title < b.title)
                    return -1;
                else if (a.title > b.title)
                    return 1;
                return 0;
            });
        });
        this.busMembers = theMembers;
        this.alphaArr = sectionArr;
    }
}
exports.default = BusMembers;
