import React from "react";
import MainHeader from "./MainHeader";

function Navbar() {
  return (
    <div className="gm-main-header">
      <div className="container ">
        <div className="row">
          <div className="col px-3">
            <MainHeader />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
