"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sectionObj = void 0;
exports.sectionObj = {
    MemberSummary: {
        key: "MemberSummary",
        name: "Member Summary",
        visible: false,
    },
    FederalAppropriations: {
        key: "FederalAppropriations",
        name: "Federal Appropriations",
        visible: false,
    },
    RegionalFunding: {
        key: "RegionalFunding",
        name: "Regional Funding",
        visible: false,
    },
    MajorGrantAwards: {
        key: "MajorGrantAwards",
        name: "Major Grant Awards",
        visible: false,
    },
    GrantDetails: {
        key: "GrantDetails",
        name: "Grant Details",
        visible: false,
    },
    TransportationFootprint: {
        key: "TransportationFootprint",
        name: "Transportation Footprint",
        visible: false,
    },
    UsefulLifeOfPublicTransit: {
        key: "UsefulLifeOfPublicTransit",
        name: "Useful Life of Public Transit",
        visible: false,
    },
    MajorPublicTransitSystems: {
        key: "MajorPublicTransitSystems",
        name: "Major Public Transit Systems",
        visible: false,
    },
    DataSummary: { key: "DataSummary", name: "Data Summary", visible: false },
    APTAMembers: { key: "APTAMembers", name: "APTA Members", visible: false },
    BusManufacturers: {
        key: "BusManufacturers",
        name: "Bus Manufacturers",
        visible: false,
    },
    RailManufacturers: {
        key: "RailManufacturers",
        name: "Rail Manufacturers",
        visible: false,
    },
    DBEMembers: { key: "DBEMembers", name: "DBE Members", visible: false },
};
