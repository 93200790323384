import React, { useMemo } from "react";
import { CoreData } from "APTAPackage";

import { SectionTitle } from "../../Components/SectionTitle";
import Doughnut from "../../../Postcard/SectionComponents/Graphs/Doughnut";
import { valuesArr, labelsArr, colors } from "./config";
import { T_dataComponent } from "../../../types";
import NoData from "../../Components/NoData";
import LoadingSpinner from "../../../../components/Icons/LoadingSpinner";

interface IProps {
  data: CoreData;
  dataLoading?: boolean;
  type: T_dataComponent;
}

const RegionalFunding: React.FC<IProps> = ({ data, dataLoading, type }) => {
  // Parse Data
  const { sortedArray } = useMemo(() => {
    let values: number[] = [];
    let parsedValues: number[] = [];
    let legendValues: number[] = [];
    let parsedLabels: string[] = [];
    let sum = 0;

    valuesArr?.forEach((valueKey, index: number) => {
      let theValue = data?.[valueKey];
      if (theValue === null) theValue = 0;
      if (
        (theValue !== undefined || theValue === 0) &&
        typeof theValue === "number"
      ) {
        values.push(theValue);
        sum += theValue;
        parsedLabels.push(labelsArr[index]);
      }
    });

    if (sum === 0) {
      let nullArr: any[] = [];
      return { sortedArray: [] };
    }

    legendValues = values.map((rawValue: number, index: number) => {
      let theValue = ((rawValue / sum) * 100).toFixed(0);
      return Number(theValue);
      // return Number(rawValue)
    });

    parsedValues = values.map((rawValue: number, index: number) => {
      let theValue = rawValue.toFixed(0);
      // let theValue = ((rawValue / sum) * 100).toFixed(0);
      // return Number(theValue);
      return Number(theValue);
    });

    let relationArray: any = [];

    for (let i = 0; i < 4; i++) {
      relationArray.push([parsedValues[i], labelsArr[i], legendValues[i]]);
    }

    //@ts-ignore
    const sortedArray = relationArray.sort((a, b) => a[0] - b[0]).reverse();


    return { sortedArray };
  }, [data]);

  // ~~~~~ Derived Data ~~~~~
  const isNoData = data == null || (sortedArray && sortedArray.length === 0);

  let sortedValues: any = [];
  let sortedLabels: any = [];

  sortedArray.forEach((item: [][]) => {
    sortedValues.push(item[0]);
    sortedLabels.push(item[1]);
  });



  return (
    <SectionTitle title="Regional Funding" type={type}>
      {dataLoading ? (
        <LoadingSpinner color="var(--apta-navbar-text-color)" />
      ) : isNoData ? (
        <NoData />
      ) : (
        <div className={`row ${type !== "POSTCARD" ? "mt-1 mt-md-5" : ""}`}>
          <div className="col  mx-5 mx-md-0">
            <div className="row">
              <div className="col">
                <Doughnut
                  data={sortedValues}
                  labels={sortedLabels}
                  colors={colors}
                  chartElementId="RegionalFunding"
                  tooltip={{
                    callbacks: {
                      label: function (context: any) {
                        return ` $${context.formattedValue}`;
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4 my-auto">
            <div className="row">
              {sortedArray.map((data: any, index: number) => {
                return (
                  <div
                    className="col-6 col-md-12 text-center pb-2"
                    key={`${index}`}
                  >
                    <div className="row">
                      <div className="col pr-0">
                        <div
                          className="mr-3 text-center float-right"
                          style={{
                            width: "2rem",
                            height: "2rem",
                            backgroundColor: colors[index],
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <div className="col pl-0">
                        <h1 className="gm-explore-numeric-3 float-left">
                          {data[2]}%
                        </h1>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p
                          className="gm-explore-text-3"
                          style={
                            type === "FULL_PAGE"
                              ? { fontSize: "1rem" }
                              : type === "WEBSITE"
                              ? { fontSize: "1.25rem" }
                              : { fontSize: "1.75rem" }
                          }
                        >
                          {data[1]}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </SectionTitle>
  );
};

export default RegionalFunding;
