import React from "react";
import { T_printType } from "../../../Stores/newCartStore.ts/newCartStore";

type T_collapseProps = {
  startOpen: boolean;
  count: string;
};

type T_sectionTitle = {
  title?: string;
  subTitle?: string;
  collapseProps?: T_collapseProps;
  type?: T_printType;
};

type T_props = T_sectionTitle & { children: React.ReactNode };

export const SectionTitle: React.FC<T_props> = ({
  title,
  subTitle,
  collapseProps,

  children,
  type = "PAGE",
}) => {
  return (
    <>
      <div className="pb-3 mb-3 gm-section-header-postcard d-flex">
        {title !== undefined ? (
          <h1 className=" m-0 w-100 gm-explore-header-postcard "> {title} </h1>
        ) : null}

        {collapseProps?.count && (
          <>
            {" "}
            <h1 className=" ml-auto gm-explore-numeric-postcard  mr-3">
              {" "}
              {collapseProps?.count}
            </h1>
          </>
        )}
      </div>

      {subTitle && (
        <h2 className="gm-explore-subtext-postcard  mb-3"> {subTitle} </h2>
      )}

      {children}
      {/* </div> */}
    </>
  );
};
