import React, { useState } from "react";
import { observer } from "mobx-react";
import { LegislatorTypes } from "APTAPackage";
import { useStore } from "../../../../Hooks";
import ToggleChevron from "../../../Icons/ToggleChevron";

const LayerDropdown = () => {
  const [active, setActive] = useState(false);
  const panelStore = useStore("panelStore");

  return (
    <div
      className={
        panelStore.activePanel
          ? "gm-dropdown-layer-on"
          : "gm-dropdown-layer-off"
      }
    >
      <div
        className={active ? "dropdown is-active" : "dropdown"}
        // style={{ width: "9rem" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "white",
            color: "black",

            // justifyContent: "space-between",
          }}
          className="dropdown-link"
          onClick={() => setActive(!active)}
          aria-haspopup="true"
          aria-expanded="false"
        >
          Boundary Selection <ToggleChevron isOpened={active} />
        </div>
        <nav
          className="dropdown-menu modifier-class"
          role="menu"
          style={{ left: panelStore.activePanel ? "-23px" : "-18px" }}
        >
          <a
            onClick={() => panelStore.setTopLayer(LegislatorTypes.STATE_SUM)}
            className="dropdown-link"
            role="menuitem"
          >
            {active}
            <div
              style={
                panelStore.topLayer === LegislatorTypes.STATE_SUM ||
                panelStore.topLayer === LegislatorTypes.SENATE
                  ? { color: "blue" }
                  : {}
              }
            >
              States
            </div>
          </a>
          <a
            onClick={() => panelStore.setTopLayer(LegislatorTypes.HOUSE)}
            className="dropdown-link gm-flex-row"
            role="menuitem"
          >
            <div
              style={
                panelStore.topLayer === LegislatorTypes.HOUSE
                  ? { color: "blue" }
                  : {}
              }
            >
              Congressional District
            </div>
          </a>
          <a
            onClick={() => {
              panelStore.setTopLayer(LegislatorTypes.SLDU);
            }}
            className="dropdown-link"
            role="menuitem"
          >
            <div
              style={
                panelStore.topLayer === LegislatorTypes.SLDU
                  ? { color: "blue" }
                  : {}
              }
            >
              State Legislature (Upper)
            </div>
          </a>
          <a
            onClick={() => panelStore.setTopLayer(LegislatorTypes.SLDL)}
            className="dropdown-link"
            role="menuitem"
          >
            <div
              style={
                panelStore.topLayer === LegislatorTypes.SLDL
                  ? { color: "blue" }
                  : {}
              }
            >
              State Legislature (Lower)
            </div>
          </a>
        </nav>
      </div>
    </div>
  );
};

export default observer(LayerDropdown);
