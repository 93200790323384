import React from "react";
import FederalAppropriations from "../../Explore/Sections/FederalAppropriations";
import MajorGrants from "../../Explore/Sections/MajorGrants";
import MemberSummary from "../../Explore/Sections/MemberSummary/MemberSummary";
import Header from "../SectionComponents/Header";
import TransFootprint from "../SectionComponents/TransFootprint";

interface MemberData {
  memberData: any;
  isPreview: boolean;
}

const PostCardFront: React.FC<MemberData> = ({ memberData, isPreview }) => {
  if (memberData == null) return null;

  return (
    <>
      {isPreview && (
        <div className="row d-print-none">
          <div className="col text-center">
            <h1>Front</h1>
          </div>
        </div>
      )}
      <div
        className="container-fluid"
        style={
          isPreview
            ? {
                boxShadow: "0px 0px 8px black",
                borderRadius: "15px",
                padding: "30px",
                height: "1200px",
                width: "1800px",
              }
            : {}
        }
      >
        <div className="row d-flex pb-3">
          <div className="col">
            <Header legislator={memberData.theMember} />
          </div>
        </div>
        {/* <DataSectionViewer dataJSON={postCardJSON} /> */}
        <div className="row d-flex">
          <div
            className="col d-flex flex-column pl-5 pr-0 m-0"
            style={{ height: "520px" }}
          >
            <MemberSummary data={memberData?.AGOLData} type="POSTCARD" />
          </div>

          <div className="col d-flex flex-column pl-5">
            <TransFootprint
              data={memberData?.AGOLData?.transitStatsData}
              dataLoading={false}
            />
          </div>

          <div
            className="col d-flex flex-column pl-5 pr-5"
            style={{ height: "520px" }}
          >
            <FederalAppropriations
              data={memberData?.AGOLData}
              dataLoading={false}
              type="POSTCARD"
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-sm-12 px-5 d-flex flex-column justify-content-center"
            style={{ height: "520px" }}
          >
            <MajorGrants
              type={"POSTCARD"}
              data={memberData.AGOLData?.grantsData}
              dataLoading={memberData.featureDataLoading}
              coreData={memberData?.AGOLData?.coreData}
              member={memberData?.theMember}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCardFront;
