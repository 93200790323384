import React from "react";
import { typeToClass, T_dataComponent } from "../../../types";
import { SubItem, T_subItemProps } from "../../Components/SubItem";

type T_props = {
  title: string;
  count?: number;
  type: T_dataComponent;
} & T_subItemProps;

export const MemberSummaryItem: React.FC<T_props> = ({
  title,
  src,
  count,
  size,
  type,
}) => {
  let printCount: any = count;
  if (count === undefined) printCount = "No Data";

  const classMod = typeToClass(type);

  return (
    <SubItem src={src} size={size}>
      <div className="text-left pl-3">
        <h1 className={`gm-explore-numeric${classMod} pb-1`}>{printCount}</h1>
        <p className={`gm-explore-text${classMod}`}>{title}</p>
      </div>
    </SubItem>
  );
};
