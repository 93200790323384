import React from "react";
// import { useStore } from "../../../../../Hooks";
// import { stateAbbrevToFull } from "APTAPackage";

interface PanelListElem {
  onClick?: any;
  img?: React.ReactNode;
  classMod?: string;
  children?: React.ReactNode;
}

const PanelListElem: React.FC<PanelListElem> = ({
  onClick,
  img,
  children,
  classMod = "",
}) => {
  return (
    <li className={`gm-panel-list-elem ${classMod}`} onClick={onClick}>
      {img}
      <section className="gm-panel-list-elem-children">{children}</section>
    </li>
  );
};

export default PanelListElem;
