import React from "react";
import { MemberSummaryItem } from "./MemberSummaryItem";
import { AGOLResponse } from "APTAPackage";
import { SectionTitle } from "../../Components/SectionTitle";
import { T_dataComponent } from "../../../types";
import LoadingSpinner from "../../../../components/Icons/LoadingSpinner";
import { HomeHeader } from "../../../../components/Pages/Home/components/HomeHeader";

interface IMemberSummary {
  data: AGOLResponse;
  dataLoading?: boolean;
  type: T_dataComponent;
  title?: React.FC;
}

const MemberSummary: React.FC<IMemberSummary> = ({
  data,
  type,
  dataLoading,
  title,
}) => {
  const aptaCount = data?.aptaMembers?.APTAMembers?.length;
  const busCount = data?.busMembers?.busMembers?.length;
  const railCount = data?.railMembers?.railMembers?.length;



  if (type === "HOME") {
    return (
      <>
        {" "}
        <HomeHeader title="Member Summary" id="member_summary" />
        <div>
          {dataLoading ? (
            <LoadingSpinner color="var(--apta-navbar-text-color)" />
          ) : (
            <>
              <MemberSummaryItem
                title="APTA Members"
                src="/images/icons/personBlueIcon.png"
                count={aptaCount}
                size={"6rem"}
                type={type}
              />
              <MemberSummaryItem
                title="Bus Manufacturers"
                src="/images/icons/busBlueIcon.png"
                count={busCount}
                size={"6rem"}
                type={type}
              />
              <MemberSummaryItem
                title="Rail Manufacturers"
                src="/images/icons/railBlueIcon.png"
                count={railCount}
                size={"6rem"}
                type={type}
              />
            </>
          )}
        </div>
      </>
    );
  }

  return (
    <SectionTitle title="Member Summary" type={type}>
      {dataLoading ? (
        <LoadingSpinner color="var(--apta-navbar-text-color)" />
      ) : (
        <>
          <MemberSummaryItem
            title="APTA Members"
            src="/images/icons/personBlueIcon.png"
            count={aptaCount}
            size={"6rem"}
            type={type}
          />
          <MemberSummaryItem
            title="Bus Manufacturers"
            src="/images/icons/busBlueIcon.png"
            count={busCount}
            size={"6rem"}
            type={type}
          />
          <MemberSummaryItem
            title="Rail Manufacturers"
            src="/images/icons/railBlueIcon.png"
            count={railCount}
            size={"6rem"}
            type={type}
          />
        </>
      )}
    </SectionTitle>
  );
};

export default MemberSummary;
