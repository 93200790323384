import React from "react";
import Slide from "./Slide";
import { carouselConfig } from "../../../../../constants/configTS";

const Carousel = () => {
  return (
    <div className="container-fluid gm-slide-container p-0">
      <div className="row h-100">
        <div className="col-lg h-100">
          <div
            style={{ overflow: "hidden" }}
            data-interval="false"
            id="carouselIndicators"
            className="carousel slide h-100"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselIndicators"
                data-slide-to="0"
                className="active mx-2"
              ></li>
              {[1, 2, 3, 4, 5].map((item, index) => (
                <li
                  key={index}
                  data-target="#carouselIndicators"
                  data-slide-to={item}
                  className="mx-2"
                ></li>
              ))}
            </ol>
            <div className="carousel- h-100">
              {carouselConfig.map((slide, index) => (
                <Slide key={slide.header} {...slide} index={index} />
              ))}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselIndicators"
              role="button"
              data-slide="prev"
              style={{ width: "50%", zIndex: 100 }}
            ></a>
            <a
              className="carousel-control-next"
              href="#carouselIndicators"
              role="button"
              data-slide="next"
              style={{ width: "50%", zIndex: 100 }}
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
