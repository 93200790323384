import React, { useMemo } from "react";
import { T_transitStats } from "APTAPackage";


import { convertNumber } from "../../../components/Pages/Print/helpers";
import { SectionTitle } from "../Components/SectionTitle";
import BarGraph from "../../Postcard/SectionComponents/Graphs/BarGraph";
import { T_dataComponent } from "../../types";
import NoData from "../Components/NoData";
import LoadingSpinner from "../../../components/Icons/LoadingSpinner";

interface IProps {
  data: T_transitStats;
  dataLoading?: boolean;
  type: T_dataComponent;
}

const callback = (value: any, index: any, values: any) => {
  return convertNumber(value);
};

const UsefulLife: React.FC<IProps> = ({ data, dataLoading, type }) => {
  const { theData, labels } = useMemo(() => {
    let labelNames = ["Bus", "Rail", "Ferry"];

    let totalData = [
      data?.CALC_SUM_Total_Active_Fleet_bus as number,
      data?.CALC_SUM_Total_Active_Fleet_rail as number,
      data?.CALC_SUM_Total_Active_Fleet_ferry as number,
    ];

    let theData = [
      data?.CALC_SUM_Beyond_Useful_Life_bus as number,
      data?.CALC_SUM_Beyond_Useful_Life_rail as number,
      data?.CALC_SUM_Beyond_Useful_Life_ferry as number,
    ];

    const percents = theData?.map((theData, index) => {
      let theTotal = totalData?.[index];

      if (!theTotal) return "N/A";
      let theValue = ((theData / theTotal) * 100).toFixed(0);
      return `${theValue}%`;
    });

    let labels = labelNames.map((label, index) => {
      return [label, percents[index]];
    });

    return { theData, labels };
  }, [data]);

  // ~~~~~ Derived Data ~~~~~
  const isNoData =
    theData?.[0] == null && theData?.[1] == null && theData?.[2] == null;

  return (
    <SectionTitle
      title="Useful Life of Public Transit"
      subTitle={
        isNoData
          ? ""
          : "Total number of assets which exceed their useful life in 5 years"
      }
      type={type}
    >
      {dataLoading ? (
        <LoadingSpinner color="var(--apta-navbar-text-color)" />
      ) : isNoData ? (
        <NoData />
      ) : (
        <div className="my-auto" style={{ maxWidth: "100%" }}>
          <BarGraph
            showDataLabels={false}
            data={theData}
            labels={labels}
            colors={["#36a2eb", "#0661a0", "#0d4064", "#000000"]}
            yAxesCallback={callback}
            chartElementId="UsefulLife"
          />
        </div>
      )}
    </SectionTitle>
  );
};

export default UsefulLife;
