"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("./index");
const constants_1 = require("../constants");
class EsriIdentityInfo {
    constructor(type, OBJECTID, stateAbbrev, CD, globalCD, sldu, sldl) {
        this.type = type;
        this.OBJECTID = OBJECTID;
        this.stateAbbrev = stateAbbrev;
        this.stateFull = constants_1.stateAbbrevToFull[stateAbbrev];
        this.CD = CD;
        this.globalCD = globalCD;
        this.sldu = sldu;
        this.sldl = sldl;
    }
    // gen from list of args
    // Made to generalize the constructor for different interfaces
    static genFromList(type, theData, theProps) {
        let theConstArgs = [type];
        theProps.forEach((prop) => {
            if (prop === null)
                theConstArgs.push(null);
            else
                theConstArgs.push(index_1.parseEsriElem(theData?.[prop]));
        });
        return new EsriIdentityInfo(...theConstArgs);
    }
}
exports.default = EsriIdentityInfo;
