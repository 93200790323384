import React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../Hooks";

import MemberSummary from "./Sections/MemberSummary/MemberSummary";
import MajorGrants from "./Sections/MajorGrants";
import RegionalFunding from "./Sections/RegionalFunding";
import UsefulLife from "./Sections/UsefulLife";
import TransportationFootprint from "./Sections/TransportationFootprint";
import MajorPublicTransit from "./Sections/MajorPublicTransit";
import DataSummary from "./Sections/DataSummary/DataSummary2";
import MemberList from "./Sections/MemberList";
import DataSectionViewer from "./DataSectionViewer";
import FederalAppropriations from "./Sections/FederalAppropriations";
import { GrantDetails } from "./Sections/GrantDetails/GrantDetails";

const Explore: React.FC = () => {
  const panelStore = useStore("panelStore");
  const activeLegend = panelStore?.legendExpanded;

  const mobileView = window.screen.width <= 768;



  const dataSectionJSON = {
    rows: [
      {
        classes: "pb-1",
        columns: [
          {
            // columnSize: 6,
            classes: "col-sm-12 col-md-6",
            innerRows: [
              {
                classes: " d-flex flex-column pb-3",
                innerColumns: [
                  {
                    component: (
                      <MemberSummary
                        type={"WEBSITE"}
                        data={panelStore.featureData}
                        dataLoading={panelStore.featureDataLoading}
                      />
                    ),

                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
          {
            // columnSize: 6,
            classes: "col-sm-12 col-md-6",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <FederalAppropriations
                        type={"WEBSITE"}
                        data={panelStore.featureData}
                        dataLoading={panelStore.featureDataLoading}
                      />
                    ),

                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-1 pt-5 pt-md-4",
        columns: [
          {
            // columnSize: 6,
            classes: "col-sm-12 col-md-6",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <RegionalFunding
                        type={"WEBSITE"}
                        data={panelStore?.featureData?.coreData}
                        dataLoading={panelStore.featureDataLoading}
                      />
                    ),

                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
          {
            // columnSize: 6,
            classes: "col-sm-12 col-md-6",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <TransportationFootprint
                        type={"WEBSITE"}
                        data={panelStore?.featureData?.transitStatsData}
                        dataLoading={panelStore.featureDataLoading}
                      />
                    ),

                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        classes: "pb-1",
        columns: [
          {
            // columnSize: 6,
            classes: "col-sm-12 col-md-6",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <UsefulLife
                        type={"WEBSITE"}
                        data={panelStore?.featureData?.transitStatsData}
                        dataLoading={panelStore.featureDataLoading}
                      />
                    ),
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
          {
            classes: "col-sm-12 col-md-6",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <MajorPublicTransit
                        type={"WEBSITE"}
                        data={panelStore.featureData?.transitSystemData}
                        dataLoading={panelStore.featureDataLoading}
                      />
                    ),

                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        classes: "pb-1",
        columns: [
          {
            columnSize: 12,
            classes: "",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <MajorGrants
                        type={"WEBSITE"}
                        data={panelStore?.featureData?.grantsData}
                        dataLoading={panelStore.featureDataLoading}
                      />
                    ),

                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-1",
        columns: [
          {
            // columnSize: 12,
            classes: "col-12",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <>
                        <GrantDetails type={"WEBSITE"} />
                      </>
                    ),
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        classes: "pb-1",
        columns: [
          {
            // columnSize: 12,
            classes: "col-12",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <DataSummary
                        type={"WEBSITE"}
                        coreData={panelStore.featureData?.coreData}
                        countyData={panelStore.featureData?.countyData}
                        dataLoading={panelStore.featureDataLoading}
                        dataView="both"
                      />
                    ),
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        classes: "pb-1 d-clock text-left",
        columns: [
          {
            columnSize: 12,
            classes: "",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <MemberList
                        type={"WEBSITE"}
                        title="APTA Members"
                        members={panelStore.featureData?.aptaMembers}
                        dataLoading={panelStore.featureDataLoading}
                        isTop={true}
                      />
                    ),
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-1 text-left",
        columns: [
          {
            columnSize: 12,
            classes: "",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <MemberList
                        type={"WEBSITE"}
                        title="Bus Manufacturers"
                        members={panelStore.featureData?.busMembers}
                        dataLoading={panelStore.featureDataLoading}
                      />
                    ),
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-1 d-print-block text-left",
        columns: [
          {
            columnSize: 12,
            classes: "",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <MemberList
                        type={"WEBSITE"}
                        title="Rail Manufacturers"
                        members={panelStore.featureData?.railMembers}
                        dataLoading={panelStore.featureDataLoading}
                      />
                    ),
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        classes: "pb-1 d-print-block text-left",
        columns: [
          {
            columnSize: 12,
            classes: "",
            innerRows: [
              {
                classes: "",
                innerColumns: [
                  {
                    component: (
                      <MemberList
                        type={"WEBSITE"}
                        title="DBE Members"
                        members={panelStore.featureData?.DBEMembers}
                        dataLoading={panelStore.featureDataLoading}
                        isTop={true}
                      />
                    ),
                    columnSize: 12,
                    classes: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  return panelStore?.activeState?.length > 1 ? (
    <div
      className="container text-sm-left text-center mt-3 gm-print"
      style={{
        position: "relative",

        zIndex: mobileView && activeLegend ? -1 : 1,
      }}
    >
      <DataSectionViewer dataJSON={dataSectionJSON} />
    </div>
  ) : null;
  // return panelStore?.activeState?.length > 1 ? (
  //   <div className="container mt-3">
  //     <div className="row">
  //       <div className="col-md-6 col-sm-12 d-flex flex-column pb-3">
  //         {" "}
  //         <MemberSummary
  //           type={"WEBSITE"}
  //           data={panelStore.featureData}
  //           dataLoading={panelStore.featureDataLoading}
  //         />
  //       </div>
  //       <div className="col-md-6 col-sm-12 d-flex flex-column pb-3">
  //         <FederalAppropriations
  //           type={"WEBSITE"}
  //           data={panelStore.featureData}
  //           dataLoading={panelStore.featureDataLoading}
  //         />
  //       </div>
  //     </div>

  //     <div className="row">
  //       <div className="col-sm-12 col-md-12 d-flex d-flex flex-column pb-3">
  //         <MajorGrants
  //           type={"WEBSITE"}
  //           data={panelStore?.featureData?.grantsData}
  //           dataLoading={panelStore.featureDataLoading}
  //         />
  //       </div>
  //     </div>

  //     <div className="row">
  //       <div className="col-sm-6 d-flex flex-column pb-3">
  //         <RegionalFunding
  //           type={"WEBSITE"}
  //           data={panelStore?.featureData?.coreData}
  //           dataLoading={panelStore.featureDataLoading}
  //         />
  //       </div>

  //       <div className="col-sm-6 d-flex  flex-column pb-3">
  //         <TransportationFootprint
  //           type={"WEBSITE"}
  //           data={panelStore?.featureData?.coreData}
  //         />
  //       </div>
  //     </div>
  //     <div className="row">
  //       <div className="col-sm-6 d-flex flex-column pb-3">
  //         <UsefulLife
  //           type={"WEBSITE"}
  //           data={panelStore?.featureData?.coreData}
  //           dataLoading={panelStore.featureDataLoading}
  //         />
  //       </div>

  //       <div className="col-sm-6 d-flex flex-column pb-3">
  //         <MajorPublicTransit
  //           type={"WEBSITE"}
  //           data={panelStore.featureData?.transitSystemData}
  //           dataLoading={panelStore.featureDataLoading}
  //         />
  //       </div>
  //     </div>

  //     <div className="row">
  //       <div className="col-sm-12 d-flex flex-column h-100 pb-3">
  //         <DataSummary
  //           type={"WEBSITE"}
  //           coreData={panelStore.featureData?.coreData}
  //           countyData={panelStore.featureData?.countyData}
  //           dataLoading={panelStore.featureDataLoading}
  //           dataView="both"
  //         />
  //       </div>
  //     </div>

  //     <div className="row">
  //       <div className="col-sm-12 pb-3">
  //         <MemberList
  //           type={"WEBSITE"}
  //           title="APTA Members"
  //           members={panelStore.featureData?.aptaMembers}
  //           dataLoading={panelStore.featureDataLoading}
  //         />
  //       </div>
  //     </div>

  //     <div className="row">
  //       <div className="col-sm-12 pb-3">
  //         <MemberList
  //           type={"WEBSITE"}
  //           title="Bus Manufacturers"
  //           members={panelStore.featureData?.busMembers}
  //           dataLoading={panelStore.featureDataLoading}
  //         />
  //       </div>
  //     </div>

  //     <div className="row">
  //       <div className="col-sm-12 pb-3">
  //         <MemberList
  //           type={"WEBSITE"}
  //           title="Rail Manufacturers"
  //           members={panelStore.featureData?.railMembers}
  //           dataLoading={panelStore.featureDataLoading}
  //         />
  //       </div>
  //     </div>

  //     <div className="row">
  //       <div className="col-sm-12 pb-3">
  //         <MemberList
  //           type={"WEBSITE"}
  //           title="DBE Members"
  //           members={panelStore.featureData?.DBEMembers}
  //           dataLoading={panelStore.featureDataLoading}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // ) : null;
};

export default observer(Explore);
