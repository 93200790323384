"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../constants");
function parseSemiStrings(links, sources) {
    let theLinks = links !== undefined ? links.split(";") : [];
    // let theSources = sources !== undefined ? sources.split(";") : [];
    let theSources = [];
    return [...theLinks, ...theSources];
}
class ContactInfo {
    constructor(type, data) {
        if (type === constants_1.PersonTypes.STATE_LEG) {
            this.email = constants_1.parsePString(data?.email);
            this.contactForm = null;
            this.address1 = constants_1.parsePString(data?.capitol_address);
            this.address2 = constants_1.parsePString(data?.district_address);
            this.phone1 = constants_1.parsePString(data?.capitol_voice);
            this.phone2 = constants_1.parsePString(data?.district_voice);
            this.fax1 = constants_1.parsePString(data?.capitol_fax);
            this.fax2 = constants_1.parsePString(data?.district_fax);
            this.twitter = constants_1.parsePString(data?.twitter);
            this.youtube = constants_1.parsePString(data?.youtube);
            this.instagram = constants_1.parsePString(data?.instagram);
            this.facebook = constants_1.parsePString(data?.facebook);
            this.urls = parseSemiStrings(data?.links, data?.sources);
            this.rss_url = null;
        }
        else {
            this.email = null;
            this.contactForm = constants_1.parsePString(data?.contact_form);
            this.address1 = constants_1.parsePString(data?.office);
            this.address2 = null;
            this.phone1 = constants_1.parsePString(data?.phone);
            this.phone2 = null;
            this.fax1 = constants_1.parsePString(data?.fax);
            this.fax2 = null;
            this.twitter = constants_1.parsePString(data?.twitter_account);
            this.youtube = constants_1.parsePString(data?.youtube_account);
            this.instagram = null;
            this.facebook = constants_1.parsePString(data?.facebook_account);
            this.urls = data?.url ? [data.url] : [];
            this.rss_url = data.rss_url;
        }
    }
}
exports.default = ContactInfo;
