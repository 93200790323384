import React from "react";

import { genMoneyString } from "../../../../components/Pages/Print/helpers";
import { Grant } from "APTAPackage/dist/src/ESRIResponses/GrantData/Grant";

export interface LegendProps {
  grants: Grant[];
}

//TODO: Fix Legend Circles

const Legend: React.FC<LegendProps> = ({ grants }) => {
  const test: (Grant | null)[] = { ...grants };
  if (test?.length < 3) test.push(null);

  return (
    <>
      {grants.map((grant, index) => {
        let recipient = `(${index + 1}) ${grant?.Recipient}` as string;
        let desc = grant?.Project_Description as string;

        if (recipient !== undefined && recipient !== null) {
          if (recipient?.length > 100) {
            recipient = recipient?.slice(0, 55);
            recipient = `${recipient}...`;
          }
        }

        if (desc !== undefined && desc !== null) {
          if (desc?.length > 100) {
            desc = desc?.slice(0, 145);
            desc = `${desc}...`;
          }
        }

        const colors = ["#36a2eb", "#0661a0", "#0d4064"];
        return (
          <div
            className=" d-flex flex-grow-1 align-items-center m-0 pb-3 w-100"
            key={index}
          >
            <div style={{ height: "7rem" }} />
            <div className="d-flex flex-column w-100  justify-content-center">
              <div className="d-flex pb-2 justify-content-center align-items-center">
                <div
                  className="mr-3"
                  style={{
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                    backgroundColor: colors[index],
                    flexShrink: 0,
                  }}
                >
                  {" "}
                </div>
                <h1 className="gm-explore-text-2 mr-3"> { recipient }</h1>
                <h1 className="gm-explore-numeric-2 ml-auto">
                  {genMoneyString(grant?.Allocation)}
                </h1>
              </div>
              <p className="gm-explore-subtext-legend pb-1">{desc}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Legend;
