import React from "react";
import { useStore } from "../../../../../Hooks";
import PanelListElem from ".";
import { stateAbbrevToFull } from "APTAPackage";
import StateImage from "./StateImage";

import ToggleChevron from "../../../../Icons/ToggleChevron";
import { sendGAEvent } from "../../../../../Hooks/useGoogleAnalytics";

interface IStateListElem {
  stateAbbrev: string;
}

const StateListElem: React.FC<IStateListElem> = ({ stateAbbrev }) => {
 
  const panelStore = useStore("panelStore");
  const stateFull = stateAbbrevToFull[stateAbbrev];

  return (
    <PanelListElem
      img={<StateImage stateKey={stateAbbrev} />}
      onClick={() => {
        sendGAEvent({
          eventName: "Click On State",
          others: {
            state: stateAbbrev,
            state_click_state: stateAbbrev
          },
        });
        panelStore.renderStatePanel(stateAbbrev);
      }}
    >
      <span className="gm-li-state-span"> {stateFull} </span>

      <div className="gm-li-state-chevron-wrapper">
        <ToggleChevron isOpened={false} classMod="gm-person-chevron" />
      </div>
    </PanelListElem>
  );
};

export default StateListElem;
