import React, { useRef, useMemo, useLayoutEffect } from "react";
import { Chart, registerables } from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";

type T_lineGraph = {
  id: string;
  title?: string;
  toolLabel?: string;
  backgroundColor?: string;
  fill: boolean;
  items: T_lineGraphItem[];
};

type T_lineGraphItem = {
  label: string;

  value: number;
};

type T_props = T_lineGraph;

export const LineGraph: React.FC<T_props> = ({
  id,
  title,
  toolLabel = null,
  items,
  backgroundColor,
  fill,
}) => {
  const canvasRef = useRef<any | null>(null);
  const graphRef = useRef<Chart | null>(null);
  // Chart.register(...registerables, ChartDataLabels);
  Chart.register(...registerables);

  const parsedData = useMemo(() => {
    const data: number[] = [];
    const colors: string[] = [];
    const labels: (string | string[])[] = [];

    let newItems = [...items];

    newItems.forEach((item) => {
      data.push(item.value);
      labels.push(item.label);
    });

    return {
      data,
      labels,
    };
  }, [items]);

  const { data, labels } = parsedData;

  useLayoutEffect(() => {
    if (graphRef.current !== null) {
      graphRef.current.destroy();
    }

    let myChartRef;
    if (canvasRef.current) {
      myChartRef = canvasRef.current.getContext("2d");
    }

    const dataObj = {
      labels,
      datasets: [
        {
          label: toolLabel,
          data,
          backgroundColor,
          fill,
        },
      ],
    };

    graphRef.current = new Chart(myChartRef, {
      type: "line",
      //@ts-ignore
      data: dataObj,
      options: {
        plugins: {
          datalabels: {
            display: false,
          },
          filler: { propagate: true },
          legend: {
            position: "top",
            labels: {
              boxWidth: 40,
              padding: 15,
              usePointStyle: true,
            },
          },
        },
      },
    });

    return () => {
      if (graphRef.current !== null) {
        graphRef.current.destroy();
      }
    }
    
  }, [items]);

  return <canvas ref={canvasRef} id={id} />;
};
