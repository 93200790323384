import React from "react";
import { useStore } from "../../../../../Hooks";
import CartIcon from "../../../../Icons/CartIcon";
import CartIcon2 from "../../../../Icons/CartIcon2";
import { FedLeg, StateLeg } from "APTAPackage";

import "./CartToggle.css";
import { observer } from "mobx-react";

interface CartToggleProps {
  person: FedLeg | StateLeg;
}

const CartToggle: React.FC<CartToggleProps> = ({ person }) => {
  const newCartStore = useStore("newCartStore")
  const authStore = useStore("authStore");

  const inCart =
    newCartStore?.cart?.[person.identityInfo.hashID] === undefined
      ? false
      : true;

  if (authStore.isAuth) {
    return inCart ? (
      <div
        className="gm-print-icon"
        onClick={(e) => {
          e.stopPropagation();
          newCartStore.removeFromCart([person]);
        }}
      >
        <CartIcon2 />{" "}
      </div>
    ) : (
      <div
        className="gm-print-icon"
        onClick={(e) => {
          e.stopPropagation();
          newCartStore.addToCart([person]);
        }}
      >
        {" "}
        <CartIcon />{" "}
      </div>
    );
  } else {
    return inCart ? (
      <div
        className="gm-print-icon"
        onClick={(e) => {
          e.stopPropagation();
           newCartStore.removeFromCart([person]);
        }}
      >
        <CartIcon2 />
      </div>
    ) : (
      <div
        className="gm-print-icon cart-tooltip"
        onClick={(e) => {
          e.stopPropagation();
           newCartStore.addToCart([person]);
        }}
      >
        <CartIcon />{" "}
        <span className="cart-tooltiptext">
          You can only print one report at a time if you are not signed in.
        </span>
      </div>
    );
  }
  // return inCart ? (
  //   <div
  //     className="gm-print-icon"
  //     onClick={(e) => {
  //       e.stopPropagation();
  //       cartStore.removeFromCart([person]);
  //     }}
  //   >
  //     <CartIcon2 />{" "}
  //   </div>
  // ) : (
  //   <div
  //     className="gm-print-icon cart-tooltip"
  //     onClick={(e) => {
  //       e.stopPropagation();
  //       cartStore.addToCart([person]);
  //     }}
  //   >
  //     {" "}
  //     <CartIcon />{" "}
  //     {!authStore.isAuth && (
  //       <span className="cart-tooltiptext">
  //         You can only print one report at a time if you are not signed in.
  //       </span>
  //     )}
  //   </div>
  // // );
};

export default observer(CartToggle);
