import React, { useEffect } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useStore } from "../../Hooks";
import "./baseform.css";

type BaseFormProps = {
  hasSubmitButton?: boolean;
  formJson: any;
  onSubmitFunc?: any;
  error?: any;
};

const BaseFormComponent: React.FC<BaseFormProps> = ({
  hasSubmitButton,
  formJson,
  onSubmitFunc,
  error,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldFocusError: false,
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const authStore = useStore("authStore");

  const onSubmit = async (data: any) => {
    await onSubmitFunc(data);
  };

  const isValidUI = (field: any) => {
    if (field.validation?.required && isDirty) {
      if (isValid) return "is-valid";
      if (!!!dirtyFields[field.formName]) return "is-invalid";
      if (errors[field.formName]) return "is-invalid";
      else return "is-valid";
    } else {
      return "";
    }
  };

  const uiFeedback = (field: any) => {
    if (!!!field.validation?.required) return;
    if (errors[field.formName] || isDirty) {
      const errorType = errors[field.formName]?.type;
      if (errorType === "minLength")
        return `${field.errorLabel} minimum length is ${field.validation.minLength}`;
      else if (errorType === "maxLength")
        return `${field.errorLabel} maximum length is ${field.validation.maxLength}`;
      else if (errorType === "pattern")
        return `${errors[field.formName]?.message}`;
      else if (
        (field.validation.required && errors[field.formName]) ||
        !dirtyFields[field.formName]
      )
        return `${field.errorLabel} is required`;
      else {
        return "Looks Good!";
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formJson.sections.map((section, idx) => {
          return (
            <React.Fragment key={idx}>
              <div className="row">
                <div className={`col pt-2 text-center`}>
                  {section.titleIcon && (
                    <img className="text-center" src={section.titleIcon} />
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  className={`col-md-8 mx-auto pt-2 px-3 ${
                    section.centerText && "text-center"
                  }`}
                >
                  <h2 className="gm-red-text">{section.title}</h2>
                  <p className=" w-100 text-wrap"> {section.para}</p>
                </div>
              </div>
              <div className="row">
                {section.fields.map((field, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <div
                        className={`col${"-lg-" + field.colSize} ${
                          field.centerCol ? "mx-auto" : ""
                        } ${!!!field?.validation ? "mt-2" : ""} `}
                      >
                        <label
                          className={`form-label ${
                            field.labelCenter ? "w-100 text-center" : ""
                          }`}
                        >
                          {field.label}
                        </label>
                        <input
                          placeholder={field.label}
                          type={field.dataType}
                          className={`form-control ${isValidUI(field)}`}
                          required={!!field.validation?.required}
                          {...register(field.formName, field?.validation)}
                          disabled={!!field?.disabled}
                          defaultValue={field?.defaultValue}
                        ></input>
                        {/* <div className="valid-feedback">Looks good!</div> */}
                        <div className="invalid-feedback">
                          {uiFeedback(field)}
                        </div>
                      </div>
                      {field.newLine && <div className={`col-${12}`}></div>}
                    </React.Fragment>
                  );
                })}{" "}
              </div>
            </React.Fragment>
          );
        })}
        <div className="row p-2">
          <div className="col text-center">
            <>
              {error && (
                <div className="row">
                  <div className="col text-center">
                    <h4 className="text-danger">{error}</h4>
                  </div>
                </div>
              )}
              {hasSubmitButton && (
                <>
                  {isSubmitting ? (
                    <button className="gm-basic-button btn-secondary disabled">
                      <div
                        className="spinner-border text-dark"
                        role="status"
                        style={{ height: "1rem", width: "1rem" }}
                      ></div>
                      <span className="h-100 my-auto align-middle">
                        {" "}
                        Signing in...
                      </span>
                    </button>
                  ) : (
                    <button
                      disabled={authStore.isAuth || !isValid}
                      className={`gm-basic-button ${
                        authStore.isAuth || isValid
                          ? "btn-danger"
                          : "btn-secondary disabled"
                      }`}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Sign in
                    </button>
                  )}
                </>
              )}
            </>
          </div>
        </div>
      </form>
    </>
  );
};

export default BaseFormComponent;
