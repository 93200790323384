import { stat } from "fs";
import React, { useMemo } from "react";
import { T_jobStatus } from "../../../Stores/newCartStore.ts/newCartStore";

type T_props = {
  color?: string;
  status: T_jobStatus | undefined;
};

export const CartSpinner: React.FC<T_props> = ({ color = "white", status }) => {
  let text = useMemo(() => {
    const successCount = status?.counts?.successCount;
    const errorCount = status?.counts?.errorCount;
    const percent = status?.counts?.percent;
    const total = status?.counts?.total;

    let text = "Loading. . .";
    if (status == null) text = "Queueing Job";
    else if (
      total !== undefined &&
      successCount !== undefined &&
      errorCount !== undefined &&
      percent !== undefined &&
      percent !== 100
    )
      text = `${successCount + errorCount} / ${total} Complete`;
    else if (percent == 100) {
      text = "Zipping Results";
    }

    return text;
  }, [status]);

  return (
    <>
      <div className="spinner" style={{ margin: 0 }}>
        <div className="rect1" style={{ backgroundColor: color }}></div>
        <div className="rect2" style={{ backgroundColor: color }}></div>
        <div className="rect3" style={{ backgroundColor: color }}></div>
        <p style={{ fontSize: "1rem", fontWeight: 300 }}>{text}</p>
      </div>
    </>
  );
};
