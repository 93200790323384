import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../Hooks";

import Table from "./Table";
import { Stepper } from "./Components/Stepper";

import { CartButton } from "./Components/CartButton/CartButton";
import { getPageConfig, titles } from "./Config";
import CartSelect from "./CartSelect";

import { useHistory } from "react-router-dom";
import FormatCard from "./Components/FormatCard";
import CartButtons from "./CartButtons";

const Cart: React.FC = () => {
  const newCartStore = useStore("newCartStore");
  const activePage = newCartStore?.cartPage;

  const config = getPageConfig(activePage, newCartStore);
  const cartLength = newCartStore.cartSize;

  const history = useHistory();

  return (
    <div className="container ">
      <div className="row pr-5 pl-5 pt-5">
        <div className="col">
          <Stepper labels={titles} activeLabel={activePage} />
        </div>
      </div>

      <div className="row pt-5">
        <div className="col">
          <div style={{ display: "flex" }}>
            <h3 style={{ color: "rgba(210, 23, 41, 1)" }}>
              {config.title} ({cartLength})
            </h3>
            <CartButton
              label="Clear Cart"
              style={{ marginLeft: "auto" }}
              onClick={() => newCartStore.clearCart()}
            />
          </div>
          <hr
            style={{ borderColor: "rgba(68, 46, 141, 1)", borderWidth: "3px" }}
          />

          {cartLength === 0 && <h4> There are no items in your cart.</h4>}
        </div>
      </div>

      <div className="row">
        <div className="col py-2 text-center">
          {" "}
          Due to print complexity, expect one to two minutes for your first PDFs
          to complete.
        </div>
      </div>

      {config.showLayers && (
        <>
          <div className="row justify-content-center pb-1">
            <div className="col-5 col-lg-2 text-center">
              <FormatCard
                type="PAGE"
                title="Full Print"
                paperType="A4"
                dimensions="8.5 by 11 in."
              />
            </div>
            <div className="col-5 col-lg-2 text-center">
              <FormatCard
                type="POSTCARD"
                title="Postcard"
                paperType="A6"
                dimensions="4 x 6 in."
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <CartSelect title="File Options" type="fileOptions" />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <CartSelect title="Select Map Layers" type="layers" />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <CartSelect title="Select Print Sections" type="sections" />
            </div>
          </div>
        </>
      )}

      {config.columns != null && cartLength !== 0 && !config.showLayers && (
        <div className="row">
          <div className="col">
            <Table />
          </div>
        </div>
      )}

      <CartButtons />
    </div>
  );
};

export default observer(Cart);
