import React from "react";
import { fundingLineGraph } from "../../../../../constants/config";
import { HomeHeader } from "../HomeHeader";
import { FundingBox, T_fundingBoxProps } from "./FundingBox";
import { HomeText } from "../HomeText";
import { LineGraph } from "../../../../graphs/LineGraph";
import Doughnut from "../../../../graphs/Doughnut";
import { config } from "../../../../../constants/config";
import { Space } from "../Space";

const text = {
  title: "Receiving More Investment",
  paraBold:
    "Public transportation operations are funded by passenger fares, agency earnings, and assistance from state, local and federal governments.",
  paraRest:
    "Total inflation-adjusted revenue has increased from $51 billion to nearly $80 billion over the last 20 years.",
};

const fundingItems: T_fundingBoxProps[] = [
  { funding: "$51B", year: "2000" },
  { funding: "$67.7B", year: "2010" },
  { funding: "$79.1B", year: "2020" },
];

const aptaFunding = config.aptaFunding;

export class FundingHome extends React.Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col">
            <HomeHeader
              title="Funding for Public Transit"
              id="funding_section"
            />

            <div className="row">
              <div className="col-md">
                <h3 className="gm-left-text text-blue">{text.title}</h3>
                <HomeText bold={text.paraBold} rest={text.paraRest} />

                <div className="row text-center p-1">
                  {fundingItems.map((item, idx) => {
                    return (
                      <FundingBox
                        key={item.year}
                        funding={item.funding}
                        year={item.year}
                        showBorder={
                          idx == fundingItems.length - 1 ? false : true
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <div className="col-md">
                <LineGraph {...fundingLineGraph} />
              </div>
            </div>
          </div>
        </div>
        <Space isHR={false} />
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col">
                <h2 className="headerText">Economic Impact:</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-md text-center">
                <img
                  style={{ height: "250px" }}
                  src="/images/econ-impact.png"
                  alt="Economic Impact"
                />
              </div>
              <div className="col text-center my-auto">
                <p className="text-center">
                  <strong>
                    {" "}
                    Each $1 billion invested in public transit provides a
                    long-term economic impact of $5 billion.
                  </strong>{" "}
                  That’s 50,000 jobs created and supported per $1 billion
                  invested. APTA members can check local impacts using APTA’s My
                  Economic Impact Tool.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="col">
                    <a
                      className="btn btn-primary px-5"
                      href="https://www.apta.com/research-technical-resources/my-economic-impact-tool/"
                      target="_blank"
                      style={{ backgroundColor: "#0079c1" }}
                    >
                      My Economic Impact Tool
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ clear: "both", paddingTop: "20px" }}>
          <div className="col">
            <div className="row">
              <div className="col">
                <h2 className="headerText">Spending:</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-lg-8 my-auto">
                <div className="row doughnut-chart-title">
                  <Doughnut
                    id="aptaFunding"
                    data={aptaFunding.data}
                    units="$"
                    toolLabel={aptaFunding.toolLabel}
                    labels={aptaFunding.labels}
                    labelPercents={aptaFunding.labelPercents}
                    colors={aptaFunding.colors}
                  />
                </div>
              </div>

              <div className="col-lg-4 mt-5 mt-lg-1">
                <h4>
                  {" "}
                  <strong> $74.3 BILLION </strong>
                </h4>
                <p className=" card-last">
                  <strong>
                    In 2020, total public transportation expenditures were over
                    $74 billion, with $50 billion on operations and $24 billion
                    on capital investments.{" "}
                  </strong>
                  Bus received the largest combined capital and operating
                  investment ($28 billion) followed by Heavy Rail ($17 billion).
                  Spending was nearly equal - roadway modes received $37 billion
                  in spending, and rail and ferry modes also received $37
                  billion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
