import { useEffect, useState } from "react";
import useEsriModules from "./useEsriModules";
import {
  AGOLResponse,
  getLegislatorData,
  LegislatorMap,
  LegislatorTypes,
} from "APTAPackage";
import * as fullData from "../../public/data2/fullData.json";

export const usePreparePrint = () => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const url = new URL(window.location.href);

  const { esriToken, esriObj, ident } = useEsriModules();

  useEffect(() => {
    if (esriToken != null) {
      getPrintData(esriToken)
        .then((retData) => setData(retData))
        .catch((retError) => setError(retError));
    }
  }, [esriToken]);
  return { data, esriObj, error, esriToken, ident };
};

async function getPrintData(session: any) {
  return new Promise(async function (resolve, reject) {
    try {
      // Parse Params
      const url = new URL(window.location.href);
      const typeString = url.pathname.slice(7);

      const isPostcard = typeString == "postcard";

      const hashId = url.searchParams.get("hashId");
      const sectionsRaw = url.searchParams.get("sections");
      const layersRaw = url.searchParams.get("layers");

      let sections = "";
      let layers = "";

      if (!hashId) {
        console.error("no hash id");
        return reject(new Error("400 - Prereq"));
      }

      if (!isPostcard) {
        if (!hashId || !sectionsRaw || !layersRaw) {
          console.error("no hash id or sections raw or layers raw");
          return reject(new Error("400 - Prereq"));
        }

        sections = JSON.parse(decodeURIComponent(sectionsRaw));
        layers = JSON.parse(decodeURIComponent(layersRaw));
      }

      if (!hashId || ((!sectionsRaw || !layersRaw) && !isPostcard)) {
        console.error("no hash id or (sections raw or layers raw)");
        return reject(new Error("400 - Prereq"));
      }

      // Get Legislator
      const allStateObj = new LegislatorMap(fullData as any);


      const person = allStateObj.getPerson(hashId);
      if (person == null) {
        console.error("could not find person");
        return reject(new Error("404"));
      }

      // Commented out given changes to old
      // if (
      //   person.identityInfo.stateAbbrev === "DC" &&
      //   person.identityInfo.type === LegislatorTypes.HOUSE
      // ) {
      //   person.identityInfo.district = "98";
      // } else if (
      //   person.identityInfo.stateAbbrev === "PR" &&
      //   person.identityInfo.type === LegislatorTypes.HOUSE
      // ) {
      //   person.identityInfo.district = "1";
      // }

      let legistlatorData: AGOLResponse | null = null;

      try {
        // Get Legislator Data
        legistlatorData = await getLegislatorData(
          person?.identityInfo,
          session.token
        );
      } catch (error) {
        console.error("failed leg data", error);
        throw new Error("leg data");
      }

      resolve({
        printData: { sections, layers },
        memberData: { theMember: person, AGOLData: { ...legistlatorData } },
      });
    } catch (error) {
      console.error("in error", error);
      reject(error);
    }
  });
}
