import React from "react";
import DataSummary from "../../Explore/Sections/DataSummary/DataSummary";
import MajorPublicTransit from "../../Explore/Sections/MajorPublicTransit";
import RegionalFunding from "../../Explore/Sections/RegionalFunding";
import QrCode from "../Components/QrCode";

interface MemberData {
  memberData: any;
  isPreview: boolean;
}

const PostCardBack: React.FC<MemberData> = ({ memberData, isPreview }) => {
  return (
    <>
      {isPreview && (
        <div className="row">
          <div className="col text-center">
            <h1>Back</h1>
          </div>
        </div>
      )}

      <div
        className="container-fluid pt-3"
        style={
          isPreview
            ? {
                boxShadow: "0px 0px 8px black",
                borderRadius: "15px",
                padding: "30px",
                height: "1200px",
                width: "1800px",
              }
            : {}
        }
      >
        <div className="row d-flex">
          <div className="col d-flex flex-column pl-5 pr-5" style={{ height: "600px" }}>
            <MajorPublicTransit
              data={memberData?.AGOLData?.transitSystemData}
              type={"POSTCARD"}
            />
          </div>

          <div className=" col d-flex flex-column  pl-5 pr-5" style={{ height: "600px" }}>
            <RegionalFunding
              type="POSTCARD"
              data={memberData?.AGOLData?.coreData}
              dataLoading={false}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8 px-5" style={{ height: "600px" }}>
            <DataSummary
              coreData={memberData.AGOLData?.coreData}
              countyData={memberData.AGOLData?.countyData}
              dataLoading={false}
              type="POSTCARD"
              dataView="both"
            />
          </div>
          <div className="col my-auto" style={{ height: "520px" }}>
            <QrCode />
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCardBack;
