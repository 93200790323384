import React from "react";
import "./CartButton.css";

type T_props = {
  invert?: boolean;
  label: string;
  onClick?: any;
  className?: string;
  style?: any;
};

export const CartButton: React.FC<T_props> = ({
  label,
  invert = false,
  className,
  onClick,
  style,
}) => {
  return (
    <button
      style={style ? { ...style } : {}}
      className={`btn btn-primary ${
        invert ? "gm-cart-button-invert" : "gm-cart-button"
      } ${className}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
