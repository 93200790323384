"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const County_1 = require("./County");
class Counties {
    constructor(type, data) {
        const theGrants = [];
        data.forEach((county) => {
            const theCounty = county?.attributes;
            theGrants.push(new County_1.County(type, theCounty));
        });
        this.counties = theGrants;
    }
}
exports.default = Counties;
