import React from "react";
import { Agencies } from "APTAPackage";

import { convertNumber } from "../../../components/Pages/Print/helpers";
import { SectionTitle } from "../Components/SectionTitle";

import { typeToClass, T_dataComponent } from "../../types";
import NoData from "../Components/NoData";

import LoadingSpinner from "../../../components/Icons/LoadingSpinner";
import { Agency } from "APTAPackage/dist/src/ESRIResponses/AgencyData/Agency";

interface IProps {
  data: Agencies;
  dataLoading?: boolean;
  type: T_dataComponent;
}

const MajorPublicTransit: React.FC<IProps> = ({ data, dataLoading, type }) => {
  let counties = data?.counties;
  counties = counties?.slice(0, 5);



  const countyObject: { [key: string]: Agency } = {};
  let countiesNoDupe: Agency[] = [];

  data?.counties?.forEach((agency) => {

    if(agency?.NTD_ID == null) return;

    if (!countyObject[agency?.NTD_ID]) {
      countyObject[agency?.NTD_ID] = agency;
      countiesNoDupe.push(agency);
    }
  });

  countiesNoDupe = countiesNoDupe?.slice(0, 5);
  const classMod = typeToClass(type);
  const isNoData = !!!counties || counties.length === 0;

  while (countiesNoDupe?.length < 5) {
    //@ts-ignore
    countiesNoDupe.push({ Agency_Name: " ", Service_Area_Pop: -1 });
  }

  return (
    <SectionTitle
      title="Public Transit Systems"
      subTitle={isNoData ? "" : "Population Served (if available)"}
      type={type}
    >
      <div className="d-flex flex-column flex-grow-1">
        {dataLoading ? (
          <LoadingSpinner color="var(--apta-navbar-text-color)" />
        ) : !!!counties || counties.length === 0 ? (
          <NoData />
        ) : (
          countiesNoDupe?.map((county, index) => {
            let name = county?.Agency_Name;
            let population = county?.Service_Area_Pop
              ? county.Service_Area_Pop === -1
                ? ""
                : convertNumber(county?.Service_Area_Pop)
              : "N/A";

            const key = `${name}-${index}`;

            return (
              <div
                key={key}
                className=" d-flex flex-nowrap flex-grow-1 mt-auto mb-auto align-items-center pb-3"
              >
                <div style={{ height: "3rem" }} />
                <div className="d-flex w-100">
                  <h1 className={`gm-explore-text-2${classMod} pr-3`}>
                    {name}
                  </h1>

                  <h1 className={`gm-explore-numeric-2${classMod} ml-auto`}>
                    {" "}
                    {population}
                  </h1>
                </div>
              </div>
            );
          })
        )}
      </div>
    </SectionTitle>
  );
};

export default MajorPublicTransit;
