import { request } from "@esri/arcgis-rest-request";
import { T_attributes } from "./types";

type T_args = {
  url: string;
  state?: string;
  token: string;
  otherWhere?: string;
};
export const getGrantsRequest = ({ url, state, token, otherWhere }: T_args) => {
  return new Promise<T_attributes[]>(async (resolve, reject) => {
    try {
      // const getToken = await getAuthToken();
      // const authToken = getToken?.token;

      let where = "1=1";

      if (state) {
        where = `STATE = '${state}'`;
      }

      if (otherWhere) {
        where = otherWhere;
      }

      const getGrants = await request(url, {
        authentication: token,
        params: {
          f: "json",
          where,
          outFields: "*",
        },
      });

      const returnObj: T_attributes[] = getGrants?.features?.map(
        (item: { attributes: T_attributes }) => {
          return item?.attributes;
        }
      );

      return resolve(returnObj);
    } catch (error) {
      return reject(error);
    }
  });
};
