// ~~~~~ Twitter ~~~~~
export const twitterTitle =
  "Knowledge is power. APTA's Industry Footprint shows every #publictransit system, supplier, and manufacturer by Congressional District and State. Learn more:";
export const twitterVia = "APTA_Transit";

// ~~~~~ Facebook ~~~~~
export const facebookQuote =
  "Knowledge is power. APTA's Industry Footprint shows every #publictransit system, supplier, and manufacturer by Congressional District and State. Learn more:";

// ~~~~~ Reddit ~~~~~
export const redditQuote =
  "Knowledge is power. APTA's Industry Footprint shows every #publictransit system, supplier, and manufacturer by Congressional District and State. Learn more:";
