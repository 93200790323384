import React from "react";

export interface SlideProps {
  header: string;
  mainText: string;
  img: string;
  index?: number;
}

const Slide: React.FC<SlideProps> = ({ header, mainText, img, index }) => {
  return (
    <div className={`carousel-item h-100 ${index === 0 ? "active" : ""}`}>
      <div className="gm-slide-text w-100 my-auto">
        <h1> {header} </h1>
        <p>{mainText}</p>
      </div>
      <img
        className="gm-homepage-carousel-image"
        src={img}
        alt={`Slide${header}`}
      />
    </div>
  );
};

export default Slide;
