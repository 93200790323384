import React, { useEffect, useMemo, useState } from "react";
import { useGetGrants } from "../../../../services";
import { useHistory } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { useStore } from "../../../../Hooks";
import { T_attributes } from "../../../../services/Grants/types";
import { T_dataComponent } from "../../../types";
import { SectionTitle } from "../../Components/SectionTitle";
import LoadingSpinner from "../../../../components/Icons/LoadingSpinner";
import NoData from "../../Components/NoData";
import { Counties, CoreData } from "APTAPackage";
import { GrantTable } from "./GrantTable";
import { Agencies, Grants, LegislatorTypes } from "APTAPackage";
import { FedLeg } from "aptapackage";
import { StateLeg } from "aptapackage";

type T_props = {
  type?: T_dataComponent;
  countyData?: Counties;
  coreData?: CoreData;
  member?: FedLeg | StateLeg
  
};

export const GrantDetails = ({ type, coreData, member }: T_props) => {
  const history = useHistory();

  const panelStore = useStore("panelStore");
  const authStore = useStore("authStore");

  const activerPersonIdentity = member?.identityInfo != null  ? member.identityInfo : panelStore?.activePerson?.identityInfo;

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    authStore.GET_esri().then((res) => {
      setToken(res.token);
    });
  }, []);

  const pathName = history.location.pathname.toLowerCase();

  const searchQuery = history.location.search;

  const searchObj = searchQuery
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}) as any;

  const hashId = searchObj?.hashId?.toLowerCase() as string;

  const splitHashId = hashId?.split("-");

  const crumbString = panelStore.crumbStrings;

  const state = (panelStore?.activeState ||
    coreData?.identityInfo?.stateAbbrev) as string;

  const getGrants = useGetGrants({
    state,
    token,
    pathName,
    hashId,
    activerPersonIdentity,
  });



  const dataIsLoading = getGrants?.isLoading;

  const getExploreTextClass = () => {
    switch (type) {
      case "POSTCARD":
        return "gm-explore-text-3-postcard";
      case "FULL_PAGE":
        return "gm-explore-text-3";
      case "WEBSITE":
        return "gm-explore-text-3";
    }
  };

  const getExploreNumericClass = () => {
    switch (type) {
      case "POSTCARD":
        return "gm-explore-numeric-4-postcard";
      case "FULL_PAGE":
        return "gm-explore-numeric-4";
      case "WEBSITE":
        return "gm-explore-numeric-4";
    }
  };

  const columnHelper = createColumnHelper<T_attributes>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("Recipient", {
        cell: (info) => (
          <span className={`${getExploreTextClass()}`}>
            {" "}
            {info.renderValue()}{" "}
          </span>
        ),
        footer: (info) => info.column.id,
        header: () => <span className={getExploreTextClass()}>Recipient</span>,
        size: 200,
      }),
      columnHelper.accessor("Allocation", {
        cell: (info) => {
          const USD = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          });
          return (
            <span className={`${getExploreNumericClass()}`}>
              {USD?.format(info?.getValue())}
            </span>
          );
        },
        footer: (info) => info.column.id,
        header: () => <span>Allocation</span>,
        size: 10,
      }),
      columnHelper.accessor("Project_Description", {
        footer: (info) => info.column.id,
        header: () => (
          <span className={getExploreTextClass()}>Project Description</span>
        ),
        cell: (info) => (
          <span className={`${getExploreTextClass()}`}>
            {" "}
            {info.renderValue()}{" "}
          </span>
        ),
        size: 150,
      }),
      columnHelper.accessor("Program", {
        cell: (info) => (
          <span className={`${getExploreTextClass()}`}>
            {" "}
            {info.renderValue()}{" "}
          </span>
        ),
        footer: (info) => info.column.id,
        header: () => <span className={getExploreTextClass()}>Program</span>,
        size: 150,
      }),
      columnHelper.accessor("Year", {
        cell: (info) => {
          return (
            <span className={`${getExploreNumericClass()}`}>
              {info?.getValue()}
            </span>
          );
        },
        footer: (info) => info.column.id,
        header: () => <span>Year</span>,
        size: 10,
      }),
    ];
  }, []);

  const seatName = useMemo(() => {
    const district =
      splitHashId && `${splitHashId[1].toUpperCase()}-${splitHashId[2]}`;

    const isHouse = pathName.includes("house") || hashId?.includes("house");

    const isUpperChamb = pathName.includes("sldu") || hashId?.includes("sldu");

    const isLowerChamb = pathName.includes("sldl") || hashId?.includes("sldl");

    const isFed = pathName.includes("senate") || hashId?.includes("senate");

    const upperFilter =
      (isUpperChamb &&
        crumbString?.[3]?.slice(crumbString?.[3]?.lastIndexOf("-") + 1)) ||
      splitHashId?.[2];
    const lowerFilter =
      (isLowerChamb &&
        crumbString?.[3]?.slice(crumbString?.[3]?.lastIndexOf("-") + 1)) ||
      splitHashId?.[2];

    const seatName = isFed
      ? "Statewide"
      : isHouse
      ? `Congressional District ${crumbString[2] || `${district}`}`
      : isUpperChamb
      ? `Upper Chamber ${upperFilter}`
      : isLowerChamb
      ? `Lower Chamber ${lowerFilter}`
      : "Statewide";

    return seatName;
  }, [pathName, crumbString]);

  const sortFromLowestToHighest = useMemo(() => {
    let arr: T_attributes[] = [];

    if (getGrants?.isSuccess) {
      const filtered = filterSets(getGrants?.data);

      arr = [...arr, ...filtered];
    }
    const sorted = arr?.sort((a, b) => {
      return b.Allocation - a.Allocation;
    });

    return sorted;
  }, [getGrants?.isLoading, activerPersonIdentity?.builtID]);

  return (
    <div
      style={{
        // breakAfter: "avoid",
        breakInside: "avoid",
      }}
    >
      <SectionTitle title="Grant Details" type={type ?? "WEBSITE"}>
        {dataIsLoading && (
          <LoadingSpinner color="var(--apta-navbar-text-color)" />
        )}

        {!dataIsLoading && sortFromLowestToHighest?.length === 0 && <NoData />}

        {!dataIsLoading && sortFromLowestToHighest?.length > 0 && (
          <>
            <div className="row pb-3">
              <div className="col-6 gm-explore-text-2">
                <span style={{ fontWeight: "bolder" }}>State: </span>
                {state}
              </div>
              <div className="col-6 gm-explore-text-2">
                <span style={{ fontWeight: "bolder" }}>Region: </span>
                {seatName}
              </div>
            </div>

            <GrantTable
              columns={columns}
              rows={sortFromLowestToHighest}
              type={type ?? "WEBSITE"}
              printAllRows={true}
            />
          </>
        )}
      </SectionTitle>
    </div>
  );
};

const filterSets = (array: any[]): any[] => {
  const newObj = {};

  array.forEach((item) => {
    //@ts-ignore
    newObj[item.unique_] = item;
  });

  return Object.values(newObj);
};
