import React from "react";
import GenericIcons from "./GenericIcon";

interface ToggleChevronProps {
  classMod?: string;
  isOpened: boolean;
}

const HoriChevron: React.FC<ToggleChevronProps> = ({ isOpened, classMod }) => {
  const theIcon = isOpened ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M13.207 7l9 9-9 9h-1.414l9-9-9-9z" />
      <path fill="none" d="M0 0h32v32H0z" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M18.793 25l-9-9 9-9h1.414l-9 9 9 9z" />
      <path fill="none" d="M0 0h32v32H0z" />
    </svg>
  );

  return <GenericIcons classMod={classMod} icon={theIcon} />;
};

export default HoriChevron;
