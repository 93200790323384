import React from "react";
import "./TransitHome.css";

type T_props = {
  count: string;
  label: string;
  color: string;
  isBorder?: boolean;
};

export type T_transitBoxProps = T_props;

export const TransitBox: React.FC<T_props> = ({
  count,
  label,
  color,
  isBorder = false,
}) => {
  return (
    <div
      className={`col-md-4 pt-4 text-center ${
        isBorder ? "gm-border-right" : ""
      }`}
    >
      <h1 className={`${color} gm-transBox-count`}>{count}</h1>
      <h2 className={`${color}`}>{label}</h2>
      <p className="gm-homepage-font-size-small">
        Public Transportation
        <br /> Systems
      </p>
    </div>
  );
};
