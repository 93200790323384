import * as genIdent from "../../../../Types/MapClicks/IdentityInfo";
import * as Clicks from "../../../../Types/MapClicks/Clicks";

import { LegislatorTypes } from "APTAPackage";
import { geoLayers } from "../../../../constants/configTS";
import { sendGAEvent } from "../../../../Hooks/useGoogleAnalytics";

// Helper Function that configures the layers for popups
// Layer list etc
export function configureLayers(theWebMap: any) {
  let layersArr: any[] = [];

  theWebMap.allLayers.forEach((layer: any) => {
    const theItemId = layer?.portalItem?.id;
    if (theItemId !== undefined) {
      switch (theItemId) {
        case geoLayers[LegislatorTypes.STATE_SUM]: {
          layersArr.push(layer);
          layer.visible = true;
          layer.listMode = "hide";
          layer.popupEnabled = true;
          layer.outFields = ["*"];

          break;
        }
        case geoLayers[LegislatorTypes.HOUSE]: {
          layersArr.push(layer);
          layer.popupEnabled = false;
          layer.listMode = "hide";
          layer.outFields = ["*"];
          break;
        }
        case geoLayers[LegislatorTypes.SLDU]: {
          layersArr.push(layer);
          layer.popupEnabled = false;
          layer.listMode = "hide";
          layer.outFields = ["SLDU_ID"];
          break;
        }
        case geoLayers[LegislatorTypes.SLDL]: {
          layersArr.push(layer);
          layer.popupEnabled = false;
          layer.listMode = "hide";
          layer.outFields = ["SLDL_ID"];
          break;
        }
      }
    }
  });
  return layersArr;
}

export function processHitTest(event: any, view: any): Promise<any> {
  return new Promise((resolve, reject) => {
    view
      .hitTest(event)
      .then((hitRes: any) => {
        const { results } = hitRes;
        let breakFor = false;

        // Loop Through Every Result and Look for a Top Level Layer
        for (let theRes of results) {
          // Get ID and Data from the result
          //@ts-ignore
          const theItemId = theRes?.graphic?.layer?.portalItem?.id;
          const { attributes, geometry } = theRes.graphic;

          if (theItemId !== undefined) {
            switch (theItemId) {
              case geoLayers[LegislatorTypes.STATE_SUM]: {
                breakFor = true;
                let stateData = genIdent.genIdentState(
                  attributes as Clicks.IStateClick,
                  geometry
                );
                resolve(stateData);

                break;
              }
              case geoLayers[LegislatorTypes.HOUSE]: {
                breakFor = true;
                let houseData = genIdent.genIdentHouse(
                  attributes as Clicks.IHouseClick,
                  geometry
                );
                resolve(houseData);

                break;
              }
              case geoLayers[LegislatorTypes.SLDU]: {
                breakFor = true;
                let SLDUData = genIdent.genIdentSLDU(
                  attributes as Clicks.ISLDUClick,
                  geometry
                );
                resolve(SLDUData);

                break;
              }
              case geoLayers[LegislatorTypes.SLDL]: {
                breakFor = true;
                let SLDLData = genIdent.genIdentSLDL(
                  attributes as Clicks.ISLDLClick,
                  geometry
                );
                resolve(SLDLData);

                break;
              }
              default: {
                break;
              }
            }
          }
          if (breakFor === true) break;
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function hitTest(event: any, view: any): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      // Null Case
      if (view == null || event == null) throw new Error();

      const hitRes = await view.hitTest(event);
      const { results } = hitRes;

      let count = 0;
      let identityData = null;

      // Loop Through Every Result and Look for a Top Level Layer
      for (let result of results) {
        const layer = result?.graphic?.layer;
        const itemId = layer?.portalItem?.id;
        const attributes = result?.graphic?.attributes;
        const geometry = result?.graphic?.geometry;

        if (itemId != null && attributes != null && geometry != null) {
          count++;

          if (
            itemId === geoLayers[LegislatorTypes.STATE_SUM] &&
            identityData == null
          ) {
            identityData = genIdent.genIdentState(
              attributes as Clicks.IStateClick,
              geometry
            );
          } else if (
            itemId === geoLayers[LegislatorTypes.HOUSE] &&
            identityData == null
          ) {
            identityData = genIdent.genIdentHouse(
              attributes as Clicks.IHouseClick,
              geometry
            );
          } else if (
            itemId === geoLayers[LegislatorTypes.SLDU] &&
            identityData == null
          ) {
            identityData = genIdent.genIdentSLDU(
              attributes as Clicks.ISLDUClick,
              geometry
            );
          } else if (
            itemId === geoLayers[LegislatorTypes.SLDL] &&
            identityData == null
          ) {
            identityData = genIdent.genIdentSLDL(
              attributes as Clicks.ISLDLClick,
              geometry
            );
          }
        }
      }
      return resolve({ identityData, count });
    } catch (error) {
      reject(error);
    }
  });
}

function findLayer(theLayerId: string, view: any) {
  return view.layerViews.find((layerView: any) => {
    if (layerView?.layer?.portalItem?.id === theLayerId) return layerView;
  });
}

export function activateGeoLayer(theLayerId: string, view: any) {
  let geoLayersArr = Object.values(geoLayers);
  let filteredLayersArr = geoLayersArr.filter((id) => id !== theLayerId);

  filteredLayersArr.forEach((id) => {
    const theLayerView = findLayer(id, view);
    theLayerView.visible = false;
  });

  const theLayerView = findLayer(theLayerId, view);
  theLayerView.visible = true;
  return theLayerView;
}

const layerNames = {
  "Public Transit Opportunity Index": "COUNTY",
  "Urbanized Area Service Data": "NAME20",
  // "Bus_Quarter_Mile_Coverage_2022",
  grant_areas_2022_gen: "Recipient",
  // "CD_Core_2023",
  // "SLDU_Core_2023",
  // "SLDL_Core_2023",
  // State_Core_2023: "NAME",
  "Transit Agency Service Areas": "Agency_Name",
  "Agency Vehicle Life 2019": "Agency_Name",
  "Rail Lines 2023": "name",
  "Amtrak Routes 2023": "line",
  "Rail Stations 2023": "line",
  "Amtrak Stations 2023": "line",
  Rail_manu_2023: "Company_Na",
  Bus_manu_2023: "Company_Na",
  "Rural Public Transportation Providers": "Sub_Recipient_Agency",
  members_2023: "cst_name_cp",
  // "members_2023",
  // "APTAMembersPredominance_pts",
  // "APTAMembersPredominance_pts",
};

export function sendMapClickEventToGA(feature: any) {
  const sourceName = feature?.layer?.source?.sourceJSON?.name;

  if (!sourceName) {
    return;
  }

  const attr = feature?.attributes;

  //@ts-ignore
  if (!attr?.[layerNames?.[sourceName]]) {
    return;
  }

  let others: any = {
    //@ts-ignore
    [sourceName]: attr?.[layerNames?.[sourceName]],
  };

  // sendGAEvent({
  //   eventName: "Map Feature Layer click",
  //   others: others,
  // });

  sendGAEvent({
    eventName: "Map Source Feature Layer Click",
    others: {
      Feature_Layer: sourceName,
    },
  });
}
