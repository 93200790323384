import React from "react";
import GenericIcons from "./GenericIcon";

interface ToggleChevronProps {
  classMod?: string;
  isOpened: boolean;
  size?: string;
}

const ToggleChevron: React.FC<ToggleChevronProps> = ({ isOpened, size = '32' }) => {
  const theIcon = isOpened ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width= {size}
      height= {size}
      viewBox="0 0 32 32"
      className="svg-icon"
    >
      <path d="M28 9v5L16 26 4 14V9l12 12L28 9z" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className="svg-icon"
    >
      <path d="M7 4h5l12 12-12 12H7l12-12L7 4z" />
    </svg>
  );

  return <GenericIcons icon={theIcon} />;
};

export default ToggleChevron;
