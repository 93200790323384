import React from "react";
//@ts-ignore

import { FedLeg, StateLeg, memberTypes, LegislatorTypes } from "APTAPackage";
import { TrieValue } from "../../../../Types/Tries/TrieValue";
import { LegMapLeaf } from "APTAPackage/dist/Legislator/LegislatorMap";
import { useStore } from "../../../../Hooks/index";
import {
  sendGAEvent,
  sendGAEventPerson,
} from "../../../../Hooks/useGoogleAnalytics";

// //@ts-ignore
// import { updateViewState, setLoad } from "../actions/actions";

interface TrieSearchElemProps {
  person: FedLeg | StateLeg | LegMapLeaf;
}

function isLeaf(arg: any): arg is LegMapLeaf {
  return arg.members !== undefined;
}

const TrieSearchElem: React.FC<TrieSearchElemProps> = (props) => {
  const panelStore = useStore("panelStore");

  if (isLeaf(props.person)) return null;

  let person: FedLeg | StateLeg = props.person as FedLeg | StateLeg;

  let url = "";
  let displayName = "";
  if (Array.isArray(person)) person = person[0];
  const { fullName } = person;
  const { stateAbbrev, builtID, stateFull, type, hashID } = person.identityInfo;
  const district = person?.identityInfo?.district;
  let chamber = person?.identityInfo?.chamber as string;

  let tagname: string | null = null;

  switch (type) {
    case LegislatorTypes.STATE_SUM: {
      //      url = `/map/${stateAbbrev}/`;
      displayName = stateFull;
      tagname = stateFull;
      break;
    }
    case LegislatorTypes.SENATE: {
      //   url = `/map/${stateAbbrev}/senate/${builtID}`;
      displayName = `${fullName} - Senator - ${stateFull}`;
      tagname = fullName;
      break;
    }
    case LegislatorTypes.HOUSE: {
      //   url = `/map/${stateAbbrev}/congress/${builtID}`;
      displayName = `${fullName} - House Member - ${builtID}`;
      tagname = fullName;
      break;
    }
    case LegislatorTypes.SLDL: {
      //  url = `/map/${stateAbbrev}/${urlPaths.SLDL}/${builtID}/${fullName}`;
      displayName = `${fullName} - ${stateAbbrev} - ${chamber?.toUpperCase()} - ${district}`;
      tagname = fullName;
      break;
    }
    case LegislatorTypes.SLDU: {
      // url = `/map/${stateAbbrev}/${urlPaths.SLDU}/${builtID}/${fullName}`;
      displayName = `${fullName} - ${stateAbbrev} - ${chamber.toUpperCase()} - ${district}`;
      tagname = fullName;
      break;
    }
    default: {
      url = "";
      displayName = `????`;
    }
  }

  return (
    <li
      key={builtID}
      onClick={() => {
        sendGAEventPerson({
          eventName: "Search Results Click",
          eventLabel: "search_click",
          person,
        });

        panelStore.renderPerson(person, undefined);
      }}
    >
      <span>{displayName}</span>
    </li>
  );
};

export default TrieSearchElem;
