"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Grant_1 = require("./Grant");
class Grants {
    constructor(type, data) {
        const theGrants = [];
        data.forEach((grant) => {
            const theGrant = grant?.attributes;
            theGrants.push(new Grant_1.Grant(type, theGrant));
        });
        this.grants = theGrants;
    }
}
exports.default = Grants;
