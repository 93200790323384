import React from "react";
import { ChartConfiguration } from "chart.js";
import Graph from "./Graph";

export interface DoughnutProps {
  labels: string[];
  data: number[];
  colors: string[];
  chartElementId?: string;
  tooltip?: any;
}

const Doughnut: React.FC<DoughnutProps> = ({
  labels,
  data,
  colors,
  chartElementId,
  tooltip
}) => {
  const chartData: ChartConfiguration = {
    type: "doughnut",
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
          datalabels: {
            display: false,
          },
        },
      ],
    },
    options: {
      animation: false,

      animations: {
        colors: false,
        x: false,
      },
      transitions: { active: { animation: { duration: 0 } } },


      aspectRatio: 1,
      //@ts-ignore
      cutout: "60%",
      // responsive: true,
      maintainAspectRatio: true,
      layout: {
        padding: {
          left: 0,
          top: -20,
          bottom: 0,
          right: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip
      },
    },
  };

  return (
    <Graph chartData={chartData} isRF={true} chartElementId={chartElementId} />
  );
};

export default Doughnut;
