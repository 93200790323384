"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseEsriElem = exports.parseEsriString = exports.parseEsriNumber = void 0;
function parseEsriNumber(data) {
    if (typeof data === "number")
        return data;
    return null;
}
exports.parseEsriNumber = parseEsriNumber;
function parseEsriString(data) {
    if (typeof data === "string" && data !== "")
        return data;
    return null;
}
exports.parseEsriString = parseEsriString;
function parseEsriElem(data) {
    if (typeof data === "string" && data !== "")
        return data;
    if (typeof data === "number")
        return data;
    return null;
}
exports.parseEsriElem = parseEsriElem;
