import React from "react";
import { observer } from "mobx-react";
import PersonListElem from "./ListElems/Person";

import { FedLeg, StateLeg } from "APTAPackage";


// Component needs to be cleaned up after the refactor of general person.
interface PersonListProps {
  people: any[];
  elem?: any;
  sortList?: boolean;
}
const PersonList: React.FC<PersonListProps> = observer(
  ({ people, elem = null, sortList = true }) => {


    if (!!sortList) {
      people.sort((a, b) => {
        const compareValA =
          a?.lastName || a?.fullName?.slice(a?.fullName?.lastIndexOf(" ") + 1);
        const compareValB =
          b?.lastName || b?.fullName?.slice(b?.fullName?.lastIndexOf(" ") + 1);
        return compareValA.trim().localeCompare(compareValB.trim());
      });
    }

    let renderData: React.ReactElement[] = [];
    people.forEach((person: FedLeg | StateLeg | StateLeg[]) => {
      if (Array.isArray(person)) {
        person.forEach((elem: StateLeg) => {
          renderData.push(<PersonListElem person={elem} />);
        });
      } else {
        renderData.push(
          <PersonListElem
            key={person.identityInfo.hashID}
            person={person}
            district={true}
          />
        );
      }
    });

    return (
      <ul className="gm-person-list">
        {elem}
        {renderData}
      </ul>
    );
  }
);

export default PersonList;
