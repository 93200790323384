import { request } from "@esri/arcgis-rest-request";
import { useEffect, useState } from "react";

const APTA_MEMBERS =
  "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/netforum_members_2023/FeatureServer/259/query";
const RAIL_MEMBERS =
  "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/Rail_manu_2023/FeatureServer/311/query";
const BUS_MEMBERS =
  "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/Bus_manu_2023/FeatureServer/325/query";

const STATE_CORE =
  "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/State_Core_2024/FeatureServer/580/query";

type T_args = {
  url: string;
  state?: string;
  token: string;
  otherWhere?: string;
};
export const GET_sum = ({ url, token }: T_args) => {
  return new Promise<number>(async (resolve, reject) => {
    try {
      const membersCountRes = await request(url, {
        authentication: token,
        params: {
          f: "json",
          where: "1=1",
          returnCountOnly: true,
        },
      });

      const count = membersCountRes?.count;

    

      return resolve(membersCountRes?.count);
    } catch (error) {
      return reject(error);
    }
  });
};

export const GET_stat = ({ url, token }: T_args) => {
  return new Promise<number>(async (resolve, reject) => {
    try {
      const membersCountRes = await request(url, {
        authentication: token,
        params: {
          f: "json",
          // where: "NAME='Vermont'",
          where: "1=1",
          outStatistics: [
            {
              statisticType: "sum",
              onStatisticField: "sum_cares_funding",
              outStatisticFieldName: "CALC_sum_cares_funding",
            },
            {
              statisticType: "sum",
              onStatisticField: "sum_fast_funding",
              outStatisticFieldName: "CALC_sum_fast_funding",
            },
            {
              statisticType: "sum",
              onStatisticField: "sum_formula_funding",
              outStatisticFieldName: "CALC_sum_formula_funding",
            },
          ],
          outFields: "*",
        },
      });

      // const unparsedTHUD = data?.coreData?.sum_formula_funding;
      // const unparsedCARES = data?.coreData?.sum_cares_funding;
      // const unparsedFAST = data?.coreData?.sum_fast_funding;



      return resolve(membersCountRes?.features?.[0]?.attributes);
    } catch (error) {
      return reject(error);
    }
  });
};



type T_props = {
  token: string | null;
};

type T_res = {
  aptaMembers?: { APTAMembers?: any[] };
  busMembers?: { busMembers?: any[] };
  railMembers?: { railMembers?: any[] };
  coreData?: {
    sum_formula_funding?: number;
    sum_cares_funding?: number;
    sum_fast_funding?: number;
  };
};

const initRes: T_res = {};

export const useGetMembers = ({ token }: T_props) => {
  const [data, setData] = useState<T_res>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (token != null) {
      setIsLoading(true);

      const memberProm = GET_sum({
        url: APTA_MEMBERS,
        token,
      });

      const busProm = GET_sum({
        url: BUS_MEMBERS,
        token,
      });

      const railProm = GET_sum({
        url: RAIL_MEMBERS,
        token,
      });

      const statsProm = GET_stat({
        url: STATE_CORE,
        token,
      });

      const promArr = [memberProm, busProm, railProm, statsProm];

      Promise.all(promArr)
        .then((res) => {
          let memberArr: number[] = [];
          let busArr: number[] = [];
          let railArr: number[] = [];

          for (let i = 0; i < res[0]; i++) memberArr.push(1);
          for (let i = 0; i < res[1]; i++) busArr.push(1);
          for (let i = 0; i < res[2]; i++) railArr.push(1);

   

          const newItem: T_res = {
            aptaMembers: { APTAMembers: memberArr },
            railMembers: { railMembers: railArr },
            busMembers: { busMembers: busArr },
            coreData: {
              //@ts-ignore
              // sum_formula_funding: res[3]?.CALC_sum_formula_funding,
              sum_formula_funding: 108100000000,
               //@ts-ignore
              // sum_cares_funding: res[3]?.CALC_sum_cares_funding,
              sum_cares_funding: 69400000000,
               //@ts-ignore
              // sum_fast_funding: res[3]?.CALC_sum_fast_funding
              sum_fast_funding: 64200000000,
            },
          };

          const unparsedTHUD = data?.coreData?.sum_formula_funding;
          const unparsedCARES = data?.coreData?.sum_cares_funding;
          const unparsedFAST = data?.coreData?.sum_fast_funding;

          setData(newItem);
          setIsSuccess(true);
        })
        .then(() => {
          setIsLoading(false);
        })
        .catch((err: any) => {
          setError(err);
          setIsSuccess(false);
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [token]);

  return { data, isLoading, isError, error, isSuccess };
};
