import React, { useMemo } from "react";
import { Counties, CoreData } from "APTAPackage";
import { DataSummaryItem } from "./SummaryItem";

import { useHistory } from "react-router-dom";
import { useStore } from "../../../../Hooks";
import { convertNumber } from "../../../../components/Pages/Print/helpers";
import { SectionTitle } from "../../Components/SectionTitle";
import { T_dataComponent } from "../../../types";
import LoadingSpinner from "../../../../components/Pages/Icons/LoadingSpinner";

type T_props = {
  countyData: Counties;
  coreData: CoreData;
  dataLoading: boolean;
  type: T_dataComponent;
  dataView: "upper" | "lower" | "both";
};

const DataSummary: React.FC<T_props> = ({
  countyData,
  coreData,
  dataLoading,
  type,
  dataView,
}) => {
  const history = useHistory();
  const panelStore = useStore("panelStore");
  const pathName = history.location.pathname.toLowerCase();
  const crumbString = panelStore.crumbStrings;

  const state = coreData?.identityInfo?.stateAbbrev
    ? coreData.identityInfo.stateAbbrev
    : crumbString[1];

  const searchQuery = history.location.search;

  const searchObj = searchQuery
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}) as any;

  const hashId = searchObj?.hashId?.toLowerCase() as string;
  const splitHashId = hashId?.split("-");

  const seatName = useMemo(() => {
    let district =
      splitHashId &&
      `${splitHashId[1].toUpperCase()} - ${splitHashId[2]}`.replace(
        /%20/g,
        " "
      );

    const isHouse = pathName.includes("house") || hashId?.includes("house");

    const isUpperChamb = pathName.includes("sldu") || hashId?.includes("sldu");

    const isLowerChamb = pathName.includes("sldl") || hashId?.includes("sldl");

    const isFed = pathName.includes("senate") || hashId?.includes("senate");

    const upperFilter =
      isUpperChamb &&
      crumbString?.[3]?.slice(crumbString?.[3]?.lastIndexOf("-") + 1);
    const lowerFilter =
      isLowerChamb &&
      crumbString?.[3]?.slice(crumbString?.[3]?.lastIndexOf("-") + 1);

    const seatName = isFed
      ? "Statewide"
      : isHouse
      ? `Congressional District ${crumbString[2] || `${district}`}`
      : isUpperChamb
      ? `Upper Chamber ${upperFilter || district}`
      : isLowerChamb
      ? `Lower Chamber ${lowerFilter || district}`
      : "Statewide";

    return seatName;
  }, [pathName, crumbString]);

  const { columns, rows } = useMemo(() => {
    let counties = countyData?.counties || [];

    if (counties !== undefined) {
      counties = counties.slice(0, 6);
    }

    const columns = [
      { Key: "NAME", id: "NAME", name: `County`, width: 280 },
      {
        Key: "pop_commuting",
        id: "pop_commuting",
        name: "Commuters",
        width: 100,
      },
      {
        Key: "pop_public_trans",
        id: "pop_public_trans",
        name: "Transit Commuters",
        width: 190,
      },
      {
        Key: "med_income",
        id: "med_income",
        name: "Median Income",
        width: 180,
      },
      {
        Key: "no_vehicle_households",
        id: "no_vehicle_households",
        name: "No Vehicle Households",
        width: 180,
      },
      {
        Key: "traffic_vol_score",
        id: "traffic_vol_score",
        name: "Traffic Volume",
        width: 180,
      },
    ];

    const rows = counties?.map((obj, index) => {
      const pct_transit = obj?.pct_transit;
      const pop_public_trans = obj?.pop_public_trans;
      const pop_commuting = obj?.pop_commuting;
      const med_income = obj?.med_income;
      const no_vehicle_households = obj?.no_vehicle_households;

      const percent = pct_transit
        ? Math.round(pct_transit * 100) >= 1
          ? `${(pct_transit * 100).toFixed()}%`
          : "<1%"
        : "N/A";
      const publicTrans = pop_public_trans
        ? convertNumber(pop_public_trans)
        : "N/A";
      const commuters = pop_commuting ? convertNumber(pop_commuting) : "N/A";

      const med = pop_commuting ? convertNumber(med_income) : "N/A";
      const noV = pop_commuting ? convertNumber(no_vehicle_households) : "N/A";

      const newObj: { [key: string]: string } = {};
      newObj["key"] = index.toString();
      newObj["pct_transit"] = percent;
      newObj["pop_public_trans"] = publicTrans;
      newObj["pop_commuting"] = commuters;
      newObj["no_vehicle_households"] = noV;
      newObj["med_income"] = med;

      return { ...obj, ...newObj };
    });

    return { columns, rows };
  }, [countyData]);

  const getExploreTextClass = () => {
    switch (type) {
      case "POSTCARD":
        return "gm-explore-text-3-postcard";
      case "FULL_PAGE":
        return "gm-explore-text-3";
      case "WEBSITE":
        return "gm-explore-text-3";
    }
  };

  const getExploreNumericClass = () => {
    switch (type) {
      case "POSTCARD":
        return "gm-explore-numeric-4-postcard";
      case "FULL_PAGE":
        return "gm-explore-numeric-4";
      case "WEBSITE":
        return "gm-explore-numeric-4";
    }
  };

  return (
    <>
      <SectionTitle title="Data Summary" type={type}>
        {dataLoading ? (
          <LoadingSpinner color="var(--apta-navbar-text-color)" />
        ) : (
          <>
            {dataView === "upper" ||
              (dataView === "both" && (
                <div className="row pb-3">
                  <div className="col ">
                    <div className="row">
                      <div className="col gm-explore-text-2 pb-2">
                        <b>State:</b> {state}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col gm-explore-text-2 pb-2">
                        <b>Region:</b> {seatName}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm text-center">
                    <DataSummaryItem
                      title="Unlinked Passenger Trips"
                      ammount={coreData?.sum_passenger_trips}
                    />
                  </div>
                  <div className="col-sm text-center">
                    <DataSummaryItem
                      title="Passenger Miles Travelled"
                      ammount={coreData?.sum_passenger_miles}
                    />
                  </div>
                </div>
              ))}
            {rows.length === 0
              ? null
              : (dataView === "lower" || dataView === "both") && (
                  <div className="row">
                    <div className=" col-lg">
                      <table
                        className="table table-hover"
                        style={{ whiteSpace: "normal", wordBreak: "break-all" }}
                      >
                        <thead>
                          <tr>
                            {columns?.map((item, key) => (
                              <th
                                className={`p-2  ${getExploreTextClass()}`}
                                key={key}
                                style={{
                                  fontWeight: 600,
                                  fontSize: "1rem",
                                }}
                              >
                                {item.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            whiteSpace: "normal",
                            wordBreak: "break-all",
                          }}
                        >
                          {rows?.map((item, key) => {
                            const name = item?.NAME
                              ? item.NAME
                                ? item?.NAME10
                                : item.NAME10
                              : item.NAMELSAD10;

                            return (
                              <tr key={key}>
                                <th className={`p-2  ${getExploreTextClass()}`}>
                                  {name}
                                </th>
                                <td
                                  className={`p-2 ${getExploreNumericClass()}`}
                                >
                                  {item?.pop_commuting}
                                </td>
                                <td
                                  className={`p-2 ${getExploreNumericClass()}`}
                                >
                                  {item?.pop_public_trans}
                                </td>
                                <td
                                  className={`p-2 ${getExploreNumericClass()}`}
                                >
                                  {item?.med_income}
                                </td>
                                <td
                                  className={`p-2 ${getExploreNumericClass()}`}
                                >
                                  {item?.no_vehicle_households}
                                </td>
                                <td
                                  className={`p-2 ${getExploreTextClass()}`}
                                  style={{ color: "black" }}
                                >
                                  {item?.traffic_vol_score}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
          </>
        )}
      </SectionTitle>
    </>
  );
};

export default DataSummary;
