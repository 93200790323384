import React, { useEffect,  useRef } from "react";
import { observer } from "mobx-react";

import { PRINT_STYLES } from "../../Stores/CartStore/CartTypes";
import PostCardFront from "./Pages/PostCardFront";
import PostCardBack from "./Pages/PostCardBack";

import { usePreparePrint } from "../../Hooks/usePreparePrint";

const PrintPostCard: React.FC = () => {
  const mainRef = useRef(null);

  const { data, error } = usePreparePrint();
  const url = new URL(window.location.href);
  const isPreview = !!url.searchParams.get("preview");

  useEffect(() => {
    if (data != null) {
      setTimeout(() => {
        if (data != null && mainRef.current != null) {
          //@ts-ignore
          mainRef.current.setAttribute("id", "DONE");
        }
      }, 10000);
    }
  }, [data]);

  if (error != null) return <div id="ERROR"> ERROR </div>;
  if (data == null) return null;

  const printType: PRINT_STYLES = "postcard";
  const memberData = data?.memberData;



  return (
    <div
      ref={mainRef}
      className={isPreview ? "p-5 m-auto" : ""}
      style={{ overflow: "scroll" }}
    >
      <PostCardFront memberData={memberData} isPreview={isPreview} />
      {isPreview && <br />}
      <PostCardBack memberData={memberData} isPreview={isPreview} />
    </div>
  );
};

export default observer(PrintPostCard);
