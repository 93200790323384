"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZIP_STATUS_TYPES = exports.PRINT_JOB_STATUS_TYPES = exports.PRINT_STATUS_TYPES = exports.stateAbbrev = exports.stateAbbrevEnum = exports.stateAbbrevList = exports.stateAbbrevToFull = exports.parties = exports.partiesEnum = exports.convertMemberType = exports.convertObj = exports.memberTypes = exports.LegislatorTypes = exports.MESSAGE_TYPES = exports.PartyEnum = exports.PersonTypes = exports.parsePString = void 0;
function parsePString(theString) {
    if (theString === undefined)
        return null;
    else if (theString === "")
        return null;
    else
        return theString;
}
exports.parsePString = parsePString;
var PersonTypes;
(function (PersonTypes) {
    PersonTypes["STATE_LEG"] = "STATE_LEG";
    PersonTypes["FED_LEG"] = "FED_LEG";
})(PersonTypes = exports.PersonTypes || (exports.PersonTypes = {}));
var PartyEnum;
(function (PartyEnum) {
    PartyEnum["Democrat"] = "Democrat";
    PartyEnum["Republican"] = "Republican";
    PartyEnum["D"] = "Democrat";
    PartyEnum["R"] = "Republican";
})(PartyEnum = exports.PartyEnum || (exports.PartyEnum = {}));
var MESSAGE_TYPES;
(function (MESSAGE_TYPES) {
    MESSAGE_TYPES["CACHE"] = "CACHE";
    MESSAGE_TYPES["PRINT"] = "PRINT";
})(MESSAGE_TYPES = exports.MESSAGE_TYPES || (exports.MESSAGE_TYPES = {}));
var LegislatorTypes;
(function (LegislatorTypes) {
    LegislatorTypes["STATE_SUM"] = "STATE_SUM";
    LegislatorTypes["SENATE"] = "SENATE";
    LegislatorTypes["HOUSE"] = "HOUSE";
    LegislatorTypes["SLDU"] = "SLDU";
    LegislatorTypes["SLDL"] = "SLDL";
})(LegislatorTypes = exports.LegislatorTypes || (exports.LegislatorTypes = {}));
var memberTypes;
(function (memberTypes) {
    memberTypes["GEO_STATE"] = "GEO_STATE";
    memberTypes["STATE"] = "States";
    memberTypes["CD"] = "116th Congressional Districts";
    memberTypes["SLDU"] = "State Legislative District Upper Chamber";
    memberTypes["SLDL"] = "State Legislative District Lower Chamber";
})(memberTypes = exports.memberTypes || (exports.memberTypes = {}));
exports.convertObj = {
    STATE: LegislatorTypes.SENATE,
    CD: LegislatorTypes.HOUSE,
    SLDU: LegislatorTypes.SLDU,
    SLDL: LegislatorTypes.SLDL,
};
const convertMemberType = (type) => {
    if (type === memberTypes.STATE)
        return LegislatorTypes.SENATE;
    else if (type === memberTypes.CD)
        return LegislatorTypes.HOUSE;
    else if (type === memberTypes.SLDU)
        return LegislatorTypes.SLDU;
    else
        return LegislatorTypes.SLDL;
};
exports.convertMemberType = convertMemberType;
var partiesEnum;
(function (partiesEnum) {
    partiesEnum["Republican"] = "Republican";
    partiesEnum["Democrat"] = "Democrat";
    partiesEnum["Other"] = "Other";
})(partiesEnum = exports.partiesEnum || (exports.partiesEnum = {}));
exports.parties = {
    Republican: "Republican",
    Democrat: "Democrat",
    Other: "Other",
};
exports.stateAbbrevToFull = {
    PR: "Puerto Rico",
    AS: "American Samoa",
    GU: "Guam",
    VI: "Virgin Islands",
    MP: "Northern Mariana Islands",
    AZ: "Arizona",
    AL: "Alabama",
    AK: "Alaska",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DC: "District of Columbia",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
};
exports.stateAbbrevList = Object.keys(exports.stateAbbrevToFull);
var stateAbbrevEnum;
(function (stateAbbrevEnum) {
    stateAbbrevEnum["PR"] = "PR";
    stateAbbrevEnum["GU"] = "GU";
    stateAbbrevEnum["VI"] = "VI";
    stateAbbrevEnum["MP"] = "MP";
    stateAbbrevEnum["AZ"] = "AZ";
    stateAbbrevEnum["AL"] = "AL";
    stateAbbrevEnum["AK"] = "AK";
    stateAbbrevEnum["AR"] = "AR";
    stateAbbrevEnum["CA"] = "CA";
    stateAbbrevEnum["CO"] = "CO";
    stateAbbrevEnum["CT"] = "CT";
    stateAbbrevEnum["DC"] = "DC";
    stateAbbrevEnum["DE"] = "DE";
    stateAbbrevEnum["FL"] = "FL";
    stateAbbrevEnum["GA"] = "GA";
    stateAbbrevEnum["HI"] = "HI";
    stateAbbrevEnum["ID"] = "ID";
    stateAbbrevEnum["IL"] = "IL";
    stateAbbrevEnum["IN"] = "IN";
    stateAbbrevEnum["IA"] = "IA";
    stateAbbrevEnum["KS"] = "KS";
    stateAbbrevEnum["KY"] = "KY";
    stateAbbrevEnum["LA"] = "LA";
    stateAbbrevEnum["ME"] = "ME";
    stateAbbrevEnum["MD"] = "MD";
    stateAbbrevEnum["MA"] = "MA";
    stateAbbrevEnum["MI"] = "MI";
    stateAbbrevEnum["MN"] = "MN";
    stateAbbrevEnum["MS"] = "MS";
    stateAbbrevEnum["MO"] = "MO";
    stateAbbrevEnum["MT"] = "MT";
    stateAbbrevEnum["NE"] = "NE";
    stateAbbrevEnum["NV"] = "NV";
    stateAbbrevEnum["NH"] = "NH";
    stateAbbrevEnum["NJ"] = "NJ";
    stateAbbrevEnum["NM"] = "NM";
    stateAbbrevEnum["NY"] = "NY";
    stateAbbrevEnum["NC"] = "NC";
    stateAbbrevEnum["ND"] = "ND";
    stateAbbrevEnum["OH"] = "OH";
    stateAbbrevEnum["OK"] = "OK";
    stateAbbrevEnum["OR"] = "OR";
    stateAbbrevEnum["PA"] = "PA";
    stateAbbrevEnum["RI"] = "RI";
    stateAbbrevEnum["SC"] = "SC";
    stateAbbrevEnum["SD"] = "SD";
    stateAbbrevEnum["TN"] = "TN";
    stateAbbrevEnum["TX"] = "TX";
    stateAbbrevEnum["UT"] = "UT";
    stateAbbrevEnum["VT"] = "VT";
    stateAbbrevEnum["VA"] = "VA";
    stateAbbrevEnum["WA"] = "WA";
    stateAbbrevEnum["WV"] = "WV";
    stateAbbrevEnum["WI"] = "WI";
    stateAbbrevEnum["WY"] = "WY";
})(stateAbbrevEnum = exports.stateAbbrevEnum || (exports.stateAbbrevEnum = {}));
exports.stateAbbrev = {
    PR: "PR",
    GU: "GU",
    VI: "VI",
    MP: "MP",
    AZ: "AZ",
    AL: "AL",
    AK: "AK",
    AR: "AR",
    CA: "CA",
    CO: "CO",
    CT: "CT",
    DC: "DC",
    DE: "DE",
    FL: "FL",
    GA: "GA",
    HI: "HI",
    ID: "ID",
    IL: "IL",
    IN: "IN",
    IA: "IA",
    KS: "KS",
    KY: "KY",
    LA: "LA",
    ME: "ME",
    MD: "MD",
    MA: "MA",
    MI: "MI",
    MN: "MN",
    MS: "MS",
    MO: "MO",
    MT: "MT",
    NE: "NE",
    NV: "NV",
    NH: "NH",
    NJ: "NJ",
    NM: "NM",
    NY: "NY",
    NC: "NC",
    ND: "ND",
    OH: "OH",
    OK: "OK",
    OR: "OR",
    PA: "PA",
    RI: "RI",
    SC: "SC",
    SD: "SD",
    TN: "TN",
    TX: "TX",
    UT: "UT",
    VT: "VT",
    VA: "VA",
    WA: "WA",
    WV: "WV",
    WI: "WI",
    WY: "WY",
};
var PRINT_STATUS_TYPES;
(function (PRINT_STATUS_TYPES) {
    PRINT_STATUS_TYPES["PRINT_LOADING"] = "PRINT_LOADING";
    PRINT_STATUS_TYPES["PRINT_ERROR"] = "PRINT_ERROR";
    PRINT_STATUS_TYPES["PRINT_SUCCESS"] = "PRINT_SUCCESS";
    PRINT_STATUS_TYPES["PRINT_INIT"] = "PRINT_INIT";
})(PRINT_STATUS_TYPES = exports.PRINT_STATUS_TYPES || (exports.PRINT_STATUS_TYPES = {}));
var PRINT_JOB_STATUS_TYPES;
(function (PRINT_JOB_STATUS_TYPES) {
    PRINT_JOB_STATUS_TYPES["PRINT_JOB_INIT"] = "PRINT_JOB_INIT";
    PRINT_JOB_STATUS_TYPES["PRINT_JOB_COMPLETE"] = "PRINT_JOB_COMPLETE";
    PRINT_JOB_STATUS_TYPES["PRINT_JOB_LOADING"] = "PRINT_JOB_LOADING";
    PRINT_JOB_STATUS_TYPES["PRINT_JOB_ERROR"] = "PRINT_JOB_ERROR";
})(PRINT_JOB_STATUS_TYPES = exports.PRINT_JOB_STATUS_TYPES || (exports.PRINT_JOB_STATUS_TYPES = {}));
var ZIP_STATUS_TYPES;
(function (ZIP_STATUS_TYPES) {
    ZIP_STATUS_TYPES["ZIP_INIT"] = "ZIP_INIT";
    ZIP_STATUS_TYPES["ZIP_COMPLETE"] = "ZIP_COMPLETE";
    ZIP_STATUS_TYPES["ZIP_LOADING"] = "ZIP_LOADING";
    ZIP_STATUS_TYPES["ZIP_ERROR"] = "ZIP_ERROR";
})(ZIP_STATUS_TYPES = exports.ZIP_STATUS_TYPES || (exports.ZIP_STATUS_TYPES = {}));
