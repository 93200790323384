import React, { useMemo } from "react";
import { CoreData, T_transitStats } from "APTAPackage";

import { convertNumber } from "../../../components/Pages/Print/helpers";
import { SectionTitle } from "../Components/SectionTitle";
import BarGraph from "../../Postcard/SectionComponents/Graphs/BarGraph";
import { graphColors } from "../Config";
import { T_dataComponent } from "../../types";
import NoData from "../Components/NoData";
import LoadingSpinner from "../../../components/Icons/LoadingSpinner";

interface IProps {
  data: T_transitStats;
  dataLoading?: boolean;
  type: T_dataComponent;
}

const callback = (value: any, index: any, values: any) => {
  return convertNumber(value);
};

function formatNumbers(n: number | string) {
  if (typeof n === "string") {
    return n;
  } else {
    return String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");
  }
}

const TransportationFootprint: React.FC<IProps> = ({
  data,
  dataLoading,
  type,
}) => {
  // const { labels, values } = useMemo(() => {
  //   const bus = data?.SUM_Total_Active_Fleet_bus || "N/A";
  //   const rail = data?.SUM_Total_Active_Fleet_rail || "N/A";
  //   const ferry = data?.SUM_Total_Active_Fleet_ferry || "N/A";

  //   let labels = [
  //     ["Bus", formatNumbers(bus)],
  //     ["Rail", formatNumbers(rail)],
  //     ["Ferry", formatNumbers(ferry)],
  //   ];

  //   let values = [
  //     data?.SUM_Total_Active_Fleet_bus as number,
  //     data?.SUM_Total_Active_Fleet_rail as number,
  //     data?.SUM_Total_Active_Fleet_ferry as number,
  //   ];
  //   return { labels, values };
  // }, [data]);  // const auto = data?.SUM_Total_Active_Fleet_auto || "N/A";
  const bus = data?.CALC_SUM_Total_Active_Fleet_bus || "N/A";
  const rail = data?.CALC_SUM_Total_Active_Fleet_rail || "N/A";
  const ferry = data?.CALC_SUM_Total_Active_Fleet_ferry || "N/A";

  // if (!auto && !bus && !rail && !ferry) return null;

  let labels = [
    // ["Auto", formatNumbers(auto)],
    ["Bus", formatNumbers(bus)],
    ["Rail", formatNumbers(rail)],
    ["Ferry", formatNumbers(ferry)],
  ];

  let values = [
    // data?.SUM_Total_Active_Fleet_auto as number,
    data?.CALC_SUM_Total_Active_Fleet_bus as number,
    data?.CALC_SUM_Total_Active_Fleet_rail as number,
    data?.CALC_SUM_Total_Active_Fleet_ferry as number,
  ];

  // ~~~~~ Derived Data
  const isData = !!values?.[0] || !!values?.[1] || !!values?.[2];

  return (
    <SectionTitle
      type={type}
      title="Transportation Footprint"
      subTitle={
        !isData ? "" : "Vehicles and Assets at Agencies Serving this Area"
      }
    >
      {dataLoading ? (
        <LoadingSpinner color="var(--apta-navbar-text-color)" />
      ) : !isData ? (
        <NoData />
      ) : (
        <div className="my-auto" style={{ maxWidth: "100%" }}>
          <BarGraph
            data={values}
            labels={labels}
            colors={graphColors}
            yAxesCallback={callback}
            aspectRatio={type === "POSTCARD" ? 2.1 : 1.4}
            chartElementId="TransportationFootprint"
          />
        </div>
      )}
    </SectionTitle>
  );
};

export default TransportationFootprint;
