import React from "react";
import AuthStore from "./newAuthStore/AuthStore";
import DataStore from "./DataStore";
import SearchStore from "./SearchStore";
import PanelStore from "./PanelStore";
import NewCartStore from "./newCartStore.ts/newCartStore";

const dataStore = new DataStore();
const authStore = new AuthStore();

export const stores = Object.freeze({
  authStore,
  dataStore,
  newCartStore: new NewCartStore(authStore),
  panelStore: new PanelStore(dataStore, authStore),
  searchStore: new SearchStore(dataStore, authStore),
});

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;
