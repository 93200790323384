import React, { useEffect } from "react";
import { observer } from "mobx-react";
import SidePanel from "./Panels/SidePanel";
import WebMapComponent from "./WebMap";
import { useStore } from "../../../Hooks";
import Explore from "../../../Features/Explore";

const MapPage: React.FC = observer((props: any) => {
  const panelStore = useStore("panelStore");
  const dataStore = useStore("dataStore");

  useEffect(() => {
    let isMap = window.location.pathname.slice(0, 100) === "/map";

    if (panelStore.activePerson && isMap)
      panelStore.renderPerson(panelStore.activePerson, undefined);
  }, []);

  return (
    <div className="App">
      {dataStore.loaded ? (
        <div id="mapContainer">
          <SidePanel store={props.store} />
          <WebMapComponent />
        </div>
      ) : (
        <div id="mapContainer">
          <div className="gm-map-section"></div>
        </div>
      )}
      <Explore key={panelStore.activePerson?.API_ID} />
    </div>
  );
});

export default MapPage;
