import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../Hooks";
import BaseFormComponent from "../../FormComponent/BaseFormComponent";

const Settings = () => {
  const authStore = useStore("authStore");
  const history = useHistory();

  if (!!!authStore.getUserStorage() || !!!authStore?.userInfo) {
    history.push("/home");
    return null;
  }


  if (!authStore.userInfo) return null;
  const { Individual, Email, Business_Address } = authStore?.userInfo;
  if (!!!Individual || !!!Email || !!!Business_Address) {
    history.push("/home");
    return null;
  }
  const SettingsPageForm = {
    sections: [
      {
        title: "Account Info",
        centerText: true,
        fields: [
          {
            label: "First Name",
            formName: "firstName",
            colSize: 4,
            centerCol: false,
            newLine: false,
            disabled: true,
            defaultValue: Individual.ind_first_name,
          },
          {
            label: "Last Name",
            formName: "lastName",
            colSize: 4,
            centerCol: false,
            //must have current lineSize for when newline is true
            newLine: true,
            disabled: true,
            defaultValue: Individual.ind_last_name,
          },
          {
            label: "Email",
            defaultValue: Email.eml_address,
            formName: "email",
            colSize: 4,
            centerCol: false,
            newLine: true,
            disabled: true,
          },

          {
            label: "Business Location",
            formName: "location",
            defaultValue: `${Business_Address.adr_city}, ${Business_Address.adr_state}, ${Business_Address.adr_country}`,
            colSize: "4",
            centerCol: false,
            lineSize: 4,

            newLine: true,
            disabled: true,
          },
        ],
      },
    ],
  };

  return (
    <div className="container">
      {authStore.userInfo && (
        <BaseFormComponent
          hasSubmitButton={false}
          formJson={SettingsPageForm}
        />
      )}
    </div>
  );
};

export default Settings;
