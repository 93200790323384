export const busTiers = {
  id: "busSupplierTiers",
  title: "Bus Supplier Tiers",
  items: [
    {
      label: "Parts and Materials Inputs",
      percentage: "48%",
      color: "#FF7E6B",
      value: 532,
    },
    {
      label: "Major Supplier",
      percentage: "38%",
      color: "#fdcc8a",
      value: 431,
    },
    {
      label: "Infrastructure*",
      percentage: "11%",
      color: "#fc8d59",
      value: 120,
    },
    { label: "Assembler/OEM", percentage: "3%", color: "#d7301f", value: 39 },
  ],
};

export const busSectors = {
  id: "busSupplierSectors",
  title: "Bus Supplier Sectors",
  items: [
    {
      label: "Metro Bus",
      percentage: "30%",
      color: "#86BBD8",
      value: 559,
    },
    {
      label: "Coach",
      percentage: "23%",
      color: "#367db2",
      value: 421,
    },
    {
      label: "Electric bus",
      percentage: "17%",
      color: "#005391",
      value: 300,
    },
    { label: "Shuttle", percentage: "15%", color: "#2392e6", value: 268 },
    { label: "Paratransit", percentage: "15%", color: "#33658A", value: 266 },
  ],
};

export const railTiers = {
  id: "railSupplierTiers",
  title: "Rail Supplier Tiers",
  items: [
    {
      label: "Parts and Materials Inputs",
      percentage: "45%",
      color: "#19ea00",
      value: 597,
    },
    {
      label: "Major Supplier",
      percentage: "25%",
      color: "#24ae14",
      value: 331,
    },
    {
      label: "Infrastructure*",
      percentage: "29%",
      color: "#159106",
      value: 385,
    },
    { label: "Assembler/OEM", percentage: "1%", color: "#34fe1b", value: 21 },
  ],
};

export const railSectors = {
  id: "railSupplierSectors",
  title: "Rail Supplier Sectors",
  items: [
    {
      label: "Intercity Passenger",
      percentage: "19%",
      color: "#cd3f3e",
      value: 347,
    },
    {
      label: "Regional/Commuter",
      percentage: "23%",
      color: "#ff9c91",
      value: 411,
    },
    {
      label: "Metro",
      percentage: "21%",
      color: "#ca3e47",
      value: 375,
    },
    { label: "Light", percentage: "21%", color: "#c10000", value: 378 },
    {
      label: "Streetcar/Trolley/Tram",
      percentage: "18%",
      color: "#d65a31",
      value: 276,
    },
  ],
};

export const aptaMembersGraph = {
  id: "aptaMembersGraph",
  toolLabel: "Total Member Locations",
  items: [
    { label: "Business", color: "#36a2eb", value: 2320 },
    { label: "Transit", color: "#0661a0", value: 667 },
    { label: ["Government", "& Other"], color: "#000000", value: 268 },
  ],
};

export const transitSystemGraph = {
  id: "transitSystemGraph",
  toolLabel: "Number of Systems Offering a Mode of Service",
  items: [
    { label: "Rail", color: "#7a003b", value: 92 },
    { label: "Bus", color: "#c90363", value: 1223 },
    { label: ["Demand", " Response"], color: "#7b0667", value: 1817 },
    { label: "Other", color: "#000000", value: 169 },
  ],
};

export const fundingLineGraph = {
  id: "fundingLineGraph",
  toolLabel: "Total Funding For Public Transit",
  backgroundColor: "#f47b43",
  fill: true,
  items: [
    { label: "1988", value: 38.8 },
    { label: "1989", value: 38.4 },
    { label: "1990", value: 40.4 },
    { label: "1991", value: 40.7 },
    { label: "1992", value: 40.5 },
    { label: "1993", value: 40.9 },
    { label: "1994", value: 41.2 },
    { label: "1995", value: 43.1 },
    { label: "1996", value: 43.2 },
    { label: "1997", value: 44.1 },
    { label: "1998", value: 46.1 },
    { label: "1999", value: 48.6 },
    { label: "2000", value: 51.0 },
    { label: "2001", value: 53.8 },
    { label: "2002", value: 57.0 },
    { label: "2003", value: 58.2 },
    { label: "2004", value: 59.0 },
    { label: "2005", value: 58.6 },
    { label: "2006", value: 60.6 },
    { label: "2007", value: 62.4 },
    { label: "2008", value: 66.8 },
    { label: "2009", value: 69.1 },
    { label: "2010", value: 67.7 },
    { label: "2011", value: 67.1 },
    { label: "2012", value: 69.3 },
    { label: "2013", value: 70.9 },
    { label: "2014", value: 72.5 },
    { label: "2015", value: 74.7 },
    { label: "2016", value: 76.2 },
    { label: "2017", value: 75.2 },
    { label: "2018", value: 76.5 },
    { label: "2019", value: 80.7 },
    { label: "2020", value: 79.1 },
  ],
};

export const config = {
  aptaFundingLine: {
    labels: [
      "1988",
      "1989",
      "1990",
      "1991",
      "1992",
      "1993",
      "1994",
      "1995",
      "1996",
      "1997",
      "1998",
      "1999",
      "2000",
      "2001",
      "2002",
      "2003",
      "2004",
      "2005",
      "2006",
      "2007",
      "2008",
      "2009",
      "2010",
      "2011",
      "2012",
      "2013",
      "2014",
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
    ],
    datasets: [
      {
        label: "Total Funding For Public Transit",
        data: [
          38.8, 38.4, 40.4, 40.7, 40.5, 40.9, 41.2, 43.1, 43.2, 44.1, 46.1,
          48.6, 51.0, 53.8, 57.0, 58.2, 59.0, 58.6, 60.6, 62.4, 66.8, 69.1,
          67.7, 67.1, 69.3, 70.9, 72.5, 74.7, 76.2, 75.2, 76.5, 80.7, 79.1,
        ],
        backgroundColor: "#f47b43",
        fill: true,
      },
    ],
  },
  aptaRailMoneyBreakDownDoughnut: {
    labels: [
      "Guideway",
      "Rolling Stock(Passenger & Service Vehicle) Expenditures",
      "other",
      "Communication & Information Systems",
      "Fare Revenue Collection Equipment",
      "Maintenance Facilities",
      "Administrative Buildings",
      "Passenger Stations",
    ],
    datasets: [
      {
        label: "Bus Details",
        data: [36, 28, 4, 9, 1, 7, 1, 14],
        backgroundColor: [
          "#4B0082",
          "#800080",
          "#8B008B",
          "#9932CC",
          "#9400D3",
          "#8A2BE2",
          "#9370DB",
          "#BA55D3",
        ],
      },
    ],
  },
  aptaFunding: {
    labels: [
      "Operating: Vehicle Operations",
      "Operating: Vehicle Maintenance",
      "Operating: Non-Vehicle Maintenance",
      "Operating: General Administration",
      "Operating: Purchased Transportation",
      "Capital: Rolling Stock",
      "Capital: Facilities",
      "Capital: Other",
    ],
    labelPercents: ["28%", "11%", "7%", "11%", "10%", "8%", "20%", "4%"],
    toolLabel: "Bus Details",
    data: [21018.7, 7921.3, 5520.1, 8428.2, 7644.4, 5667.7, 15169.6, 2880.3],
    colors: [
      "#4B0082",
      "#800080",
      "#8B008B",
      "#9932CC",
      "#9400D3",
      "#8A2BE2",
      "#9370DB",
      "#BA55D3",
      "#952dae",
    ],
  },

  transitTrips: {
    labels: [
      "Bus, BRT, Trolleybus",
      "Heavy Rail",
      "Commuter & Hybrid Rail",
      "Light Rail & Streetcar",
      "Other",
      "Demand Response",
      "Commuter Bus",
    ],
    labelPercents: ["55%", "30%", "4%", "6%", "2%", "2%", "1%"],
    data: [
      4689000000, 3724000000, 512000000, 543000000, 185000000, 204000000,
      95000000,
    ],
    colors: [
      "#008080",
      "#5F9EA0",
      "#00CED1",
      "#40E0D0",
      "#66CDAA",
      "#00FFFF",
      "#669999",
    ],
  },
  transitMiles: {
    labels: [
      "Bus, BRT, Trollybus",
      "Heavy Rail",
      "Commuter & Hybrid Rail",
      "Light Rail & Streetcar",
      "Other",
      "Demand Response",
      "Commuter Bus",
    ],

    labelPercents: ["38%", "28%", "20%", "6%", "5%", "4%", "3%"],
    title: "Rail Modes Carry Passengers for More Miles",
    toolLabel: "Rail Modes Carry Passengers for More Miles",
    data: [
      17279000000, 16914000000, 12905000000, 2645000000, 2281000000, 1948000000, 1821000000,
      
    ],
    colors: [
      "#A0522D",
      "#D2691E",
      "#CD853F",
      "#FFA500",
      "#FFD700",
      "#F0E68C",
      "#f0cd8c",
    ],
  },
};
