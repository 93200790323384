"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Agency_1 = require("./Agency");
class Agencies {
    constructor(type, data) {
        const theGrants = [];
        data.forEach((county) => {
            const theCounty = county?.attributes;
            theGrants.push(new Agency_1.Agency(type, theCounty));
        });
        this.counties = theGrants;
    }
}
exports.default = Agencies;
