import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { openSignInWindow } from "./Popup";
import { useStore } from "../../../Hooks";
import BaseFormComponent from "../../FormComponent/BaseFormComponent";
import axios from "axios";
import { Router, useHistory } from "react-router-dom";
import APTALogo from "../../../../public/images/apta-logo-200-2022.png";

// type T_error = <ReturnType<typeof Error>;>

const LogIn = () => {
  const authStore = useStore("authStore");
  const history = useHistory();
  const [error, setError]: any = useState(null);

  const onSubmitFunc = async (credentials: any) => {
    try {
      const res = await authStore.signIn(credentials);
      if (authStore.isAuth) {
        history.push("/home");
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setError(error.message);
      }
    }
  };

  return (
    <div className="container">
      {authStore.isAuth ? (
        <div className="row">
          <div className={`col pt-2 text-center`}>
            <h2>You are Currently Logged In.</h2>
          </div>
        </div>
      ) : (
        <>
          <BaseFormComponent
            hasSubmitButton={true}
            onSubmitFunc={onSubmitFunc}
            formJson={LoginBaseForm()}
            error={error}
          />
          <div className="row">
            <div className="col text-center">
              <a
                className="gm-blue-text"
                target="_blank"
                href="https://www.aptagateway.com/eweb/DynamicPage.aspx?WebCode=forgotpassword&Site=MyAPTA"
              >
                Lost Your Password?
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <a
                className="gm-blue-text"
                target="_blank"
                href="https://www.aptagateway.com/eweb/DynamicPage.aspx?WebCode=APTAWebRegister"
              >
                Create New Account
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const LoginBaseForm = (error?: string) => {
  return {
    submitButton: {
      hasSubmitButton: true,
      floatLocation: "right",
    },
    sections: [
      {
        title: "MyAPTA  Login",
        titleIcon: APTALogo,
        para: "Please enter your email address and MyAPTA password below. If you have not registered for MyAPTA access, please create a free account below to register for the site.",
        fields: [
          {
            label: "Email Address",
            errorLabel: "Email",
            dataType: "email",
            formName: "email",
            colSize: 4,
            centerCol: true,
            newLine: true,
            validation: {
              required: "Email is Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            },
          },
          {
            label: "Password",
            centerCol: true,
            errorLabel: "Password",
            dataType: "password",
            formName: "password",
            colSize: 4,
            newLine: true,
            validation: {
              required: "Password is Required",
            },
          },
        ],
      },
    ],
  };
};

export default LogIn;
