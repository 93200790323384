import React from "react";

interface GenericIconProps {
  icon: React.ReactNode;
  classMod?: string;
  children?: React.ReactNode;
  onClick?: any;
}

const GenericIcon: React.FC<GenericIconProps> = ({
  icon,
  classMod = "",
  children,
  onClick,
}) => {
  return (
    <div className={`${classMod} h-100`} onClick={onClick}>
      {icon} {children}{" "}
    </div>
  );
};

export default GenericIcon;
