import React, { useRef, useMemo, useLayoutEffect } from "react";
import { Chart, registerables } from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import "./BarGraph.css";

export const aptaMembersGraph = {
  id: "aptaMembersGraph",
  toolLabel: "Total Member Locations",
  items: [
    { label: "Business", color: "#36a2eb", value: 2320 },
    { label: "Transit", color: "#0661a0", value: 667 },
    { label: ["Government", "& Other"], color: "#000000", value: 268 },
  ],
};

type T_barGraph = {
  id: string;
  title?: string;
  toolLabel?: string;
  items: T_barGraphItem[];
};

type T_barGraphItem = {
  label: string | string[];
  color: string;
  value: number;
};

type T_props = T_barGraph;

export const BarGraphRefactor: React.FC<T_props> = ({
  id,
  title,
  toolLabel = null,
  items,
}) => {
  const canvasRef = useRef<any | null>(null);
  const graphRef = useRef<Chart | null>(null);

  Chart.register(...registerables);
  // Chart.register(...registerables, ChartDataLabels);

  const parsedData = useMemo(() => {
    const data: number[] = [];
    const colors: string[] = [];
    const labels: (string | string[])[] = [];

    let newItems = [...items];

    newItems.sort((a, b) => {
      if (a.value > b.value) return -1;
      else if (a.value < b.value) return 1;
      return 0;
    });

    newItems.forEach((item) => {
      data.push(item.value);
      colors.push(item.color);
      labels.push(item.label);
    });

    return {
      data,
      colors,
      labels,
      sortedItems: newItems,
    };
  }, [items]);

  const { data, colors, labels, sortedItems } = parsedData;

  useLayoutEffect(() => {
    if (graphRef.current !== null) {
      graphRef.current.destroy();
    }

    let myChartRef;
    if (canvasRef.current) {
      myChartRef = canvasRef.current.getContext("2d");
    }

    graphRef.current = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels,
        datasets: [
          {
            label: toolLabel ? toolLabel : "",
            data,
            backgroundColor: colors,
            datalabels: { display: false },
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          //@ts-ignore
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontFamily:
                    '"Avenir Next W01","Avenir Next W00","Avenir Next","Avenir","Helvetica Neue","sans-serif"',

                  //@ts-ignore
                  userCallback: function (value, index, values) {
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(",");
                    return value;
                  },
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontFamily:
                    '"Avenir Next W01","Avenir Next W00","Avenir Next","Avenir","Helvetica Neue","sans-serif"',
                  fontSize: 15,
                  //@ts-ignore
                  userCallback: function (value, index, values) {
                    let obj: any = {};
                    obj.value = value;
                    obj.fontSize = 100;

                    return value;
                  },
                },
              },
            ],
          },
        },
      },
    });
    return () => {
      if (graphRef.current !== null) {
        graphRef.current.destroy();
      }
    }
  }, [sortedItems]);

  return <canvas ref={canvasRef} id={id} />;
};
