import React, { useEffect} from "react";

// A Stub Route that improves the authentication experience.
const RedirectRoute = () => {

  // Sends the Query String and closes the window. 
  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage(window.location.search, '*');
      window.close();
    }
  });

  return (
    <div className="grid-container">
      ...Loading
    </div>
  );
};

export default RedirectRoute;
