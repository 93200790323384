import React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../Hooks";
import { T_printType } from "../../../../Stores/newCartStore.ts/newCartStore";
import "./FormatCard.css";

export type T_format = {
  type: T_printType;
  title: string;
  paperType: string;
  dimensions: string;
};

type T_props = T_format;

const FormatCard: React.FC<T_props> = ({
  type,
  title,
  paperType,
  dimensions,
}) => {
  const cartStore = useStore("newCartStore");
  const printType = cartStore.printType;

  return (
    <div
      className={`card ${
        printType === type ? "gm-format-card-active" : ""
      } ml-1 mr-1`}
      onClick={() => cartStore.setPrintType(type)}
    >
      <div className="card-body gm-format-card-body">
        <span> {paperType} </span>
        <span> {title} </span>

        <span> {dimensions} </span>
      </div>
    </div>
  );
};

export default observer(FormatCard);
