import React, {  useRef, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { useStore } from "../../Hooks";

// @ts-ignore
import OutsideAlerter from "./OutsideAlerter";

//@ts-ignore
import APTALogo from "../../Images/apta_logo_with_text_2022.png";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import {
  facebookQuote,
  redditQuote,
  twitterTitle,
  twitterVia,
} from "../../constants/socialConfig";
import { sendGAEvent } from "../../Hooks/useGoogleAnalytics";

function StickyHeader() {
  const { signOut, isAuth,  loadingAuth } = useStore("authStore");
  const [link, setLink] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const linkRef = useRef(null);
  const buttonRef = useRef(null);




  //@ts-ignore
  function copyToClipboard(e) {
    //@ts-ignore
    linkRef.current.select();
    document.execCommand("copy");
    setCopySuccess("Copied!");
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <a className="navbar-brand" href="/home">
        <img src={APTALogo} alt="notWorking" className="apta-logo-white" />
      </a>
      <button
        className="navbar-toggler gm-navbar-hamburger px-4 py-2"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <i className="gm-navbar-hamburger bi bi-list"></i>
        </span>
        {/* <span className=" gm-navbar-hamburger"></span> */}
      </button>
      <div
        className="collapse navbar-collapse text-center mt-1"
        id="navbarText"
      >
        <ul className="navbar-nav mr-auto">
          <li
            className="nav-item my-auto p-0"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <HashLink
              to="/#search_section"
              className="nav-link px-0 gm-full-nav-links"
            >
              Search
            </HashLink>
          </li>
          <li
            className="nav-item my-auto p-0"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <HashLink
              to="/#members_section"
              className="nav-link px-0 gm-full-nav-links"
              onClick={() => {
                sendGAEvent({
                  eventName: "View Section",
                  others: { view: "Members Section" },
                });
              }}
            >
              Members
            </HashLink>
          </li>
          <li
            className="nav-item my-auto p-0"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <HashLink
              to="/#supply_section"
              className="nav-link px-0 gm-full-nav-links"
              onClick={() => {
                sendGAEvent({
                  eventName: "View Section",
                  others: { view: "Supply Section" },
                });
              }}
            >
              Supply Chain
            </HashLink>
          </li>
          <li
            className="nav-item my-auto p-0"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <HashLink
              to="/#funding_section"
              className="nav-link px-0 gm-full-nav-links"
              onClick={() => {
                sendGAEvent({
                  eventName: "View Section",
                  others: { view: "Funding Section" },
                });
              }}
            >
              Funding
            </HashLink>
          </li>
          <li
            className="nav-item my-auto p-0"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <a
              className="nav-link px-0 gm-full-nav-links"
              href="https://www.apta.com/research-technical-resources/my-economic-impact-tool/"
              target="_blank"
              onClick={() => {
                sendGAEvent({
                  eventName: "View Section",
                  others: { view: "Economic Impact" },
                });
              }}
            >
              ECONOMIC IMPACT
            </a>
          </li>
          <li
            className="nav-item my-auto p-0"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <Link
              className="nav-link px-0 gm-full-nav-links"
              to="/map"
              onClick={() => {
                sendGAEvent({
                  eventName: "View Section",
                  others: { view: "Map" },
                });
              }}
            >
              MAP
            </Link>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item my-auto">
            <a
              className="nav-link px-0 gm-full-nav-links"
              href="https://www.apta.com/research-technical-resources/industry-footprint/guide/"
              target="_blank"
            >
              <span className="navbar-text">
                <i className="bi bi-question-circle-fill"></i>
                <span className="d-lg-none ml-2">About</span>
              </span>
            </a>
          </li>
          <li className="nav-item my-auto">
            <a
              ref={buttonRef}
              onClick={() => {
                setLink(!link);
              }}
              className="nav-link px-0 gm-full-nav-links"
            >
              <i className="bi bi-share-fill navbar-text"></i>
              <span className="d-lg-none ml-2">Share</span>
              {link ? (
                <OutsideAlerter
                  otherRef={buttonRef}
                  link={link}
                  setLink={setLink}
                >
                  <div
                    className="gm-header-dropdown"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h6 onClick={copyToClipboard}>Share</h6>
                    <p onClick={copyToClipboard} className="py-3">
                      To share information about this legislator, either copy
                      the link below or press one of the social media sharing
                      buttons.
                    </p>
                    <div className="gm-copy-group">
                      <input
                        onClick={copyToClipboard}
                        ref={linkRef}
                        value={window.location.href}
                        readOnly
                      />

                      <svg
                        onClick={copyToClipboard}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#ffffff"
                          d="M23 15H11.707l2.646 2.646-.707.707L9.793 14.5l3.854-3.854.707.707L11.707 14H23zm-13-5H6v1h4zm-4 5h2v-1H6zM3 4h3V3h3a2 2 0 0 1 4 0h3v1h3v9h-1V5h-2v2H6V5H4v16h14v-5h1v6H3zm4 2h8V4h-3V2.615A.615.615 0 0 0 11.386 2h-.771a.615.615 0 0 0-.615.615V4H7zM6 19h4v-1H6z"
                        />
                        <path fill="none" d="M0 0h24v24H0z" />
                      </svg>
                    </div>
                    <div className="my-auto row" style={{ overflow: "hidden" }}>
                      <div className="col d-flex justify-content-center">
                        {" "}
                        <TwitterShareButton
                          title={twitterTitle}
                          via={twitterVia}
                          url={window.location.href}
                        >
                          {" "}
                          <TwitterIcon size={40} round={true} />{" "}
                        </TwitterShareButton>
                      </div>
                      <div className="col d-flex justify-content-center">
                        <FacebookShareButton
                          url={window.location.href}
                          quote={facebookQuote}
                        >
                          {" "}
                          <FacebookIcon size={40} round={true} />{" "}
                        </FacebookShareButton>
                      </div>
                      <div className="col d-flex justify-content-center">
                        <RedditShareButton
                          url={window.location.href}
                          title={redditQuote}
                        >
                          {" "}
                          <RedditIcon size={40} round={true} />{" "}
                        </RedditShareButton>
                      </div>
                      <div className="col d-flex justify-content-center">
                        <LinkedinShareButton
                          url={window.location.href}
                          title={facebookQuote}
                          summary={"test"}
                        >
                          {" "}
                          <LinkedinIcon size={40} round={true} />{" "}
                        </LinkedinShareButton>
                      </div>
                    </div>
                  </div>
                </OutsideAlerter>
              ) : null}
            </a>
          </li>
          {loadingAuth ? (
            <>
              <li className="nav-item my-auto">
                <div className="spinner-border ml-5" role="status"></div>
              </li>
            </>
          ) : (
            <>
              {isAuth && (
                <li className="nav-item my-auto">
                  <Link
                    to="/settings"
                    className="nav-link px-0 gm-full-nav-links"
                  >
                    <span className="navbar-text">
                      <i className="bi bi-gear-fill p-0"></i>
                      <span className="d-lg-none ml-2">Settings</span>
                    </span>
                  </Link>
                </li>
              )}
              <li className="nav-item my-auto">
                {isAuth ? (
                  <Link
                    onClick={() => signOut()}
                    className="nav-link px-0 gm-full-nav-links"
                    to="/home"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <span className="navbar-text">
                      <i className="bi bi-box-arrow-left p-0 mr-2"></i>
                      LOG OUT
                    </span>
                  </Link>
                ) : (
                  //login on apta
                  // <a
                  //   href={`https://www.aptagateway.com/eweb/DynamicPage.aspx?webCode=login&URL_success=${
                  //     process.env.REACT_APP_ENV === "dev"
                  //       ? DEV_BASE_URL
                  //       : STAGING_BASE_URL
                  //   }/close/?userToken={Token}`}
                  //   // target="_blank"
                  //   className="nav-link px-0 gm-full-nav-links"
                  // >
                  //   <span className="navbar-text">
                  //     <i className="bi bi-box-arrow-right p-0 mr-2"></i>
                  //     Sign In
                  //   </span>
                  // </a>
                  //popup login
                  // <div
                  //   onClick={() => {
                  //     signInApta();
                  //   }}
                  //   className="nav-link px-0 gm-full-nav-links"
                  // >
                  //   <span className="navbar-text">
                  //     <i className="bi bi-box-arrow-right p-0 mr-2"></i>
                  //     Sign In
                  //   </span>
                  // </div>
                  //login on apta from footprint
                  <Link
                    to="/login"
                    className="nav-link px-0 gm-full-nav-links"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <span className="navbar-text">
                      <i className="bi bi-person-fill p-0 mr-2"></i>
                      Sign In
                    </span>
                  </Link>
                )}
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default observer(StickyHeader);
