import React, { useMemo } from "react";
import LoadingSpinner from "../../../components/Icons/LoadingSpinner";
import { T_dataComponent } from "../../types";
import { SectionTitle } from "../Components/SectionTitle";

type T_props = {
  title: string;
  isTop?: boolean;
  members: any;
  dataLoading?: boolean;
  type: T_dataComponent;
};

const MemberList: React.FC<T_props> = ({
  title,
  members,
  dataLoading,
  isTop,
  type,
}) => {
  const arr = useMemo(() => {
    let localCount = members?.count + members?.alphaArr?.length;
    const trueMax = Math.ceil(localCount / 3);
    const columnMax = trueMax;

    let arr = [[], [], []];

    let count = 0;
    let topIndex = 0;

    members?.alphaArr?.forEach((section: any, index: number) => {
      const listElem = (
        <li
          key={`${index}`}
          className={`gm-explore-text-3 font-weight-bold ${
            count !== 0 ? "pt-2" : ""
          }`}
        >
          {section.title}
        </li>
      );
      //@ts-ignore
      arr[topIndex].push(listElem);
      count++;

      section?.data?.forEach((member: any, childIndex: number) => {
        const length = section?.data?.length ? section?.data?.length : 0;

        const type = member?.org_ogt_code
          ? member.org_ogt_code.toLowerCase()
          : "";

        const isMain = type === "main" ? true : false;

        const listElem = (
          <li
            className="ml-2 gm-explore-text-3"
            style={{ breakInside: "avoid" }}
            key={member.orgName}
          >
            {`${member.orgName}${isMain ? "*" : ""}`}
          </li>
        );
        //@ts-ignore
        arr[topIndex].push(listElem);
        count++;

        if (count > columnMax) {
          count = 0;
          topIndex++;
        }
      });
    });

    return arr;
  }, [members]);

  return (
    <div style={{ breakInside: "avoid" }}>
      <SectionTitle
        type={type}
        title={title}
        isTop={isTop}
        collapseProps={{ startOpen: true, count: members?.count }}
      >
        {dataLoading ? (
          <LoadingSpinner color="var(--apta-navbar-text-color)" />
        ) : !!!members ? null : members.count >= 1 ? (
          <div className="row" style={{ breakInside: "auto" }}>
            <ul
              className="col-sm-6 col-md-4"
              style={{ listStyle: "none", marginBottom: "0px" }}
            >
              {arr[0]}
            </ul>
            <ul
              className="col-sm-6 col-md-4"
              style={{ listStyle: "none", marginBottom: "0px" }}
            >
              {arr[1]}
            </ul>
            <ul
              className="col-sm-6 col-md-4"
              style={{ listStyle: "none", marginBottom: "0px" }}
            >
              {arr[2]}
            </ul>
          </div>
        ) : null}
      </SectionTitle>
    </div>
  );
};
export default MemberList;
