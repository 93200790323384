import React from "react";
import LazyLoad from "react-lazyload";

interface StateImageProps {
  stateKey: string;
  containerClassMod?: string;
  imgClassMod?: string;
}

const StateImage: React.FC<StateImageProps> = ({ stateKey, imgClassMod, containerClassMod }) => {
  return (
    <div
      className={`gm-state-image-container ${
        stateKey === "RI" ? "gm-rhode-island-container" : null
      } ${containerClassMod}`}
    >
      <img
        className={`gm-state-image ${
          stateKey === "RI" ? "gm-rhode-island-img" : null
        } ${imgClassMod}`}
        src={`/images/stateFlags/${stateKey.toLowerCase()}.svg`}
        alt={`${stateKey} Flag`}
      />
    </div>
  );
};

export default StateImage;
