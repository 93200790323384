import React from "react";

type Data_Types = {
  dataJSON: any;
};

const DataSectionViewer: React.FC<Data_Types> = ({ dataJSON }) => {
  return (
    <>
      {dataJSON?.rows?.map((row: any, key: any) => {
        return (
          <div className={`row ${row?.classes}`} key={key}>
            {row?.columns?.map((column: any, key: any) => {
              return (
                <div
                  key={key}
                  className={`${column?.classes} col-${
                    column?.columnSize ?? column?.columnSize
                  }`}
                >
                  {column?.innerRows?.map((innerRows: any, key: any) => {
                    return (
                      <div className={`row ${innerRows.classes}`} key={key}>
                        {innerRows?.innerColumns?.map(
                          (innerColumn: any, key: any) => {
                            return (
                              <div
                                key={key}
                                className={`col-${
                                  innerColumn?.columnSize ??
                                  innerColumn.columnSize
                                } ${innerColumn.classes}`}
                              >
                                {innerColumn.component}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default DataSectionViewer;
