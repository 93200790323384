import React, { useEffect } from "react";

import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";

export const GrantTable = ({ type, columns, rows, printAllRows }: any) => {
  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const url = new URL(window.location.href);
  const isPrint = url.pathname.includes("print");
  const isMobile =
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i);

  useEffect(() => {
    if (type === "FULL_PAGE") {
      const printAll = printAllRows ? rows?.length : 10;

      table.setPageSize(printAll);
    } else {
      table.setPageSize(10);
    }
  }, []);

  return (
    <>
      <table
        className="table table-hover"
        style={isPrint || !isMobile ? {} : { overflowX: "scroll" }}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <td
                    key={header.id}
                    style={{
                      textOverflow: "ellipsis",
                      wordWrap: "break-word",
                      overflow: "visible",
                      width: header.column.columnDef.size,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </td>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody
          style={{
            borderBottom: "solid 1px #dee2e6",
          }}
        >
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      className="cart-body-header-size"
                      key={cell.id}
                      style={{
                        textOverflow: "ellipsis",
                        wordWrap: "break-word",
                        fontWeight: 300,
                        overflow: "visible",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {type === "WEBSITE" && (
        <div className="row">
          <div className="col">
            {table.getPageCount() > 1 && (
              <div className="flex items-center gap-2 text-center">
                <button
                  className="border rounded p-1 mr-2"
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<<"}
                </button>
                <button
                  className="border rounded p-1 mr-2"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<"}
                </button>
                <span className="flex items-center gap-1  mr-2">
                  Page
                  <strong className="ml-1">
                    {table.getState().pagination.pageIndex + 1} of{" "}
                    {table.getPageCount()}
                  </strong>
                </span>
                <button
                  className="border rounded p-1 mr-2"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">"}
                </button>
                <button
                  className="border rounded p-1 mr-2"
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                >
                  {">>"}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
