import { Chart, registerables } from "chart.js";
import React, { useRef, useState, useEffect } from "react";

export interface GraphProps {
  chartData: any;
  children?: React.ReactNode;
  isRF?: boolean;
  chartElementId?: string;
}

const Graph: React.FC<GraphProps> = ({
  chartData,
  isRF = false,
  chartElementId,
}) => {

  Chart.register(...registerables);
  const canvasRef = useRef<any>(null);
  const graphRef = useRef<any>(null);

  useEffect(() => {
    if (graphRef.current !== null) {
      graphRef.current.destroy();
    }

    let myChartRef;
    if (canvasRef.current !== null && chartData != null) {
      myChartRef = canvasRef.current.getContext("2d");
      graphRef.current = new Chart(myChartRef, chartData);
      graphRef.current.render();
    }
  }, [canvasRef.current, chartData]);

  useEffect(() => {
    return () => {
      if (graphRef.current) {
        graphRef.current.destroy();
      }
    };
  }, []);

  return (
    <canvas
      className="chart-canvas w-100"
      ref={canvasRef}
      id={chartElementId}
    />
  );
};

export default Graph;
