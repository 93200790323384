import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../Hooks";

const ClosePage = () => {
  const history = useHistory();
  const authStore = useStore("authStore");
  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("userToken");
      if (token) {
        await authStore.signInApta(token);
    
        //push for same page, close for popup
        history.push("/home");

        // window.close();
      }
    })();
  }, []);

  return <div></div>;
};

export default ClosePage;
