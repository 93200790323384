import React from "react";

import { EsriNumber } from "APTAPackage/dist/ESRIResponses";
import { convertNumber } from "../../../../components/Pages/Print/helpers";

interface IVertItem {
  title: string;
  ammount: string | undefined;
}

const HoriItem: React.FC<IVertItem> = ({ title, ammount }) => {
  return (

    
    <div className="text-center d-flex flex-column justify-content-center align-items-center flex-grow-1">

        <h2 className="gm-explore-text pb-2">{title}</h2>
        <p className="gm-explore-numeric">{ammount ? ammount : "No Data"}</p>

    </div>
  );
};

interface IWrappedVertItem {
  title: string;
  ammount: EsriNumber;
}

export const DataSummaryItem: React.FC<IWrappedVertItem> = ({
  title,
  ammount,
}) => {
  let parsedAmmount = convertNumber(ammount);
  return <HoriItem title={title} ammount={parsedAmmount} />;
};
