import React from "react";

type T_props = {
  colors: string[];
  labels: string[];
  labelPercents: string[];
};

const DoughnutLegend: React.FC<T_props> = ({
  labelPercents,
  labels,
  colors,
}) => {
  return (
    <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
      {labels.map((label, index) => {
        return (
          <li key={label} style={{ textAlign: "justify" }}>
            <div
              style={{
                display: "inline-block",
                width: ".8rem",
                height: ".8rem",
                background: colors[index],
                borderRadius: "50%",
                marginRight: '.2rem',
              }}
            />
            <span style={{ width: "100%" }}>
              <span
                style={{
                  marginLeft: "2px",
                  // color: colors[index],
                  minWidth: "25px",
                  fontSize: ".8rem",
                  //@ts-ignore
                  fontWeight: "900",
                  // 'text-shadow': '1px 1px 2px black'
                }}
              >
                {labelPercents[index]}{" "}
              </span>
              <span style={{ marginLeft: "2px", fontSize: ".8rem"}}>
                {label}
              </span>
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default DoughnutLegend;
