import React from "react";

function NoData() {
  return (
    <div className="row">
      <div className="col">
      <h1 className="gm-explore-numeric" style = {{textAlign: 'center'}}> No Data</h1>
      </div>
    </div>
  );
}

export default NoData;
