import React from "react";

type T_props = {
  labels: string[];
  activeLabel: number;
};

export const Stepper: React.FC<T_props> = ({ labels, activeLabel }) => {
  return (
    <div
      style={{
        display: "flex",

        width: "100%",
        justifyContent: "space-between",
      }}
    >
      {labels.map((label, idx) => {
        const isFinal = idx === labels.length - 1;

        return (
          <div
            key={idx}
            style={{
              display: "flex",
              flexGrow: isFinal ? 0 : 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexGrow: 0,
                flexShrink: 0,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor:
                    idx <= activeLabel ? "rgba(71, 49, 142, 0.3)" : "white",
                  borderColor: "rgba(71, 49, 142, 0.3)",
                  borderWidth: "1px",
                  borderRadius: "50%",
                  borderStyle: "solid",
                }}
              ></div>
              <span
                style={{
                  position: "absolute",
                  top: "33px",
                  fontSize: ".75rem",
                  fontWeight: 500,
                  color: "rgba(71, 49, 142, 1)",
                }}
              >
                {" "}
                {label}
              </span>
            </div>
            {!isFinal && (
              <hr
                style={{
                  flexGrow: 1,
                  borderColor: "rgba(71, 49, 142, 0.3)",
                  borderWidth: "2px",
                }}
                className="ml-3 mr-3"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
