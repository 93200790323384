import React from "react";

import {
  busSectors,
  busTiers,
  railSectors,
  railTiers,
} from "../../../../../constants/config";
import { HomeHeader } from "../HomeHeader";
import { Space } from "../Space";
import { HomeText } from "../HomeText";
import { DoughnutRefactor } from "../../../../graphs/Doughnut/DoughnutRefactor";

const text = {
  busBold: ` Over 1000 private sector sites supply the bus transit sector.
  These companies provide everything from parts, to systems, to
  entire vehicles.`,
  railBold: `Rail transit systems own track and rights-of-way,
  stations, administrative buildings, and maintenance facilities.`,
  railRest: ``,
  railNote: `     * includes Infrastructure, Station Equipment, and Remanufacturing`,
};

export const SupplyChainHome = () => {
  return (
    <div className="row" id="supply_section">
      <div className="col">
        <HomeHeader title="Supply Chain" id="supply_section" />

        <div className="row">
          <div className="col">
            <HomeText bold={text.busBold} />
          </div>
        </div>

        <Space isHR={false} />

        <div className="row">
          <div className="col-md-6 gm-border-right text-center mb-5 mb-lg-1">
            <DoughnutRefactor {...busTiers} />
          </div>

          <div className="col-md-6 text-center">
            <DoughnutRefactor {...busSectors} />
          </div>
        </div>

        <Space isHR={false} />

        <div className="row">
          <div className="col">
            <HomeText bold={text.railBold} rest={text.railRest} />
          </div>
        </div>

        <Space isHR={false} />

        <div className="row text-center ">
          <div className="col-md-6 gm-border-right mb-5 mb-lg-1">
            <DoughnutRefactor {...railTiers} />
          </div>
          <div className="col-md-6">
            <DoughnutRefactor {...railSectors} />
          </div>
        </div>
        <div className="row">
          <div className="col pt-4">
            <HomeText rest={text.railNote} />
          </div>
        </div>
      </div>
    </div>
  );
};
