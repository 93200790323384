import React, { useRef, useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import DoughLegend from "./DoughnutLegend";

type T_props = {
  id: string;
  data: any;
  labelPercents: any;
  labels: any;
  colors: any;
  units: any;
  noValue?: any;
  title: string;
};

const DoughnutGraph: React.FC<T_props> = (props) => {
  let { id, data, labelPercents, labels, colors, units, noValue } = props;

  const canvasRef = useRef<any | null>(null);
  const graphRef = useRef<Chart | null>(null);

  // Chart.register(...registerables, ChartDataLabels);
  Chart.register(...registerables);

  useEffect(() => {
    buildGraph(data, labels);
  }, [id]);

  const buildGraph = (data: any, labels: any) => {
    if (graphRef.current !== null) {
      graphRef.current.destroy();
    }

    let myChartRef;
    if (canvasRef.current) {
      myChartRef = canvasRef.current.getContext("2d");
    }

    //@ts-ignore
    graphRef.current = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: colors,
            datalabels: { display: false },
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.75,
        cutout: "70%",
        plugins: {
          legend: { display: false },
        },
        //@ts-ignore
        tooltips: {
          enabled: true,
          mode: "single",
          callbacks: {
            label: function (tooltipItems: any, data: any) {
              units = units ? units : "";

              let index = tooltipItems.index;

              if (noValue) {
                return " " + labelPercents[index] + " " + data.labels[index];
              }

              let value = data.datasets[0].data[index];
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(",");
              return units !== "$"
                ? " " +
                    labelPercents[index] +
                    " " +
                    data.labels[index] +
                    " : " +
                    value +
                    " " +
                    units
                : " " +
                    labelPercents[index] +
                    " " +
                    data.labels[index] +
                    " : " +
                    "$ " +
                    value;
            },
          },
        },
      },
    });

    return () => {
      if (graphRef.current !== null) {

        graphRef.current.destroy();
      }
    }
  };

  let objs: any[] = [];

  data.forEach((elem: any, index: any) => {
    objs.push({
      data: elem,
      label: labels[index],
      color: colors[index],
      labelPercent: labelPercents[index],
    });
  });

  objs.sort((a, b) => {
    //@ts-ignore
    if (parseFloat(a.data) > parseFloat(b.data, 10)) return -1;
    //@ts-ignore
    else if (parseFloat(a.data) < parseFloat(b.data, 10)) return 1;
    return 0;
  });

  data = [];
  labels = [];
  labelPercents = [];
  colors = [];
  objs.forEach((obj) => {
    data.push(obj.data);
    labels.push(obj.label);
    colors.push(obj.color);
    labelPercents.push(obj.labelPercent);
  });

  return (
    <React.Fragment>
      <div className="col-md">
        <canvas ref={canvasRef} id={id} />
      </div>
      <div className="col-md my-auto d-flex justify-content-center text-md-left">
        <DoughLegend
          labels={labels}
          colors={colors}
          labelPercents={labelPercents}
        />
      </div>
    </React.Fragment>
  );
};

export default DoughnutGraph;
