"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayerConfig = void 0;
function extractValue(value) {
    if (value === undefined)
        return false;
    else if (typeof value !== "boolean")
        return false;
    return value;
}
function hasOwnProperty(obj, prop) {
    return obj.hasOwnProperty(prop);
}
class LayerConfig {
    constructor(data) {
        this.APTAMembersDBE = extractValue(data?.APTAMembersDBE);
        this.APTAMembers = extractValue(data?.APTAMembers);
        this.RuralProviders = extractValue(data?.RuralProviders);
        this.BusManu = extractValue(data?.BusManu);
        this.RailManu = extractValue(data?.RailManu);
        this.AmtrakStations = extractValue(data?.AmtrakStations);
        this.RailStations = extractValue(data?.RailStations);
        this.BusStationAreas = extractValue(data?.BusStationAreas);
        this.AmtrakRoutes = extractValue(data?.AmtrakRoutes);
        this.RailLines = extractValue(data?.RailLines);
        this.TransitAgency = extractValue(data?.TransitAgency);
        this.TransitAgencyServiceArea = extractValue(data?.TransitAgencyServiceArea);
        this.GrantData = extractValue(data?.GrantData);
        this.UrbanizedArea = extractValue(data?.UrbanizedArea);
    }
    getValue(key) {
        if (hasOwnProperty(this, key))
            return this[key];
        return false;
    }
    getPrintableName(alias) {
        let theMap = {
            APTAMembersDBE: "APTA Members DBE",
            APTAMembers: "APTA Members",
            RuralProviders: "Rural Public Transit Providers",
            BusManu: "Bus Manufacturers",
            RailManu: "Rail Manufacturers",
            BusStationAreas: "Bus Station Areas",
            AmtrakStations: "Amtrak Stations",
            RailStations: "Rail Stations",
            AmtrakRoutes: "Amtrak Routes",
            RailLines: "Rail Lines",
            TransitAgency: "Transit Agency",
            TransitAgencyServiceArea: "Transit Agency Service Area",
            GrantData: "Grant Data",
            UrbanizedArea: "Urbanized Area",
            States: "States",
            HOUSE: "House",
            SLDL: "SLDL",
            SLDU: "SLDU",
        };
        if (hasOwnProperty(theMap, alias))
            return theMap?.[alias];
        return undefined;
    }
    getID(alias) {
        let theMap = {
            APTAMembersDBE: "bc6b5938a137418f93325b0f7533f8cf",
            APTAMembers: "54692244989d4a0b945e3516d51b92cd",
            RuralProviders: "873856ab3dfd46709ece0324d1b9756e",
            BusManu: "8b611df1549d4731b5a1c9445496ffb5",
            RailManu: "c1a2547390f64befad2ccc4a72b48c3b",
            AmtrakStations: "9811b902b0c54577bb12b654db125c66",
            RailStations: "11e95e0793994e678fdf0048aa73d8fb",
            AmtrakRoutes: "c2228cce812e44aa8afcd3aec3fb33c5",
            RailLines: "b551915d1d7e45bcb8a046bde1407f6a",
            TransitAgency: "fee8734e57894ac4a4cd2891f52f8c1d",
            TransitAgencyServiceArea: "b51b46dfdee346939d0a30d1a42106d0",
            GrantData: "74cdeab461314411b6b510b58c80561e",
            UrbanizedArea: "5626ad6a735d4f798964afd0159c4ec5",
            State: "1d843651380f417a98328b112ddcbb33",
            HOUSE: "4433601145e74eb0a3da5f015f11538d",
            SLDL: "aec2536bfd9a4752a590eaba9450dc04",
            SLDU: "8309bc28d894474ba53017d8536f93b8",
        };
        if (hasOwnProperty(theMap, alias))
            return theMap?.[alias];
        return undefined;
    }
    getAlias(id) {
        const theMap = {
            bc6b5938a137418f93325b0f7533f8cf: "APTAMembersDBE",
            "54692244989d4a0b945e3516d51b92cd": "APTAMembers",
            "873856ab3dfd46709ece0324d1b9756e": "RuralProviders",
            "8b611df1549d4731b5a1c9445496ffb5": "BusManu",
            c1a2547390f64befad2ccc4a72b48c3b: "RailManu",
            "9811b902b0c54577bb12b654db125c66": "AmtrakStations",
            "11e95e0793994e678fdf0048aa73d8fb": "RailStations",
            c2228cce812e44aa8afcd3aec3fb33c5: "AmtrakRoutes",
            b551915d1d7e45bcb8a046bde1407f6a: "RailLines",
            fee8734e57894ac4a4cd2891f52f8c1d: "TransitAgency",
            b51b46dfdee346939d0a30d1a42106d0: "TransitAgencyServiceArea",
            "74cdeab461314411b6b510b58c80561e": "GrantData",
            "5626ad6a735d4f798964afd0159c4ec5": "UrbanizedArea",
            "1d843651380f417a98328b112ddcbb33": "State",
            "4433601145e74eb0a3da5f015f11538d": "HOUSE",
            aec2536bfd9a4752a590eaba9450dc04: "SLDL",
            "8309bc28d894474ba53017d8536f93b8": "SLDU",
        };
        if (hasOwnProperty(theMap, id))
            return theMap?.[id];
        return undefined;
    }
    static genAllFalse() {
        const data = {
            APTAMembersDBE: false,
            APTAMembers: false,
            RuralProviders: false,
            BusManu: false,
            RailManu: false,
            AmtrakStations: false,
            BusStationAreas: false,
            RailStations: false,
            AmtrakRoutes: false,
            RailLines: false,
            TransitAgency: false,
            TransitAgencyServiceArea: false,
            GrantData: false,
            UrbanizedArea: false,
        };
        return new LayerConfig(data);
    }
}
exports.LayerConfig = LayerConfig;
