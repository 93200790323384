import { loadModules } from "esri-loader";

// Resolves APTA Layer
export function genAPTALayer(token: any) {
  return new Promise((resolve, reject) => {
    loadModules(["esri/identity/IdentityManager", "esri/layers/FeatureLayer"], {
      version: "4.25",
    })
      .then(([IdentityManager, FeatureLayer]) => {
     
          IdentityManager.registerToken({
            expires: token.expires,
            server: "http://www.arcgis.com/sharing/rest",
            ssl: false,
            token: token.token,
          });

          const APTAMembers = new FeatureLayer({
            url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/netforum_members_2023/FeatureServer/259",
          });
          resolve(APTAMembers);
        
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function initAPTALayer() {
  // return new Promise((resolve, reject) => {
  //   genAPTALayer()
  //     .then((APTALayer) => {
  //       resolve(APTALayer);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });

    // resolve("test");
    // GLOBAL_AUTH_TOKEN.then((token: any) => {
    //   genAPTALayer(token)
    //     .then((APTALayer) => {
    //       resolve(APTALayer);
    //     })
    //     .catch((error) => {
    //       reject(error);
    //     });
    // }).catch((error) => {
    //   reject(error);
    // });
  // });
}

function initLayers(token: any) {
  return new Promise((resolve, reject) => {
    loadModules(
      [
        "esri/identity/IdentityManager",
        "esri/layers/FeatureLayer",
        "esri/core/watchUtils",
      ],
      {
        version: "4.25",
      }
    )
      .then(([IdentityManager, FeatureLayer, watchUtils]) => {
        IdentityManager.registerToken({
          expires: token.expires,
          server: "http://www.arcgis.com/sharing/rest",
          ssl: false,
          token: token.token,
        });

        let layersObj: any = {};

        layersObj.stateGrants = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/STATE_APTA_2022/FeatureServer/2",
        });

        layersObj.CDGrants = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/CD_APTA_2022/FeatureServer/2",
        });

        layersObj.SLDLGrants = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_APTA_2022/FeatureServer/2",
        });

        layersObj.SLDUGrants = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_APTA_2022/FeatureServer/2",
        });

        layersObj.StateCounty = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/STATE_APTA_2022/FeatureServer/1",
        });

        layersObj.CDCounty = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/CD_APTA_2022/FeatureServer/1",
        });

        layersObj.SLDUCounty = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_APTA_2022/FeatureServer/1",
        });

        layersObj.SLDLCounty = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_APTA_2022/FeatureServer/1",
        });

        layersObj.StateSystems = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/STATE_APTA_2022/FeatureServer/3",
        });

        layersObj.CDSystems = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/CD_APTA_2022/FeatureServer/3",
        });

        layersObj.SLDUSystems = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_APTA_2022/FeatureServer/3",
        });

        layersObj.SLDLSystems = new FeatureLayer({
          url: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_APTA_2022/FeatureServer/3",
        });

        resolve(layersObj);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
