import { ArcGISIdentityManager } from "@esri/arcgis-rest-request";
import { loadModules } from "esri-loader";
import { useEffect, useState } from "react";
import { useStore } from ".";

import { portalURLs } from "../constants/configTS";
import { User } from "../Types/User";

export type T_useEsriModulesRes = {
  esriObj: T_esriObj | null;
  esriToken: ArcGISIdentityManager | null;
  layers: any;
  ident: null | boolean;
};


export type T_esriObj = {
  MapView: any;
  WebMap: any;
  Basemap: any;
  Home: any;
  Search: any;
  Legend: any;
  BasemapGallery: any;
  LayerList: any;
  Expand: any;
  watchUtils: any;
  Graphic: any;
  FeatureLayer: any;
  IdentityManager: any;
  Layer: any;
  FieldsContent: any;
  Polygon: any;
  GroupLayer: any;
};

type T_token = { token: string; expires: number };

const useEsriModules = (
  token: string | null = null,
  expires: number | null = null
): T_useEsriModulesRes => {
  const authStore = useStore("authStore");

  const [esriToken, setEsriToken] = useState<ArcGISIdentityManager | null>(
    null
  );
  const [ident, setIdent] = useState<any | null>(null);
  const [esriObj, setEsriObj] = useState<T_esriObj | null>(null);
  const [layers, setLayers] = useState<any>(undefined);

  useEffect(() => {
    if (token) {
      const session = new ArcGISIdentityManager({
        token,
        tokenExpires: expires ? new Date(expires) : undefined,
        ssl: true,
      });

      setEsriToken(session);
    } else if (token == null) {
      authStore.GET_esri().then((res) => setEsriToken(res));
    }
  }, []);

  useEffect(() => {
    loadModules(
      [
        "esri/views/MapView",
        "esri/WebMap",
        "esri/Basemap",
        "esri/widgets/Home",
        "esri/widgets/Search",
        "esri/widgets/Legend",
        "esri/widgets/BasemapGallery",
        "esri/widgets/LayerList",
        "esri/widgets/Expand",
        "esri/core/watchUtils",
        "esri/Graphic",
        "esri/layers/FeatureLayer",
        "esri/identity/IdentityManager",
        "esri/layers/Layer",
        "esri/popup/content/FieldsContent",
        "esri/geometry/Polygon",
        "esri/layers/GroupLayer",
      ],
      { version: "4.25" }
    )
      .then(
        ([
          MapView,
          WebMap,
          Basemap,
          Home,
          Search,
          Legend,
          BasemapGallery,
          LayerList,
          Expand,
          watchUtils,
          Graphic,
          FeatureLayer,
          IdentityManager,
          Layer,
          FieldsContent,
          Polygon,
          GroupLayer,
        ]) => {
          setEsriObj({
            MapView,
            WebMap,
            Basemap,
            Home,
            Search,
            Legend,
            BasemapGallery,
            LayerList,
            Expand,
            watchUtils,
            Graphic,
            FeatureLayer,
            IdentityManager,
            Layer,
            FieldsContent,
            Polygon,
            GroupLayer,
          });
        }
      )
      .catch((error) => {
        setEsriObj(null);
      });
  }, []);

  useEffect(() => {
    if (esriObj != null && esriToken != null) {
      const { IdentityManager, FeatureLayer } = esriObj;

      IdentityManager.registerToken({
        server: "https://www.arcgis.com/sharing/rest",
        token: esriToken?.token,
      });

      setIdent(true);

      let layersObj: any = {};

      const keys = Object.keys(portalURLs);

      keys.forEach((key) => {
        layersObj[key] = new FeatureLayer({ url: portalURLs[key] });
      });

      setLayers(layersObj);
    }
  }, [esriObj, esriToken]);

  return { esriObj, layers, esriToken, ident };
};

export default useEsriModules;
