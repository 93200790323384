import React from "react";
import { observer } from "mobx-react";
import FullStatePanel from "./FullStatePanel";
import SearchPanel from "./SearchPanel";
import { useStore } from "../../../../Hooks";

import { PANEL_TYPES } from "../../../../Stores/PanelStore/types";

import PersonPanel from "./PersonPanel";
import CommitteePanel from "./CommitteePanel";
import APTAMemberPanel from "./MemberPanel";
import ExploreLayers from "./ExploreLayers";

interface PanelIndexProps {
  store: any;
}

const PanelIndex: React.FC<PanelIndexProps> = () => {
  const panelStore = useStore("panelStore");

  const renderSwitch = (activeView: string) => {
    switch (activeView) {
      //   case types.EXPLORE:
      //     return <ExploreLayersComponent store={store} />;
      case PANEL_TYPES.SELECT_STATE:
        return <SearchPanel />;
      case PANEL_TYPES.PERSON:
        return <PersonPanel />;
      case PANEL_TYPES.SEARCH:
        return <SearchPanel />;
      case PANEL_TYPES.COMMITTEE:
        return <CommitteePanel />;
      case PANEL_TYPES.APTAMEMBER:
        return <APTAMemberPanel />;
      case PANEL_TYPES.EXPLORE:
        return <ExploreLayers />;
      default:
        return <FullStatePanel />;
    }
  };

  return (
    <section
      id="gm-side-panel-parent"
      className={panelStore.activePanel ? "" : "gm-hide"}
    >
      {renderSwitch(panelStore.activeView)}

      <div
        className={
          panelStore.activePanel
            ? "gm-slide-toggle"
            : "gm-slide-toggle-hide-sidePanel"
        }
        onClick={() => panelStore.setActivePanel()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          className="svg-icon"
        >
          <path d="M25 28h-5L8 16 20 4h5L13 16l12 12z" />
        </svg>
      </div>
    </section>
  );
};

export default observer(PanelIndex);
