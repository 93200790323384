import React from "react";
import { Space } from "./Space";

type T_props = {
  title: string;
  id: string;
};

export const HomeHeader: React.FC<T_props> = ({ title, id }) => {
  return (
    <>
      <div className="row" id={id}>
        <div className="col">
          <h2 className="headerText">{`${title}:`}</h2>
        </div>
      </div>
      <Space isHR = {true} />
    </>
  );
};
