import React from "react";

type T_props = {
  funding: string;
  year: string;
  showBorder?: boolean;
};

export type T_fundingBoxProps = T_props;

export const FundingBox: React.FC<T_props> = ({
  funding,
  year,
  showBorder = false,
}) => {
  return (
    <div className={`col py-4 ${showBorder ? "gm-border-right" : ""}`}>
      <h2 className="avenir-bold text-dark-blue" style={{ fontSize: "2rem" }}>
        {funding}
      </h2>
      <p
        className="gm-homepage-font-size-small text-blue"
        style={{ fontSize: "18px" }}
      >
        {year}
      </p>
    </div>
  );
};
