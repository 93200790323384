import React from "react";
import { observer } from "mobx-react";
import Panel from "../BasePanel/Panel";

import {
  SEARCH_PANEL_TYPES,
  SEARCH_TYPES,
} from "../../../../../Stores/PanelStore/types";
import "./SearchPanel.css";
import { useStore } from "../../../../../Hooks";
import TabSelect from "./TabSelect";
import Search from "./Search";
import LegList from "./Lists/LegList";
import APTAList from "./Lists/APTAList";
import CommList from "./Lists/CommList";
import StateSelectPanel from "../StateSelectPanel";

interface IHashObj {
  [key: string]: boolean;
}

const SearchPanel = () => {
  const searchStore = useStore("searchStore");

  let renderData = null;

  switch (searchStore.searchType) {
    case SEARCH_PANEL_TYPES.STATE_SEARCH_PANEL: {
      renderData = <StateSelectPanel />;
      break;
    }
    case SEARCH_PANEL_TYPES.LEG_SEARCH_PANEL: {
      renderData = <LegList />;
      break;
    }
    case SEARCH_PANEL_TYPES.APTA_SEARCH_PANEL: {
      renderData = <APTAList />;
      break;
    }
    case SEARCH_PANEL_TYPES.COMMITTEE_SEARCH_PANEL: {
      renderData = <CommList />;
      break;
    }
  }

  return (
    <Panel classMod="gm-search-panel">
      <Search />
      <TabSelect />

      <section className="gm-search-panel-results">
        {searchStore.searchType === SEARCH_PANEL_TYPES.STATE_SEARCH_PANEL ? (
          renderData
        ) : searchStore.searchStatus === SEARCH_TYPES.INIT ? (
          <p> Please Search Above </p>
        ) : searchStore.searchStatus === SEARCH_TYPES.LOADING ? (
          <p> Loading ... </p>
        ) : searchStore.searchStatus === SEARCH_TYPES.SUCCESS ? (
          renderData
        ) : (
          <p> Error Loading Search </p>
        )}
      </section>
    </Panel>
  );
};

export default observer(SearchPanel);
