import React from "react";
import "./Home.css";
import CarouselComponent from "./components/Carousel/Carousel";
import Search from "./Search";
import { TransitHome } from "./components/TransitHome/TransitHome";
import RidershipComponent from "./components/RidershipComponent";
import DataExplorerComponent from "./components/DataExplorerComponent";
import { MembersHome } from "./components/MemberHome/MembersHome";
import { Space } from "./components/Space";
import { SupplyChainHome } from "./components/SupplyChainHome/SupplyChainHome";
import { FundingHome } from "./components/FundingHome/FundingHome";
import { SummaryHome } from "./components/SummaryHome/SummaryHome";

function App() {
  return (
    <div className="App">
      <CarouselComponent />
      {/* <HomeSearchComponent searchObj={props.searchObj} store={props.store} /> */}
      <Search />
      <div className="container px-4 mt-3">
        <SummaryHome />
        <Space isHR={false} />

        <TransitHome />
        <Space isHR={false} />
        <SupplyChainHome />
        <Space isHR={false} />
        <MembersHome />
        <Space isHR={false} />
        <FundingHome />
        <Space isHR={false} />
        <RidershipComponent />
        <DataExplorerComponent />
      </div>
    </div>
  );
}

export default App;
