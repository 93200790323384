import React from "react";
import { observer } from "mobx-react";
import Panel from "./BasePanel/Panel";
import CommitteeListElem from "./ListElems/Committee";
import PersonElem from "./ListElems/Person";
import { useStore } from "../../../../Hooks";
import { AddAllCommittee } from "./ListElems/AddButtons";
import { LegislatorTypes, FedLeg } from "APTAPackage";
import { reaction } from "mobx";

export type T_committeeMember = {
  rank?: number,
  title?: string,
  bioguide?: string,
  hashId?: string,
}

const PersonPanel: React.FC = () => {
  const panelStore = useStore("panelStore");
  const dataStore = useStore("dataStore");


  if (panelStore?.activeCommittee === undefined) {
    return <Panel />;
  }
  return (
    <Panel>
      <CommitteeListElem theComm={panelStore.activeCommittee} />
      {/* TODO Redo button in new cart store */}
      {/* <AddAllCommittee
        stateKey={
          panelStore.activeCommittee.chamber === LegislatorTypes.SENATE
            ? "USSENATE"
            : "USHOUSE"
        }
        legs={panelStore.activeCommittee.currentMembers}
      /> */}

      {panelStore.activeCommittee.currentMembers.map(
        (member: T_committeeMember, index: number) => {

          const hashId = member?.hashId;
          if(hashId == null) return null;

          const thePerson = dataStore.getPerson(hashId);
          if (thePerson != null)
            return (
              <PersonElem
                key={index}
                person={thePerson}
                solo={true}
                district={true}
              />
            );
        }
      )}
    </Panel>
  );
};

export default observer(PersonPanel);
