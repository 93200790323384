import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Person } from "aptapackage/dist/constants";

export type T_GA_eventNames =
  | "Click on Person"
  | "Click On State"
  | "Map Source Feature Layer Click"
  | "Print Person"
  | "Search Results Click"
  | "View Section"
  | "external_link"
  | "login"
  | "logout";

export type T_GA_paramNames =
  | "Click on Person"
  | "Click On State"
  | "Map Source Feature Layer Click"
  | "Print Person"
  | "Search Results Click"
  | "View Section"
  | "Visited Social Media"
  | "Visited Website"
  | "event_apta-id";

// | "View Members Section"
// | "View Supply Chain Section"
// | "View Funding Section"
// | "View Economic Impact"
// | "View Map"

const isProduction = process.env.NODE_ENV === "production";

const GA_TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const init = () =>
  ReactGA.initialize(GA_TRACKING_ID as string, {
    // testMode: !isProduction,
  });

export const sendGAEvent = (args: {
  eventName: T_GA_eventNames;
  others: { [key: string]: boolean | string | null };
  apta_user?: string;
}) => {
  const event = args.eventName;

  if (event == "login") ReactGA.set({ user_id: args?.apta_user });
  else if (event == "logout") ReactGA.set({ user_id: undefined });

  ReactGA.event(args.eventName, {
    ...args.others,
    apta_user: args?.apta_user,
  });
};

export const sendGAEventPerson = (args: {
  eventName: T_GA_eventNames;
  eventLabel: string;
  person: Person;
  otherParams?: any;
}) => {
  const { person, eventLabel, otherParams } = args;

  let paramObj: any = {
    hashId: person?.identityInfo?.hashID,
    seat: person?.identityInfo?.builtID,
    type: person?.identityInfo?.type,
    state: person?.identityInfo?.stateAbbrev,
    chamber: person?.identityInfo?.chamber,
    district: person?.identityInfo?.district,
    name: person?.fullName,
  };

  const keys = Object.keys(paramObj);
  keys.forEach((key) => {
    const value = paramObj[key];
    paramObj[`${eventLabel}_${key}`] = value;
  });

  if (otherParams != null)
    paramObj = {
      ...paramObj,
      ...otherParams,
    };

  ReactGA.event(args.eventName, {
    ...paramObj,
  });
};

export const sendPageview = (path: string) =>
  ReactGA.send({
    hitType: "pageview",
    page: path,
  });

export const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let windowPathname = location?.pathname
      ? location?.pathname
      : "/pathnameNotFound";
    let windowSearch = window.location.search
      ? window.location.search
      : "searchNotFound";

    sendPageview(windowPathname);
  }, [location]);
};
