import { ChartConfiguration } from "chart.js/auto";

import React from "react";
import Graph from "./Graph";

export interface BarGraphProps {
  labels: string[] | string[][];
  data: number[];
  colors: string[];
  yAxesCallback?: any;
  xAxesCallback?: any;

  showDataLabels?: boolean;
  aspectRatio?: number;
  chartElementId?: string;
  tooltip?: any;
}

const BarGraph: React.FC<BarGraphProps> = ({
  labels,
  data,
  colors,
  chartElementId,
  yAxesCallback = (value: any) => value,
  xAxesCallback = (value: any) => value,
  showDataLabels = false,
  aspectRatio,
  tooltip
}) => {
  const chartData: ChartConfiguration = {
    type: "bar",
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
          borderColor: colors,

          borderWidth: 1,
          /* A function that returns true or false. */
          datalabels: {
            display: (context) => {
              const displayVal = context.dataset.data[context.dataIndex];
              return showDataLabels
                ? displayVal
                  ? displayVal > 0
                    ? true
                    : false
                  : false
                : false;
            },
            anchor: "end",
            align: "top",
          },
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: aspectRatio ? aspectRatio : 1.4,
      animation: {
        duration: 0,
      },
      scales: {
        x: {
          ticks: {
            // @ts-ignore
            callback: (item: string) => {
              const index = parseInt(item);
              return labels[index] as string;
            },
            font: { size: 20 },
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: yAxesCallback,
            font: { size: 20 },
          },
          suggestedMin: 50,
          suggestedMax: 100,
        },
      },

      plugins: {
        legend: {
          display: false,
        },
        tooltip: tooltip ? tooltip : undefined
      },
    },
  };

  return <Graph chartData={chartData} chartElementId={chartElementId} />;
};

export default BarGraph;
