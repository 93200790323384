import React from "react";
import APTAQRCode from "../../../../public/images/apta_qr_code.png";

const QrCode = () => {
  return (
    <div className="row">
      <div className="col text-center">
        <h1 className="text-danger">
          More information at https://footprint.apta.com/
        </h1>
        <img src={APTAQRCode} className="img-fluid" style={{ height: 350 }} />;
      </div>
    </div>
  );
};

export default QrCode;
