"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionTitles = exports.LayerTitles = void 0;
var LayerTitles;
(function (LayerTitles) {
    LayerTitles["APTAMembersDBE"] = "APTAMembersDBE";
    LayerTitles["APTAMembers"] = "APTAMembers";
    LayerTitles["RuralProviders"] = "RuralProviders";
    LayerTitles["BusStationAreas"] = "BusStationAreas";
    LayerTitles["BusManu"] = "BusManu";
    LayerTitles["RailManu"] = "RailManu";
    LayerTitles["AmtrakStations"] = "AmtrakStations";
    LayerTitles["RailStations"] = "RailStations";
    LayerTitles["AmtrakRoutes"] = "AmtrakRoutes";
    LayerTitles["RailLines"] = "RailLines";
    LayerTitles["TransitAgency"] = "TransitAgency";
    LayerTitles["TransitAgencyServiceArea"] = "TransitAgencyServiceArea";
    LayerTitles["GrantData"] = "GrantData";
    LayerTitles["UrbanizedArea"] = "UrbanizedArea";
    LayerTitles["States"] = "States";
    LayerTitles["House"] = "House";
    LayerTitles["SLDU"] = "SLDU";
    LayerTitles["SLDL"] = "SLDL";
})(LayerTitles = exports.LayerTitles || (exports.LayerTitles = {}));
var SectionTitles;
(function (SectionTitles) {
    SectionTitles["MemberSummary"] = "MemberSummary";
    SectionTitles["FederalAppropriations"] = "FederalAppropriations";
    SectionTitles["RegionalFunding"] = "RegionalFunding";
    SectionTitles["MajorGrantAwards"] = "MajorGrantAwards";
    SectionTitles["TransportationFootprint"] = "TransportationFootprint";
    SectionTitles["UsefulLifeofPublicTransit"] = "UsefulLifeofPublicTransit";
    SectionTitles["MajorPublicTransitSystems"] = "MajorPublicTransitSystems";
    SectionTitles["DataSummary"] = "DataSummary";
    SectionTitles["APTAMembers"] = "APTAMembers";
    SectionTitles["BusManufacturers"] = "BusManufacturers";
    SectionTitles["RailManufacturers"] = "RailManufacturers";
    SectionTitles["DBEMembers"] = "DBEMembers";
})(SectionTitles = exports.SectionTitles || (exports.SectionTitles = {}));
