import React from "react";
import { observer } from "mobx-react";
import PanelListElem from ".";
import StateImage from "./StateImage";

import { useStore } from "../../../../../Hooks";
import { FedLeg, StateLeg } from "APTAPackage";


interface AddButtonProps {
  img: React.ReactNode;
  legs: (FedLeg | StateLeg)[];
  title: string;
}

export const AddButton: React.FC<AddButtonProps> = observer(
  ({ img, title, legs }) => {
    const panelStore = useStore("panelStore");
    const newCartStore = useStore("newCartStore")

    return (
      <PanelListElem
        img={img}
        onClick={(e: any) => {
          e.stopPropagation();
          newCartStore.addToCart(legs);
        }}
      >
        <span>{title}</span>
      </PanelListElem>
    );
  }
);

interface AddButtonPropsHash {
  img: React.ReactNode;
  legs: string[];
  title: string;
}

export const AddButtonHash: React.FC<AddButtonPropsHash> = observer(
  ({ img, title, legs }) => {
    const panelStore = useStore("panelStore");


    return panelStore.printMode ? (
      <PanelListElem
        img={img}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <span>{title}</span>
      </PanelListElem>
    ) : null;
  }
);

interface ButtonProps {
  legs: (FedLeg | StateLeg)[];
  stateKey?: string;
}

export const AddAllFed: React.FC<ButtonProps> = ({ legs }) => {
  const authStore = useStore("authStore");
  if (authStore.isAuth)
    return (
      <AddButton
        img={
          <StateImage
            stateKey="USA"
            containerClassMod="gm-unset-height"
            imgClassMod="gm-no-border"
          />
        }
        title=" Add All Federal Legislators to Print"
        legs={legs}
      />
    );
  else return <></>;
};

export const AddAllState: React.FC<ButtonProps> = ({ legs, stateKey }) => {
  const authStore = useStore("authStore");
  if (authStore.isAuth)
    return (
      <AddButton
        img={
          <StateImage
            stateKey={stateKey as string}
            containerClassMod="gm-unset-height"
            imgClassMod="gm-no-border"
          />
        }
        title=" Add All to Print"
        legs={legs}
      />
    );
  else return <></>;
};

export const AddAllSenState: React.FC<ButtonProps> = ({ legs }) => {
  const authStore = useStore("authStore");
  if (authStore.isAuth)
    return (
      <AddButton
        img={
          <StateImage
            stateKey="USSENATE"
            containerClassMod="gm-unset-height"
            imgClassMod="gm-no-border"
          />
        }
        title=" Add All Senators"
        legs={legs}
      />
    );
  else return <></>;
};

export const AddAllHouseState: React.FC<ButtonProps> = ({ legs }) => {
  const authStore = useStore("authStore");
  if (authStore.isAuth)
    return (
      <AddButton
        img={
          <StateImage
            stateKey="USHOUSE"
            containerClassMod="gm-unset-height"
            imgClassMod="gm-no-border"
          />
        }
        title=" Add All House"
        legs={legs}
      />
    );
  else return <></>;
};

export const AddAllSLDLState: React.FC<ButtonProps> = ({ legs, stateKey }) => {
  const authStore = useStore("authStore");
  if (authStore.isAuth)
    return (
      <AddButton
        img={
          <StateImage
            stateKey={stateKey as string}
            containerClassMod="gm-unset-height"
            imgClassMod="gm-no-border"
          />
        }
        title=" Add All SLDL"
        legs={legs}
      />
    );
  else return <></>;
};

export const AddAllSLDUState: React.FC<ButtonProps> = ({ legs, stateKey }) => {
  const authStore = useStore("authStore");
  if (authStore.isAuth)
    return (
      <AddButton
        img={
          <StateImage
            stateKey={stateKey as string}
            containerClassMod="gm-unset-height"
            imgClassMod="gm-no-border"
          />
        }
        title=" Add All SLDU"
        legs={legs}
      />
    );
  else return <></>;
};

export const AddAllSearch: React.FC<ButtonProps> = ({ legs, stateKey }) => {
  const authStore = useStore("authStore");
  if (authStore.isAuth)
    return (
      <AddButton
        img={
          <StateImage
            stateKey={stateKey as string}
            containerClassMod="gm-unset-height"
            imgClassMod="gm-no-border"
          />
        }
        title=" Add All Matching Members"
        legs={legs}
      />
    );
  else return <></>;
};

interface ButtonPropsHash {
  legs: string[];
  stateKey?: string;
}

export const AddAllCommittee: React.FC<ButtonPropsHash> = ({
  legs,
  stateKey,
}) => {
  const authStore = useStore("authStore");
  if (authStore.isAuth)
    return (
      <AddButtonHash
        img={
          <StateImage
            stateKey={stateKey as string}
            containerClassMod="gm-unset-height"
            imgClassMod="gm-no-border"
          />
        }
        title=" Add All Committee Members"
        legs={legs}
      />
    );
  else return <></>;
};
