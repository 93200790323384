import { CoreData } from "APTAPackage";

export const valuesArr: (keyof CoreData)[] = [
  "Fares_fund",
  "Local_fund",
  "State_fund",
  "Federal_fund",
];
export const labelsArr = [
  "Fares Funding",
  "Local Funding",
  "State Funding",
  "Federal Funding",
];

export const colors = ["#36a2eb", "#2b81bc", "#20618d", "#15405E"];
