"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../constants");
class IdentityInfo {
    constructor(type, stateAbbrev, district, chamber, geoid, geometry, externalID) {
        this.type = type;
        this.stateAbbrev = stateAbbrev;
        this.stateFull = constants_1.stateAbbrevToFull[stateAbbrev];
        this.district = district;
        this.chamber = chamber;
        let newDistrict = district;
        if (district != null && typeof district == 'string') {
            newDistrict = newDistrict.replace('-', ' ');
            this.district = newDistrict;
        }
        this.hashID = `${type}-${stateAbbrev}-${newDistrict}-${chamber}-${externalID}`;
        this.hashID = this.hashID.replace('/', '-');
        this.builtID = `${stateAbbrev}-${chamber}-${district}`.toLowerCase();
        this.externalID = `${externalID}`;
        this.geometry = geometry;
        if (geoid)
            this.geoid = geoid;
    }
}
exports.default = IdentityInfo;
