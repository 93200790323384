"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLegislatorData = void 0;
const getMembers_1 = require("./getMembers");
const getData_1 = require("./getData");
// Get All Data
function getLegislatorData(requestData, token) {
    return new Promise((resolve, reject) => {
        Promise.all([
            getMembers_1.getDBEMembers(requestData, token),
            getMembers_1.getAPTAMembers(requestData, token),
            getMembers_1.getBusMembers(requestData, token),
            getMembers_1.getRailMembers(requestData, token),
            getData_1.coreDataQuery(requestData, token),
            getData_1.grantsQuery(requestData, token),
            getData_1.vehicleLifeQuery(requestData, token),
            getData_1.countyQuery(requestData, token),
            getData_1.transitSystemsQuery(requestData, token),
            getData_1.transitStatsQuery(requestData, token)
        ])
            .then((results) => {
            let AGOLData = {
                DBEMembers: results[0],
                aptaMembers: results[1],
                busMembers: results[2],
                railMembers: results[3],
                coreData: results[4],
                grantsData: results[5],
                vehicleLifeData: results[6],
                countyData: results[7],
                transitSystemData: results[8],
                //@ts-ignore
                transitStatsData: results[9]
            };
            resolve(AGOLData);
        })
            .catch((error) => {
            reject(error);
        });
    });
}
exports.getLegislatorData = getLegislatorData;
