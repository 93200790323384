import React from "react";
import {  T_transitStats } from "APTAPackage";
import BarGraph from "./Graphs/BarGraph";

import { convertNumber } from "../../../components/Pages/Print/helpers";
import { SectionTitle } from "../Components/SectionTitle";

interface IProps {
  data: T_transitStats;
  dataLoading?: boolean;
}

const callback = (value: any, index: any, values: any) => {
  return convertNumber(value);
};

const TransFootprint: React.FC<IProps> = ({ data, dataLoading }) => {
  // const auto = data?.SUM_Total_Active_Fleet_auto || "N/A";
  const bus = data?.CALC_SUM_Total_Active_Fleet_bus || "N/A";
  const rail = data?.CALC_SUM_Total_Active_Fleet_rail || "N/A";
  const ferry = data?.CALC_SUM_Total_Active_Fleet_ferry || "N/A";

  let labels = [
    ["Bus", formatNumbers(bus)],
    ["Rail", formatNumbers(rail)],
    ["Ferry", formatNumbers(ferry)],
  ];

  let theData = [
    data?.CALC_SUM_Total_Active_Fleet_bus as number,
    data?.CALC_SUM_Total_Active_Fleet_rail as number,
    data?.CALC_SUM_Total_Active_Fleet_ferry as number,
  ];

  if(theData == null) return null;

  return (
    <SectionTitle
      title="Transportation Footprint"
      // subTitle="Vehicles and Assets at Agencies Serving this Area"
    >
      <div className="my-auto">
        <BarGraph
          data={theData}
          //@ts-ignore
          labels={labels}
          colors={["#36a2eb", "#0661a0", "#0d4064", "#000000"]}
          // yAxesCallback={callback}
          // width={150}
          // height={400}
        />
      </div>
    </SectionTitle>
  );
};

export default TransFootprint;

function formatNumbers(n: number | string) {
  if (typeof n === "string") {
    return n;
  } else {
    return String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");
  }
}
