import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import useEsriModules from "../../../../Hooks/useEsriModules";
import { dataExplorerWebmapId } from "../../../../constants/configTS";

function DataExplorerComponent() {
  const { esriObj, esriToken } = useEsriModules();

  useEffect(() => {
    if (esriObj != null && esriToken != null) {
      const {
        IdentityManager,
        WebMap,
        MapView,
        Legend,
        Expand,
        BasemapGallery,
        Search,
        LayerList,
      } = esriObj;

      // IdentityManager.registerToken({
      //   server: "https://www.arcgis.com/sharing/rest",
      //   expires: esriToken.tokenExpires,
      //   ssl: true,
      //   token: esriToken.token,
      // });

      var webmap = new WebMap({
        portalItem: {
          id: dataExplorerWebmapId,
        },
      });

      var view = new MapView({
        map: webmap,
        container: "viewDiv2",
      });

      var legendWidget = new Legend({
        view: view,
      });

      var expandLegend = new Expand({
        expandTooltip: "Legend",
        expandIconClass: "icon-ui-menu",
        view: view,
        content: legendWidget,
        expanded: false,
      });

      var basemapGallery = new BasemapGallery({
        view: view,
      });

      var expandBaseMap = new Expand({
        expandIconClass: "icon-ui-basemap",
        view: view,
        content: basemapGallery,
      });

      var layerList = new LayerList({
        view: view,
      });

      const expandLayerList = new Expand({
        expandTooltip: "Layer List",
        expandIconClass: "icon-ui-layer-list",
        view: view,
        content: layerList,
      });

      var searchWidget = new Search({
        view: view,
      });

      const searchWidgetExpand = new Expand({
        expandTooltip: "Search",
        expandIconClass: "esri-icon-search", // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
        view: view,
        content: searchWidget,
      });

      view.ui.add(searchWidget, {
        position: "top-right",
      });

      view.ui.add(expandBaseMap, "top-left");
      view.ui.add(expandLayerList, "top-left");
      view.ui.add(searchWidgetExpand, "top-left");
      view.ui.add(expandLegend, "bottom-right");
    }
  }, [esriObj, esriToken]);

  return (
    <div className="leader-2" id="data_section">
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <h2 className="headerText">Data Explorer:</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <hr />
            </div>
          </div>

          <p className="gm-homepage-font-size-small">
            <strong>
              {" "}
              Browse content and take advantage of the latest demographic
              information.{" "}
            </strong>{" "}
            Use interactive maps to give context to your story and inform your
            audience.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div id="viewDiv2" style={{ height: 600 }}></div>
        </div>
      </div>
    </div>
  );
}

export default observer(DataExplorerComponent);
