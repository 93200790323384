"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PortalURLs = exports.grantURLs = exports.portalURLs = exports.PortalURLs_2023 = void 0;
exports.PortalURLs_2023 = {
    CORE_URLS: {
        STATE: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/State_Core_2023/FeatureServer/229",
        CD: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/CD_Core_2023/FeatureServer/256",
        SLDL: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_Core_2023/FeatureServer/273",
        SLDU: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_Core_2023/FeatureServer/272",
    },
    COUNTY_URLS: {
        STATE: "https://services2.arcgis.com/MGS8P397fp3TUR2w/ArcGIS/rest/services/State_Core_2023/FeatureServer/288",
        CD: "https://services2.arcgis.com/MGS8P397fp3TUR2w/ArcGIS/rest/services/CD_Core_2023/FeatureServer/287",
        SLDL: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_Core_2023/FeatureServer/276",
        SLDU: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_Core_2023/FeatureServer/275",
    },
    AGENCY_URLS: {
        STATE: "https://services2.arcgis.com/MGS8P397fp3TUR2w/ArcGIS/rest/services/State_Core_2023/FeatureServer/287",
        CD: "https://services2.arcgis.com/MGS8P397fp3TUR2w/ArcGIS/rest/services/CD_Core_2023/FeatureServer/286",
        SLDL: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_Core_2023/FeatureServer/275",
        SLDU: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_Core_2023/FeatureServer/274",
    },
    GRANTS_URLS: {
        STATE: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/State_Core_2023/FeatureServer/286",
        CD: "https://services2.arcgis.com/MGS8P397fp3TUR2w/ArcGIS/rest/services/CD_Core_2023/FeatureServer/285",
        SLDL: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_Core_2023/FeatureServer/274",
        SLDU: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_Core_2023/FeatureServer/273",
    },
};
// 2024 change
const APTA_FS_URL = "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services";
exports.portalURLs = {
    STATE_SUM_CORE: `${APTA_FS_URL}/State_Core_2024/FeatureServer`,
    SENATE_CORE: `${APTA_FS_URL}/State_Core_2024/FeatureServer`,
    HOUSE_CORE: `${APTA_FS_URL}/CD_Core_2024/FeatureServer`,
    SLDL_CORE: `${APTA_FS_URL}/SLDL_Core_2024/FeatureServer`,
    SLDU_CORE: `${APTA_FS_URL}/SLDU_Core_2024/FeatureServer`,
};
exports.grantURLs = {
    STATE: `${exports.portalURLs.STATE_SUM_CORE}/557/query`,
    CD: `${exports.portalURLs.HOUSE_CORE}/560/query`,
    SLDL: `${exports.portalURLs.SLDL_CORE}/558/query`,
    SLDU: `${exports.portalURLs.SLDU_CORE}/559/query`,
};
exports.PortalURLs = {
    CORE_URLS: {
        STATE: `${exports.portalURLs.STATE_SUM_CORE}/580`,
        CD: `${exports.portalURLs.HOUSE_CORE}/577`,
        SLDL: `${exports.portalURLs.SLDL_CORE}/574`,
        SLDU: `${exports.portalURLs.SLDU_CORE}/556`,
    },
    COUNTY_URLS: {
        STATE: `${exports.portalURLs.STATE_SUM_CORE}/288`,
        CD: `${exports.portalURLs.HOUSE_CORE}/287`,
        SLDL: `${exports.portalURLs.SLDL_CORE}/276`,
        SLDU: `${exports.portalURLs.SLDU_CORE}/275`,
    },
    AGENCY_URLS: {
        STATE: `${exports.portalURLs.STATE_SUM_CORE}/635`,
        CD: `${exports.portalURLs.HOUSE_CORE}/634`,
        SLDL: `${exports.portalURLs.SLDL_CORE}/633`,
        SLDU: `${exports.portalURLs.SLDU_CORE}/632`,
    },
    GRANTS_URLS: {
        STATE: `${exports.portalURLs.STATE_SUM_CORE}/557`,
        CD: `${exports.portalURLs.HOUSE_CORE}/560`,
        SLDL: `${exports.portalURLs.SLDL_CORE}/558`,
        SLDU: `${exports.portalURLs.SLDU_CORE}/559`,
    },
};
