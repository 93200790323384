import React from "react";

const MapError = () => {
  return (
    <div className="gm-map-error">
      <div>
        <p style={{ margin: 0 }}>
          It looks like there was an error loading the map. Please try
          refreshing the page. If the issue persists, please email
          info@apta.com.
        </p>
      </div>
    </div>
  );
};

export default MapError;
