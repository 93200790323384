import React, { useEffect, useState } from "react";


import FederalAppropriations from "../../../../../Features/Explore/Sections/FederalAppropriations";
import MemberSummary from "../../../../../Features/Explore/Sections/MemberSummary/MemberSummary";
import { useStore } from "../../../../../Hooks";
import { useGetMembers } from "../../../../../services/Home/useGetMembers";

export const SummaryHome = () => {

    const authStore = useStore("authStore");
    const [token, setToken] = useState<string | null>(null);

  
    useEffect(() => {
      authStore.GET_esri().then((res) => {
        setToken(res.token);
      });
    }, []);
  
    const{data, isLoading} =useGetMembers({token})

  return (
    <div className="row">
      <div className="col">
     
        <div className="row pb-1">
          <div className="col-sm-12 col-md-6 col-undefined">
            {/* @ts-ignore */}
            <MemberSummary data={data} dataLoading = {isLoading} type = 'HOME'/>
          </div>
          <div className="col-sm-12 col-md-6 col-undefined">
            {/* @ts-ignore */}
            <FederalAppropriations data={data} dataLoading = {isLoading} type = 'HOME' />
          </div>
        </div>

      </div>
    </div>
  );
};
